import React, { useState, useEffect } from 'react';
import { Button, Modal, Card, Row, Col, Form } from 'react-bootstrap';
import { AgGridReact } from "ag-grid-react";
import axios from 'axios';

import baseURL from '../adminPanel/authServices/Url';

function BulkOrderList() {
    let token = localStorage.getItem('accessToken');
     

    const [rowData, setRowData] = useState([]);

    const rowHeight = 100;
    const DefaultColumnSetting = {
        sortable: true,
        flex: 1,
        resizable: true,
        minWidth: 120,
    };

    const [colDefs, setColDefs] = useState([
        {
            headerName: "#",
            valueGetter: "node.rowIndex + 1",
            lockPosition: true,
        },
        {
            headerName: "Customer Name",
            field: "name",
            filter: true,

        },
        {
            headerName: "Customer Phone",
            filter: true,
            field: "mobile_number",

        },
        {
            headerName: "Email",
            field: 'email',
        },
        {
            headerName: "Message",
            field: 'message',
        },
        {
            headerName: "Gold Vendor Name",
            field: 'gold_vendor_name',
        },
        
    ])

    const couponsAvailable = async () => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        const getcoupon = await axios.get(`${baseURL}/reachOutForm`, requestOptions)
        console.log('getcoupon@@@@@@@@@@@@@@@@@@@', getcoupon)
        if (getcoupon?.status === 200) {
            setRowData(getcoupon?.data)
        } else {
            console.log('error')
        }
    }
   

    useEffect(() => {
        couponsAvailable();
    }, [])

     

  return (
        <div>

            <div class="content">
                <div className="container">
                   
                    <Card className='p-2'>
                        <Row>
                            <Col>
                                <h3 className='headertext'>Bulk Order:</h3>
                            </Col>
                        </Row>
                        <div
                            className="ag-theme-alpine"
                            style={{ height: "70vh", width: "100%" }}
                        >
                            <AgGridReact
                                rowHeight={rowHeight}
                                columnDefs={colDefs}
                                defaultColDef={DefaultColumnSetting}
                                pagination={true}
                                paginationPageSize={10}
                                rowData={rowData}
                            />
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default BulkOrderList