import React, { useState, useEffect } from 'react';
import { Card, Table, Button } from 'react-bootstrap';
import SideBar from '../../dashboard/SideBar';
import FirstNavbar from '../../dashboard/FirstNavbar';
import axios from 'axios';

function CustomerWishlist() {
    const [orders, setOrders] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;

    const userId = localStorage.getItem("userId");
    const newUserId = JSON.parse(userId);
    const { user_credential_id } = newUserId;

    const CustomerOrderAvailable = async () => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
            },
        };
        try {
            const getorders = await axios.get(`http://localhost:8001/api/v1/admin/userorders?user_id=${user_credential_id}`, requestOptions);
            if (getorders?.status === 200) {
                setOrders(getorders?.data.data || []);  // Adjusted here
            } else {
                console.log('error');
            }
        } catch (error) {
            console.error('Error fetching orders:', error);
        }
    };

    useEffect(() => {
        CustomerOrderAvailable();
    }, []);

    const handleNextPage = () => {
        setCurrentPage(prevPage => prevPage + 1);
    };

    const handlePreviousPage = () => {
        setCurrentPage(prevPage => (prevPage > 1 ? prevPage - 1 : 1));
    };

    const paginatedData = Array.isArray(orders)
        ? orders.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
        : [];

    return (
        <div>
            <div className="sidebar">
                <SideBar type='Details' />
            </div>
            <div className="content">
                <div className="container">
                    <FirstNavbar />
                    <h3 className='headertext'>Customer Data</h3>
                    <div>
                        <Card className="p-2 mt-4">
                            <h3>Orders</h3>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Sl. No</th>
                                        <th>Order Name</th>
                                        <th>Order Image</th>
                                        <th>Price per Quantity</th>
                                        <th>Quantity</th>
                                        <th>Order Payment Status</th>
                                        <th>Delivery Status</th>
                                        <th>Vendor Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.isArray(paginatedData) && paginatedData.length > 0 ? (
                                        paginatedData.map((order, index) => (
                                            <tr key={order.id}>
                                                <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                <td>{order.product_order_details.product_data[0]?.product_name || 'N/A'}</td>
                                                <td>
                                                    {order.product_order_details.product_data[0]?.product_image[0] ? (
                                                        <img src={order.product_order_details.product_data[0]?.product_image[0]} alt={order.product_order_details.product_data[0]?.product_name} style={{ width: '100px', height: '100px' }} />
                                                    ) : 'N/A'}
                                                </td>
                                                <td>{order.product_order_details.product_data[0].price_breakup?.total_price_by_quantity || 'N/A'}</td>
                                                <td>{order.product_order_details.product_data[0].price_breakup?.product_quantity || 'N/A'}</td>
                                                <td>{order.product_order_payment_status || 'N/A'}</td>
                                                <td>{order.product_order_status || 'N/A'}</td>
                                                <td>{order.product_order_details.product_data[0].gold_vendor.gold_vendor_name}</td>

                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="7">No orders available</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                            <div className="d-flex justify-content-between">
                                <Button onClick={handlePreviousPage} disabled={currentPage === 1}>
                                    Previous Page
                                </Button>
                                <Button onClick={handleNextPage} disabled={paginatedData.length < itemsPerPage}>
                                    Next Page
                                </Button>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CustomerWishlist;
