import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router-dom";
import baseURL from '../../authServices/Url';
import { Card, Form, Row, Col, Button, Modal } from 'react-bootstrap'; // Import Modal component
import SideBar from '../../../dashboard/SideBar';
import {toast} from "react-toastify";
import FirstNavbar from '../../../dashboard/FirstNavbar';



function ProductList(props) {
    const navigate = useNavigate();
    let token = localStorage.getItem('accessToken');
    const vendorsDataaa = JSON.parse(localStorage.getItem('vendorDetails'));

    const [deleteId, setDeleteId] = useState('')
    const [showModal, setShowModal] = useState(false);
    const openModal = (id) => {
        console.log('@@@@@@@@@@@@@@@@@')
        setShowModal(true);
        setDeleteId(id)
    };

    const closeModal = () => {
        setShowModal(false);
    }
    const [rowData, setRowData] = useState([]);
    const rowHeight = 100;
    const DefaultColumnSetting = {
        sortable: true,
        //   filter: true,
        //   floatingFilter: true,
        flex: 1,
        resizable: true,
        minWidth: 120,
    };

    const [colDefs, setColDefs] = useState([
        {
            headerName: "#",
            valueGetter: "node.rowIndex + 1",
            // filter: true,
            lockPosition: true,
        },
        {
            headerName: "Name",
            field: "product_name",
            filter: true,
        },
        {
            headerName: "Code",
            filter: true,
            field: "product_code",
        },
        {
            headerName: "Gender",
            filter: true,
            field: "gender",
        },
        
        {
            headerName: "Stock",
            filter: true,
            field: "product_stock",

        },
        {
            headerName: "Product Image",
            field: 'Product_image',
            cellRendererFramework: (params) => {
                console.log('params', params)
                return (
                    <img src={params?.data?.product_image[0]} alt='logo' style={{ height: '100%', width: '100%', objectFit: 'contain' }} />
                )
            }
        },
        {
            headerName: "View",
            field: "view",
            cellRendererFramework: (params) => (
                <center>
                    <FontAwesomeIcon icon={faEye} style={{ color: '#BE783B' }} onClick={() => { navigate('/productData', { state: params.data }) }}> </FontAwesomeIcon>{" "}
                </center>
            ),
        },
        // {
        //     headerName: "Delete",
        //     cellRendererFramework: (params) => (
        //         <center>
        //             <FontAwesomeIcon icon={faTrash} style={{ color: '#f00' }} onClick={() => openModal(params?.data?.id)}> </FontAwesomeIcon>
        //         </center>
        //     ),
        // },
        {
            headerName:"Enable/Disable",
            field: "is_active",
            cellRendererFramework: (params) =>(
                <center>
                    <Button variant={params.data.is_active ? "success" : "danger"}
                    onClick={() => toggleOfferListStatus(params.data.id, params.data.is_active)}
                    >
                        {params.data.is_active ? "Enable" : "Disable"}
                    </Button>
                </center>

            ),
         }
    ])

    const ProductAvailable = async () => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        const getProduct = await axios.get(`${baseURL}/admin/readProduct?gold_vendor_id=${vendorsDataaa?.id}`, requestOptions)
        console.log('getProduct@@@@@@@@@@@@@@@@@@@', getProduct)
        if (getProduct?.status === 200) {
            setRowData(getProduct?.data?.data)
        } else {
            console.log('error', getProduct)
        }
    }
    useEffect(() => {
        ProductAvailable()
    }, [])

    const permanentDeleteProduct = async () => {
        const requestOptions = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        const permanentProductDel = await axios.delete(`${baseURL}/admin/deleteProduct?product_id=${deleteId}`, requestOptions)
        console.log('permanentProductDel', permanentProductDel)
        if (permanentProductDel?.status === 200) {
            ProductAvailable()
            closeModal()
        } else {
            console.log('error ==>', permanentProductDel)
        }
    }

    const inActivateProduct = async () => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        const Productinactive = await axios.delete(`${baseURL}/admin/softDeleteProduct?Product_id=${deleteId}`, requestOptions)
        console.log('Productinactive', Productinactive)
        if (Productinactive?.status === 200) {
            ProductAvailable()
        } else {
            console.log('error ==>', Productinactive)
        }
    }

    const toggleOfferListStatus = async(productId, isActive) => {
        const requestOptions = {
           headers: {
               'Accept':'application/json, text/plain, */*',
               'Content-Type': 'application/json',
               Authorization: `Bearer ${token}`,
           }
        };
        const response = await axios.patch(`${baseURL}/admin/updateProductsActive`, {
            product_id: productId,
           is_active: !isActive
        }, requestOptions);
        if(response?.status === 200) {
            toast.success("offer Updated Successfull")
            ProductAvailable();
           } else {
           console.log('error ===>', response);
        }
   }

    return (
        <div>
            <div class="sidebar">
                <SideBar type='Details' />
            </div>
            <div class="content">
                <div className="container">
                    <FirstNavbar />
                    <h3 className='headertext'>Jewellery List:</h3>
            <div
                className="ag-theme-alpine"
                style={{ height: "70vh", width: "100%" }}
            >

                <AgGridReact
                    rowHeight={rowHeight}
                    columnDefs={colDefs}
                    defaultColDef={DefaultColumnSetting}
                    pagination={true}
                    paginationPageSize={10}
                    rowData={rowData}
                />
            </div>
            </div>
            </div>
            <Modal
                show={showModal}
                onHide={closeModal}>
               
                <Modal.Body closeButton>
                    <h3>Are you sure, you want to delete this Product ...?</h3>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        Cancel
                    </Button>
                    {/* <Button variant="warning" onClick={inActivateProduct}>
                      inActivate
                  </Button> */}
                    <Button variant="danger" onClick={permanentDeleteProduct}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
export default ProductList