import { useEffect, useContext } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast, Zoom } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { UserTypeContext } from "../../App";

function FirstNavbar() {
  const navigate = useNavigate()
  const { userType, setUserType } = useContext(UserTypeContext)
  console.log('UserTypeContext : ', userType)

  const handleLogout = () => {
    localStorage.clear();
    toast.success('Successfully Logout')
    setTimeout(() => {
      navigate('/');
    }, 1000);
  }

  useEffect(() => {
    localStorage.setItem('userType', userType);
  }, [userType]);

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar
        transition={Zoom}
        delay={500}
        limit={1}
      />
      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingInline: 30, marginBlock: 20 }}>
        <div style={{ width: '100%', display: 'flex', }}>
          <div style={{ alignItems: 'center', display: 'flex', width: '100%', justifyContent: "flex-end", gap: "1.4rem", }}>
            <Button variant="outline-primary" onClick={() => { navigate('/clipboard'); setUserType('Customer') }}>Customer</Button>
            <Button variant="outline-primary" onClick={() => { navigate('/clipboard'); setUserType('BrandEndorsement') }}>Brand Endorsement</Button>
            <Button variant="outline-primary" onClick={() => { navigate('/clipboard'); setUserType('Vendor') }}>Vendor</Button>
            <Button variant="outline-primary" onClick={() => { navigate('/clipboard'); setUserType('Coupon') }}>Coupon</Button>
            <Button variant="outline-primary" onClick={() => { navigate('/clipboard'); setUserType('Request') }}>Bulk Order</Button>
            <Button variant="outline-primary" onClick={() => { navigate('/clipboard'); setUserType('Faq') }}>FAQ</Button>

            <div className="" onClick={handleLogout} >
              <Button variant="outline-danger">Log Out</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FirstNavbar;
