import React from 'react'
import { Children } from 'react';
import { Navigate } from "react-router-dom";

function ProtectedRoute({children}) {
    console.log('!!localStorage.getItem("userDetails")', localStorage.getItem("userDetails"));
    console.log('!!localStorage.getItem("accessToken")', localStorage.getItem("accessToken"));
  // return !!localStorage.getItem("accessToken") ? children : <Navigate to="/" />;
  return children
}

export default ProtectedRoute