import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router-dom";
import baseURL from '../../authServices/Url';
import { Card, Form, Row, Col, Button, Modal } from 'react-bootstrap'; // Import Modal component
import SideBar from '../../../dashboard/SideBar';
import FirstNavbar from '../../../dashboard/FirstNavbar';
import  {toast} from 'react-toastify';


function ProductCategoryList() {
    const navigate = useNavigate();
    let token = localStorage.getItem('accessToken');
    const vendorsDataaa = JSON.parse(localStorage.getItem('vendorDetails'));
    const [deleteId, setDeleteId] = useState('')
    const [showModal, setShowModal] = useState(false);
    const openModal = (id) => {
        console.log('@@@@@@@@@@@@@@@@@')
        setShowModal(true);
        setDeleteId(id)
    };

    const closeModal = () => {
        setShowModal(false);
    }
    const [rowData, setRowData] = useState([]);
    const rowHeight = 100;
    const DefaultColumnSetting = {
        sortable: true,
        //   filter: true,
        //   floatingFilter: true,
        flex: 1,
        resizable: true,
        minWidth: 120,
    };

    const [colDefs, setColDefs] = useState([
        {
            headerName: "#",
            valueGetter: "node.rowIndex + 1",
            // filter: true,
            lockPosition: true,
        },
        {
            headerName: "category Name",
            field: "category_name",
            filter: true,
        },
        {
            headerName: "category Image",
            field: 'category_image',
            cellRendererFramework: (params) => {
                console.log('params', params)
                return (
                    <img src={params?.data?.category_image} alt='logo' style={{ height: '100%', width: '100%', objectFit: 'contain' }} />
                )
            }
        },
        {
            headerName: "Brand Name",
            field: "brand_endorsment",
            cellRendererFramework: (params) => {
                console.log('params', params);
                const brandName = params?.data?.brand_endorsment?.brand_name || '-'; // Handle missing data
                return <span>{brandName}</span>;
            },
        },
        // {
        //     headerName: "Description",
        //     field: "category_description",
        // },
        {
            headerName: "View",
            field: "view",
            cellRendererFramework: (params) => (
                <center>
                    <FontAwesomeIcon icon={faEye} style={{ color: '#BE783B' }} onClick={() => { navigate('/categorydata', { state: params.data }) }}> </FontAwesomeIcon>{" "}
                </center>
            ),
        },
        // {
        //     headerName: "Delete",
        //     cellRendererFramework: (params) => (
        //         <center>
        //             <FontAwesomeIcon icon={faTrash} style={{ color: '#f00' }} onClick={() => openModal(params?.data?.id)}> </FontAwesomeIcon>
        //         </center>
        //     ),
        // },

        {
            headerName:"Enable/Disable",
            field: "is_active",
            cellRendererFramework: (params) =>(
                <center>
                    <Button variant={params.data.is_active ? "success" : "danger"}
                    onClick={() => toggleOfferListStatus(params.data.id, params.data.is_active)}
                    >
                        {params.data.is_active ? "Enable" : "Disable"}
                    </Button>
                </center>

            ),
         }
    ])

    const categoryAvailable = async () => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        const getcategory = await axios.get(`${baseURL}/admin/readProductCategory?gold_vendor_id=${vendorsDataaa?.id}`, requestOptions)
        console.log('getcategory@@@@@@@@@@@@@@@@@@@', getcategory)
        if (getcategory?.status === 200) {
            setRowData(getcategory?.data?.data)
        } else {
            console.log('error')
        }
    }
    

    useEffect(() => {
        categoryAvailable()
    }, [])

    const permanentDeletecategory = async () => {
        const requestOptions = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        const permanentcategoryDel = await axios.delete(`${baseURL}/admin/deleteProductCategory?product_category_id=${deleteId}`, requestOptions)
        console.log('permanentcategoryDel', permanentcategoryDel)
        if (permanentcategoryDel?.status === 200) {
            categoryAvailable()
            closeModal()
        } else {
            console.log('error ==>', permanentcategoryDel)
        }
    }

    const inActivatecategory = async () => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        const categoryinactive = await axios.delete(`${baseURL}/admin/softDeletecategory?category_id=${deleteId}`, requestOptions)
        console.log('categoryinactive', categoryinactive)
        if (categoryinactive?.status === 200) {
            categoryAvailable()
            closeModal()
        } else {
            console.log('error ==>', categoryinactive)
        }
    }


    const toggleOfferListStatus = async(productCategoryId, isActive) => {
        const requestOptions = {
           headers: {
               'Accept':'application/json, text/plain, */*',
               'Content-Type': 'application/json',
               Authorization: `Bearer ${token}`,
           }
        };
        const response = await axios.patch(`${baseURL}/admin/updateProductsCategoryActive`, {
            product_category_id: productCategoryId,
           is_active: !isActive
        }, requestOptions);
        if(response?.status === 200) {
            toast.success("offer Updated Successfull")
            categoryAvailable();
           } else {
           console.log('error ===>', response);
        }
   }

    return (
        <div>
            <div class="sidebar">
                <SideBar type='Details' />
            </div>
            <div class="content">
                <div className="container">
                    <FirstNavbar />
                    <h3 className='headertext'>Category List:</h3>
            <div
                className="ag-theme-alpine"
                style={{ height: "70vh", width: "100%" }}
            >

                <AgGridReact
                    rowHeight={rowHeight}
                    columnDefs={colDefs}
                    defaultColDef={DefaultColumnSetting}
                    pagination={true}
                    paginationPageSize={10}
                    rowData={rowData}
                
                />
            </div>
            </div>
            </div>
            <Modal
                show={showModal}
                onHide={closeModal}>
               
                <Modal.Body closeButton>
                    <h3>Are you sure, you want to delete this category ...?</h3>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button variant="warning" onClick={inActivatecategory}>
                        inActivate
                    </Button>
                    <Button variant="danger" onClick={permanentDeletecategory}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ProductCategoryList