import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Button, Card, Row, Col, Form } from 'react-bootstrap';
import SideBar from '../dashboard/SideBar';
import FirstNavbar from '../dashboard/FirstNavbar';
import { toast } from "react-toastify";
import baseURL from '../adminPanel/authServices/Url';

function AddBrandEndorsement() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [brandData, setBrandData] = useState({
        brand_name: '',
        brand_logo: null,
        gold_vendor_id: '',
        gold_vendor_name: '',
        brand_id: ''
    });
    const [fileName, setFileName] = useState('');
    const [vendors, setVendors] = useState([]);
    const [brandErrors, setBrandErrors] = useState({});
    const [isEditing, setIsEditing] = useState(false);

    let token = localStorage.getItem('accessToken');

    useEffect(() => {
        const fetchVendors = async () => {
            try {
                const response = await axios.get(`${baseURL}/goldVendor/readGoldVendor`);
                setVendors(response.data?.data);
            } catch (err) {
                console.error('Failed to fetch vendors:', err);
            }
        };

        fetchVendors();

        if (state) {
            setIsEditing(true);
            setBrandData({
                brand_name: state.brand_name || '',
                brand_logo: state.brand_logo || null,
                gold_vendor_id: state.gold_vendor_id || '',
                gold_vendor_name: state.gold_vendor_name || '',
                brand_id: state.id || ''
            });
            setFileName(state.brand_logo ? state.brand_logo.split('/').pop() : '');
        }
    }, [state]);

    const handleValidation = () => {
        let errors = {};
        if (!brandData.brand_name) errors.brand_name = "Please enter the brand name";
        if (!brandData.brand_logo) errors.brand_logo = "Please upload the brand logo";
        if (!brandData.gold_vendor_id) errors.gold_vendor_id = "Please select a vendor";
        return errors;
    };

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'brand_logo') {
            if (files && files[0]) {
                setBrandData(prev => ({ ...prev, brand_logo: files[0] }));
                setFileName(files[0].name);
            } else {
                setBrandData(prev => ({ ...prev, brand_logo: null }));
                setFileName('');
            }
        } else if (name === 'gold_vendor_id') {
            const selectedVendor = vendors.find(vendor => vendor.id === value);
            setBrandData(prev => ({
                ...prev,
                gold_vendor_id: selectedVendor.id,
                gold_vendor_name: selectedVendor.gold_vendor_name
            }));
        } else {
            setBrandData(prev => ({ ...prev, [name]: value }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const errors = handleValidation();
            console.log("Validation errors:", errors);

            if (Object.keys(errors).length > 0) {
                console.log("Validation failed");
                setBrandErrors(errors);
                return;
            }
            let logoUrl = brandData.brand_logo;

            if (brandData.brand_logo instanceof File) {
                const formData = new FormData();
                formData.append('image', brandData.brand_logo);

                const uploadResponse = await axios.post(`${baseURL}/admin/mediaMingle/upload`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${token}`,
                    }
                });

                if (uploadResponse.data && uploadResponse.data.data && uploadResponse.data.data.url) {
                    logoUrl = uploadResponse.data.data.url;
                } else {
                    throw new Error("Failed to upload file");
                }
            }

            const jsonData = {
                brand_name: brandData.brand_name,
                brand_logo: logoUrl,
                gold_vendor_id: brandData.gold_vendor_id,
                gold_vendor_name: brandData.gold_vendor_name,
            };

            if (isEditing && brandData.brand_id) {
                jsonData.brand_id = brandData.brand_id;
            }

            const requestOptions = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };

            let response;
            if (isEditing) {
                response = await axios.patch(`${baseURL}/admin/brandEdorsmentupdate`, jsonData, requestOptions);
            } else {
                response = await axios.post(`${baseURL}/admin/brandEdorsment`, jsonData, requestOptions);
            }

            if (response?.status === 200) {
                toast.success(`Brand Endorsement successfully ${isEditing ? 'updated' : 'created'}`);
                setTimeout(() => {
                    if (isEditing) {
                        navigate('/subbrand');
                    }
                }, 1000);
            } else {
                toast.error('Failed to submit brand endorsement');
            }
        } catch (err) {
            toast.error(err.response?.data?.message || 'An error occurred');
        }
    };

    return (
        <div>
            <div className="sidebar">
                <SideBar type='Dashboard' />
            </div>
            <div className="content">
                <div className="container">
                    <FirstNavbar />
                    <Card className='p-2'>
                        <div>
                            <h3 className='headertext'>{isEditing ? 'Edit' : 'Add'} your Brand Endorsement</h3>
                            <div>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Brand Name</Form.Label>
                                            <Form.Control
                                                maxLength={50}
                                                type="text"
                                                placeholder="Enter brand name"
                                                size="md"
                                                name='brand_name'
                                                onChange={handleChange}
                                                autoComplete='off'
                                                className='mb-3'
                                                value={brandData.brand_name}
                                            />
                                            {brandErrors.brand_name && <span className="text-danger">{brandErrors.brand_name}</span>}
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Brand Logo</Form.Label>
                                            <Form.Control
                                                type="file"
                                                size="md"
                                                name='brand_logo'
                                                onChange={handleChange}
                                                autoComplete='off'
                                                className='mb-3'
                                            />
                                            {fileName && <p>File selected: {fileName}</p>}
                                            {brandErrors.brand_logo && <span className="text-danger">{brandErrors.brand_logo}</span>}
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Select Vendor</Form.Label>
                                            <Form.Control
                                                as="select"
                                                size="md"
                                                name='gold_vendor_id'
                                                onChange={handleChange}
                                                autoComplete='off'
                                                className='mb-3'
                                                value={brandData.gold_vendor_id}
                                            >
                                                <option value=''>Select Vendor</option>
                                                {vendors.map((vendor) => (
                                                    <option key={vendor.id} value={vendor.id}>
                                                        {vendor.gold_vendor_name}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                            {brandErrors.gold_vendor_id && <span className="text-danger">{brandErrors.gold_vendor_id}</span>}
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                            <Button variant="warning" onClick={handleSubmit}>
                                {isEditing ? 'Update' : 'Submit'}
                            </Button>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default AddBrandEndorsement;
