import React, { useState, useEffect } from 'react'
import { useLocation } from "react-router-dom";
import {
  Card,
  Row,
  Col,
  Figure,
  Table,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import SideBar from "../../../dashboard/SideBar";
import FirstNavbar from "../../../dashboard/FirstNavbar";
import axios from 'axios';
import baseURL from '../../authServices/Url';
import { toast } from "react-toastify";
import Multiselect from 'multiselect-react-dropdown';


function VendorShops() {
  const { state } = useLocation();
  const [shopData1, setshopData1] = useState(state)
  console.log('shopData1', shopData1, state)
  let token = localStorage.getItem('accessToken');

  const [storeDetails, setStoreDetails] = useState({
    name: "",
    storeCode: "",
    street: "",
    city: "",
    state: "",
    pin: '',
    phoneCode: "",
    phone: "",
    mail: "",
    openTime: "",
    closeTime: "",
    openDays: [],
    description: '',
  });

  const [openTime, setOpenTime] = useState('')
  const [closeTime, setCloseTime] = useState('')
  const [storeData, setStoreData] = useState([])
  const [isOpen, setIsOpen] = useState(false);
  const [shopModal, setShopModal] = useState(false)
  const daysWeek1 = [
    { id: 1, day: 'SUNDAY' },
    { id: 2, day: 'MONDAY' },
    { id: 3, day: 'TUESDAY' },
    { id: 4, day: 'WEDNESDAY' },
    { id: 5, day: 'THURSDAY' },
    { id: 6, day: 'FRIDAY' },
    { id: 7, day: 'SATURDAY' },
  ]

  const shopAvailable = async () => {
    const requestOptions = {
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    const getshop = await axios.get(`${baseURL}/admin/readGoldVendorStore?store_id=${shopData1?.id}`, requestOptions)
    console.log('getshop@@@@@@@@@@@@@@@@@@@', getshop)
    if (getshop?.status === 200) {
      setshopData1(getshop?.data?.data[0])
    } else {
      console.log('error')
    }
  }
  useEffect(() => {
    shopAvailable()
  }, [])

  const handleChange = ({ target: { name, value } }) => {
    console.log('target: { name, value } ', { ...storeDetails, [name]: value })
    setStoreDetails({ ...storeDetails, [name]: value });
  };

  const timeConverter = (time) => {
    console.log('timeConverter timeConverter timeConverter', time)
    const timeParts = time?.split(':');
  console.log('timeParts', timeParts);
    if (!timeParts || timeParts.length !== 2) {
      // Handle the case where the time format is unexpected
      console.error('Invalid time format:', time);
      return 'Invalid Time';
    }
  
    const [hours, minutes] = timeParts;
    const period = hours >= 12 ? 'PM' : 'AM';
    const hours12 = hours % 12 || 12;
    const time12 = `${hours12}:${minutes} ${period}`;
    return time12;
  }

  const handleOpenTimeChange = (e) => {
    console.log('eeee', e.target.value)
    const convertedTime = timeConverter(e.target.value);
    console.log('convertedTime', convertedTime);
    setOpenTime(convertedTime)
    setStoreDetails({ ...storeDetails, openTime: e.target.value });
  }

  const handleCloseTimeChange = (e) => {
    console.log('eeee', e.target.value)
    const convertedTime = timeConverter(e.target.value);
    setCloseTime(convertedTime)
    setStoreDetails({ ...storeDetails, closeTime: e.target.value });
  }
  const [selectedShop, setSelectedShop] = useState([]);
  const [selectedShop1, setSelectedShop1] = useState([]);

  const onSelectShop = (selectedList, selectedItem) => {
    setSelectedShop(selectedList?.map((day) => day?.day));
    setSelectedShop1(selectedList)
  };

  const onRemoveShop = (selectedList, removedItem) => {
    const updatedSelectedShopIds = selectedList?.map((item) => item?.id);
    setSelectedShop(selectedList);
    setSelectedShop1(selectedList)
  };


  const handelValidation = () => {
    const newErrors = {};
    const {
      accNumber,
      address,
      bankName,
      description,
      email,
      gst,
      ifsc,
      managerName,
      name,
      owner,
      phone,
    } = storeDetails;
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const nameRegex = /^[a-zA-Z\s]{1,}[\.]{0,1}[a-zA-Z\s]{0,}$/;
    const accNumberRegex = /^\d{10}$/;
    const gstNumberRegex =
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    const ifscRegex = /^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/;
    const phoneRegex = /^[6-9]\d{9}$/;

    if (name === "" || nameRegex.test(nameRegex)) {
      newErrors.name = "please enter name";
    }
    if (email === "" || emailRegex.test(email)) {
      newErrors.email = "please enter a valid email address";
    }
    if (accNumber === "" || accNumberRegex.test(accNumber)) {
      newErrors.accNumber = "please enter a valid email address";
    }
    if (address === "") {
      newErrors.address = "please add Address";
    }
    if (bankName === "") {
      newErrors.address = "please enter a Bank Name";
    }
    if (description === "") {
      newErrors.description = "please add description";
    }
    if (managerName === "") {
      newErrors.managerName = "please add a Manager Name";
    }
    if (gst === "" || gstNumberRegex.test(gst)) {
      newErrors.gst = "please enter a valid gst number";
    }
    if (ifsc === "" || ifscRegex.test(ifsc)) {
      newErrors.ifsc = "please enter a valid  ifsc code ";
    }
    if (owner === "") {
      newErrors.owner = "please enter a owner Name";
    }
    if (phone === "" || phoneRegex.test(phone)) {
      newErrors.phone = "please enter a valid phone number";
    }

    return newErrors;
  };


  const handleShowModal = () => {
    console.log('handleShowModal shopData1',shopData1?.store_open_time, shopData1);
    // setSelectedShopId(shopData1?.id)
    const fomattedDays = shopData1?.store_week_days?.map((day, i) => { return ({ id: i + 1, day: day }) })
    // console.log('fomattedDays', fomattedDays)
    setShopModal(true)
    // setOpenTime(timeConverter(shopData1?.store_open_time))
    // setOpenTime(`${shopData1?.store_open_time}:00`)
    setCloseTime(`${shopData1?.store_close_time}:00`)
    // setCloseTime(timeConverter(shopData1?.store_close_time))
    setSelectedShop(shopData1?.store_week_days)
    setSelectedShop1(fomattedDays)
    setStoreDetails({
      name: shopData1?.store_name,
      storeCode: shopData1?.store_code,
      street: shopData1?.store_address,
      city: shopData1?.store_city,
      state: shopData1?.store_state,
      pin: shopData1?.store_address_pincode,
      phoneCode: shopData1?.store_phone_code,
      phone: shopData1?.store_phone_no,
      mail: shopData1?.store_email,
      openTime: shopData1?.store_open_time,
      closeTime: shopData1?.store_close_time,
      openDays: shopData1?.store_week_days,
      description: shopData1?.store_description,
    })
  }

  const handleCloseModal = () => setShopModal(false);

  const editStore = async () => {
    try {
      const userCredentials = {
        "store_code": storeDetails?.storeCode,
        "store_name": storeDetails?.name,
        "store_description": storeDetails?.description,
        "store_address": storeDetails?.street,
        "store_city": storeDetails?.city,
        "store_state": storeDetails?.state,
        "store_address_pincode": storeDetails?.pin,
        "store_phone_code": '+91',
        "store_phone_no": storeDetails?.phone,
        "store_email": storeDetails?.mail,
        "store_open_time": storeDetails?.openTime,
        "store_close_time": shopData1?.store_close_time,
        "store_week_days": selectedShop,
        "store_id": shopData1?.id,
        "gold_vendor_id": shopData1?.gold_vendor_id
        // "gold_vendor_store_id":selectedShopId,
      }
      const requestOptions = {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      }
      const updateShop = await axios.patch(`${baseURL}/admin/updateGoldVendorStore`, userCredentials, requestOptions)
      console.log('updateShop updateShop', updateShop);
      if (updateShop?.status === 200) {
        shopAvailable()
        setShopModal(false)
        toast.success(`Shop succesfully created`);
        setOpenTime('')
        setCloseTime('')
        setSelectedShop('')
        setSelectedShop1('')
        setStoreDetails({
          name: "",
          storeCode: "",
          street: "",
          city: "",
          state: "",
          pin: '',
          phoneCode: "",
          phone: "",
          mail: "",
          openTime: "",
          closeTime: "",
          openDays: [],
          description: '',
        })
      } else {
        setShopModal(false)
        console.log('error ===>', updateShop)
      }
    } catch (err) {
      setShopModal(false)
      console.log('error ===>', err)
    }
  }


  return (
    <div>
      <div class="sidebar">
        <SideBar type='Details'/>
      </div>
      <div class="content">
        <div className="container">
          <FirstNavbar />
          <h3 className='headertext'>shop Data</h3>
          <div>
            <Card className="p-2">
              <Row>
                <Col md={6}>
                  <h3 className="headertext text1">shop Deatils:</h3>
                </Col>
              </Row>

              <Row>
                <Col md={1}></Col>
                <Col md={3}>
                  <h5>Name</h5>
                </Col>
                <Col md={1}>:</Col>
                <Col md={3}>
                  <h5>{shopData1?.store_name}</h5>
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={3}>
                  <h5>Phone Number</h5>
                </Col>
                <Col md={1}>:</Col>
                <Col md={3}>
                  <h5>{shopData1?.store_phone_no}</h5>
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={3}>
                  <h5>Email</h5>
                </Col>
                <Col md={1}>:</Col>
                <Col md={3}>
                  <h5>{shopData1?.store_email}</h5>
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={3}><h5>Open Time</h5></Col>
                <Col md={1}>:</Col>
                <Col md={3}><h5>{shopData1?.store_open_time}</h5></Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={3}>
                  <h5>Close Time</h5>
                </Col>
                <Col md={1}>:</Col>
                <Col md={6}>
                  <h5>{shopData1?.store_close_time}</h5>
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={3}>
                  <h5>Working Days</h5>
                </Col>
                <Col md={1}>:</Col>
                <Col md={6}>
                  {shopData1?.store_week_days?.map((shop, i) => {
                    return (

                      <h5 key={i}>{shop}</h5>
                    )
                  })}
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={3}>
                  <h5>Address</h5>
                </Col>
                <Col md={1}>:</Col>
                <Col md={6}>
                  <h5>{shopData1?.store_address} {shopData1?.store_city} {shopData1?.store_state} {shopData1?.store_address_pincode}</h5>
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={3}>
                  <h5>description</h5>
                </Col>
                <Col md={1}>:</Col>
                <Col md={3}>

                  <h5>{shopData1?.store_description}</h5>
                </Col>
              </Row>

              <hr />
              <Row>
                <Col md={3}>
                  <Button variant="warning" onClick={handleShowModal}>
                    Edit shop
                  </Button>
                </Col>

              </Row>

            </Card>
          </div>
        </div>
      </div>

      <Modal
        show={shopModal}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <h4 className="headertext text-center">Add/Edit Store Details:</h4>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Store Name</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter Store Name"
                  size="sm"
                  name="name"
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                  value={storeDetails?.name}
                />
                <span className="text-danger">{ }</span>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Store Code</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter Store Code"
                  size="sm"
                  name="storeCode"
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                  value={storeDetails?.storeCode}
                />
                <span className="text-danger">{ }</span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Street/Area</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter House Number/Street "
                  size="sm"
                  name="street"
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                  value={storeDetails?.street}
                />
                <span className="text-danger">{ }</span>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>City</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter City"
                  size="sm"
                  name="city"
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                  value={storeDetails?.city}
                />
                <span className="text-danger">{ }</span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>State</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="tel"
                  placeholder="Enter State"
                  size="sm"
                  name="state"
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                  value={storeDetails?.state}
                />
                <span className="text-danger">{ }</span>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Pincode</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter Pincode"
                  size="sm"
                  name="pin"
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                  value={storeDetails?.pin}
                />
                <span className="text-danger">{ }</span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="email"
                  placeholder="Enter Phone Number"
                  size="sm"
                  name="phone"
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                  value={storeDetails?.phone}
                />
                <span className="text-danger">{ }</span>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Mail Id</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="email"
                  placeholder="Enter Mail Id"
                  size="sm"
                  name="mail"
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                  value={storeDetails?.mail}
                />
                <span className="text-danger">{ }</span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Open Time</Form.Label>
                <Form.Control
                  type="time"
                  placeholder="Enter Open Time"
                  size="sm"
                  name="openTime"
                  onChange={(e) => handleOpenTimeChange(e)}
                  autoComplete="off"
                  className="mb-3"
                  value={storeDetails?.openTime}
                />

                <span className="text-danger">{ }</span>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Close Time</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="time"
                  placeholder="Enter Close Time"
                  size="sm"
                  name="closeTime"
                  onChange={(e) => handleCloseTimeChange(e)}
                  autoComplete="off"
                  className="mb-3"
                  value={storeDetails?.closeTime}
                />
                <span className="text-danger">{ }</span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Week Days</Form.Label>
                <Multiselect
                  options={daysWeek1}
                  displayValue="day"
                  onSelect={onSelectShop}
                  onRemove={onRemoveShop}
                  selectedValues={selectedShop1}
                  placeholder="Select Working Days"
                  value={selectedShop}
                />
                <span className="text-danger">{ }</span>
              </Form.Group>
            </Col>

          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Enter Description"
                  // className="w-50"
                  name="description"
                  onChange={handleChange}
                  autoComplete="off"
                  maxLength={50}
                  size="sm"
                  className="mb-3"
                  rows={3}
                  value={storeDetails?.description}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseModal} variant="secondary">
            Cancel
          </Button>
          <Button variant="warning"
            onClick={() => editStore()}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default VendorShops