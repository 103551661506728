import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router-dom";
import baseURL from '../../authServices/Url';
import { Button, Modal } from 'react-bootstrap'; // Import Modal component
import FirstNavbar from '../../../dashboard/FirstNavbar';
import SideBar from '../../../dashboard/SideBar';
import { toast } from 'react-toastify';

function OrderList(props) {
    const navigate = useNavigate();
    let token = localStorage.getItem('accessToken');
    const vendorsDataaa = JSON.parse(localStorage.getItem('vendorDetails'));

    const [deleteId, setDeleteId] = useState('');
    const [showModal, setShowModal] = useState(false);
    console.log('props', props);

    const openModal = (id) => {
        console.log('@@@@@@@@@@@@@@@@@');
        setShowModal(true);
        setDeleteId(id);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const [rowData, setRowData] = useState([]);
    const rowHeight = 100;
    const DefaultColumnSetting = {
        sortable: true,
        flex: 1,
        resizable: true,
        minWidth: 120,
    };

    const [colDefs, setColDefs] = useState([
        {
            headerName: "#",
            valueGetter: "node.rowIndex + 1",
            lockPosition: true,
        },
        {
            headerName: "Order Name",
            valueGetter: (params) => {
                const product = params.data.product_order_details?.product_data?.[0];
                return product ? product.product_name : '';
            },
            filter: true,
        },
        {
            headerName: "Payment Status",
            field: "product_order_payment_status",
        },
        {
            headerName: "Packing Status",
            valueGetter: (params) => {
                const product = params.data.product_order_details?.sequelShipmentData?.[0];
                return product ? product.shipment_status : '';
            },
            filter: true,
        },
        {
            headerName: "Order Status",
            field: "product_order_status",
            filter: true,
        },
        {
            headerName: "Enable/Disable",
            field: "is_active",
            cellRendererFramework: (params) => (
                <center>
                    <Button variant={params.data.is_active ? "success" : "danger"} onClick={() => toggleOrderStatus(params.data.id, params.data.is_active)}>
                        {params.data.is_active ? "Enable" : "Disable"}
                    </Button>
                </center>
            ),
        },
        {
            headerName: "View",
            field: "view",
            cellRendererFramework: (params) => (
                <center>
                    <FontAwesomeIcon icon={faEye} style={{ color: '#BE783B' }} onClick={() => { navigate('/orderdata', { state: params.data }) }}> </FontAwesomeIcon>{" "}
                </center>
            ),
        },
        {
            headerName: "Delete",
            cellRendererFramework: (params) => (
                <center>
                    <FontAwesomeIcon icon={faTrash} style={{ color: '#f00' }} onClick={() => openModal(params?.data?.id)}> </FontAwesomeIcon>
                </center>
            ),
        },
    ]);

    const orderAvailable = async () => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        const getorder = await axios.get(`${baseURL}/admin/readProductOrder?gold_vendor_id=${vendorsDataaa?.id}`, requestOptions);
        console.log('getorder@@@@@@@@@@@@@@@@@@@', getorder);
        if (getorder?.status === 200) {
            setRowData(getorder?.data?.data);
        } else {
            console.log('error');
        }
    };

    const toggleOrderStatus = async (orderId, isActive) => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        const response = await axios.patch(`${baseURL}/admin/updateOrderStatus`, {
            order_id: orderId,
            is_active: !isActive
        }, requestOptions);
        if (response?.status === 200) {
            toast.success(`Order successfully updated ${!isActive}`)
            orderAvailable();
        } else {
            console.log('error ==>', response);
        }
    };

    useEffect(() => {
        orderAvailable();
    }, []);

    const permanentDeleteorder = async () => {
        const requestOptions = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        const permanentorderDel = await axios.delete(`${baseURL}/admin/deleteorder?order_id=${deleteId}`, requestOptions);
        console.log('permanentorderDel', permanentorderDel);
        if (permanentorderDel?.status === 200) {
            orderAvailable();
            closeModal();
        } else {
            console.log('error ==>', permanentorderDel);
        }
    };

    const inActivateorder = async () => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        const orderinactive = await axios.delete(`${baseURL}/admin/softDeleteorder?order_id=${deleteId}`, requestOptions);
        console.log('orderinactive', orderinactive);
        if (orderinactive?.status === 200) {
            orderAvailable();
        } else {
            console.log('error ==>', orderinactive);
        }
    };

    return (
        <div>
            <div className="sidebar">
                <SideBar type='Details' />
            </div>
            <div className="content">
                <div className="container">
                    <FirstNavbar />
                    <h3 className='headertext'>Order List:</h3>
                    <div
                        className="ag-theme-alpine"
                        style={{ height: "70vh", width: "100%" }}
                    >

                        <AgGridReact
                            rowHeight={rowHeight}
                            columnDefs={colDefs}
                            defaultColDef={DefaultColumnSetting}
                            pagination={true}
                            paginationPageSize={10}
                            rowData={rowData}
                        />
                    </div>
                </div>
            </div>
            <Modal
                show={showModal}
                onHide={closeModal}>
                <Modal.Body closeButton>
                    <h3>Are you sure, you want to delete this order ...?</h3>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        Cancel
                    </Button>
                    {/* <Button variant="warning" onClick={inActivateorder}>
                        inActivate
                    </Button> */}
                    <Button variant="danger" onClick={permanentDeleteorder}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default OrderList;
