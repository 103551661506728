import React, { useState, useEffect } from 'react'
// import { useLocation } from "react-router-dom";
import SideBar from '../../dashboard/SideBar';
import FirstNavbar from "../../dashboard/FirstNavbar";
import { AgGridReact } from 'ag-grid-react';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import baseURL from '../authServices/Url';
import { toast } from 'react-toastify';
import { height } from '@fortawesome/free-solid-svg-icons/fa0';

function Approval() {

    let token = localStorage.getItem('accessToken');
    console.log("token: ", token);
    const [rowData, setRowData] = useState([]);
    const [flattenedRowData, setFlattenedRowData] = useState([]);
    const [goldVendorBanner, setGoldVendorBanner] = useState(null);
    console.log("rowData: ", rowData);

    const [deleteId, setDeleteId] = useState('')
    const [showModal, setShowModal] = useState(false);

    const openModal = (id) => {
        setShowModal(true);
        setDeleteId(id)
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const goldVendorRequest = async () => {
        try {
            const requestOptions = {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            };
            const getVendor = await axios.get(`${baseURL}/admin/readGoldVendor`, requestOptions);
            if (getVendor?.status === 200) {
                setRowData(getVendor?.data?.data);
            } else {
                console.log('Error while fetching gold vendor data');
            }
        } catch (error) {
            console.error('Error in goldVendorRequest:', error);
        }
    };

    useEffect(() => {
        goldVendorRequest();
    }, [])

    useEffect(() => {
        if (rowData.length > 0) {
            const flattenedData = rowData.flatMap(vendor => {
                const banners = vendor.gold_vendor_banners || [];
                return banners.map((bannerString, index) => {
                    let banner;
                    try {
                        banner = JSON.parse(bannerString);
                    } catch (error) {
                        console.error('Error parsing banner:', error);
                        banner = bannerString;
                    }
                    return {
                        id: `${vendor.id}-${index}`,
                        gold_vendor_name: vendor.gold_vendor_name,
                        gold_vendor_logo: vendor.gold_vendor_logo,
                        banner: typeof banner === 'object' ? banner.gold_vendor_banners : banner,
                        approve: typeof banner === 'object' ? banner.approve : false,
                        bannerIndex: index,
                        originalId: vendor.id
                    };
                });
            });
            setFlattenedRowData(flattenedData);
        } else {
            console.log('rowData is empty');
        }
    }, [rowData]);

    const rowHeight = 100;
    const DefaultColumnSetting = {
        sortable: true,
        flex: 1,
        resizable: true,
        minWidth: 120,
    };

    const navigate = useNavigate();


    const [colDefs, setColDefs] = useState([
        {
            headerName: "#",
            valueGetter: "node.rowIndex + 1",
            lockPosition: true,
            width: 70,
        },
        {
            headerName: "Vendor Name",
            field: "gold_vendor_name",
        },
        {
            headerName: "Vendor Logo",
            field: "gold_vendor_logo",
            cellRendererFramework: (params) => (
                <img
                    src={params.value}
                    alt="Vendor Logo"
                    style={{ height: '80px', width: '80px', objectFit: 'contain' }}
                />
            ),
        },
        {
            headerName: "Banner",
            field: "banner",
            cellRendererFramework: (params) => (
                <img
                    src={params.value}
                    alt="Banner"
                    style={{ height: '80px', width: '80px', objectFit: 'cover' }}
                />
            ),
        },
        {
            headerName: "Status",
            field: "approve",
            cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
            cellRendererFramework: (params) => (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', }}>
                    <Form.Check
                        type="switch"
                        id={`custom-switch-${params.data.originalId}`}
                        // label={params.value ? "Approved" : "Approve"}
                        checked={params.value}
                        onChange={() => handleApproveReject(params.data.originalId, params.data, !params.value)}
                        className="custom-switch"
                    />
                    <span>{params.value ? "Approved" : "Approve"}</span>
                </div>
            )
        },
    ]);

    const handleApproveReject = async (goldVendorId, banner, newApproveStatus) => {

        try {

            const requestBody = {
                approve: newApproveStatus,
                gold_vendor_id: goldVendorId,
                imgUrl: JSON.stringify({
                    gold_vendor_banners: banner.banner,
                    approve: banner.approve
                })
            };

            const response = await axios.patch(
                `${baseURL}/admin/bannerApproveStatus`,
                requestBody,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response?.status == 200) {
                await goldVendorRequest()
                toast.success("Status Changed");
            } else {
                toast.error("Failed to change the status")
            }
        } catch (error) {
            console.error('Error updating approval status:', error);
        }
    };

    return (
        <div>
            <div class="sidebar">
                <SideBar type='Dashboard' />
            </div>
            <div class="content">
                <div className="container">
                    <FirstNavbar />
                    <h3 className='headertext'>Approval</h3>
                    <div
                        className="ag-theme-alpine"
                        style={{ height: "70vh", width: "100%" }}
                    >
                        <AgGridReact
                            rowHeight={rowHeight}
                            columnDefs={colDefs}
                            defaultColDef={DefaultColumnSetting}
                            pagination={true}
                            paginationPageSize={10}
                            rowData={flattenedRowData}

                        />
                    </div>
                </div>
            </div>
        </div>


    )
}

export default Approval