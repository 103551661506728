import React, { useState, useEffect } from 'react'
import { useLocation } from "react-router-dom";
import {
    Card,
    Row,
    Col, Figure
} from "react-bootstrap";
import SideBar from '../../dashboard/SideBar';
import FirstNavbar from "../../dashboard/FirstNavbar";

function BlockedCustomerList() {
    return (
        <div>
            <div class="sidebar">
                <SideBar type='Dashboard' />
            </div>
            <div class="content">
                <div className="container">
                    <FirstNavbar />
                    <h3 className='headertext'>BlockedCustomerList</h3>
                </div>
            </div>
        </div>


    )
}

export default BlockedCustomerList