import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router-dom";
import baseURL from '../../authServices/Url';
import { Card, Form, Row, Col, Button, Modal } from 'react-bootstrap'; // Import Modal component
import FirstNavbar from '../../../dashboard/FirstNavbar';
import SideBar from '../../../dashboard/SideBar';

function GemstoneList(props) {
    const navigate = useNavigate();
    let token = localStorage.getItem('accessToken');
    const vendorsDataaa = JSON.parse(localStorage.getItem('vendorDetails'));

    const [deleteId, setDeleteId] = useState('')
    const [showModal, setShowModal] = useState(false);
    // console.log('props', props)
    const openModal = (id) => {
        console.log('@@@@@@@@@@@@@@@@@')
        setShowModal(true);
        setDeleteId(id)
    };

    const closeModal = () => {
        setShowModal(false);
    }
    const [rowData, setRowData] = useState([]);
    const rowHeight = 100;
    const DefaultColumnSetting = {
        sortable: true,
        //   filter: true,
        //   floatingFilter: true,
        flex: 1,
        resizable: true,
        minWidth: 120,
    };

    const [colDefs, setColDefs] = useState([
        {
            headerName: "#",
            valueGetter: "node.rowIndex + 1",
            // filter: true,
            lockPosition: true,
        },
        {
            headerName: "Gemstone Name",
            field: "gemstone_name",

            filter: true,
            // cellRendererFramework: (params) => (
            //     <div
            //         style={{ cursor: 'pointer' }}
            //         onClick={() => navigate('/vendorData', { state: params.data })}
            //     >
            //         {params.data.name}
            //     </div>
            // ),
        },
        {
            headerName: "color",
            filter: true,
            field: "gemstone_color",

        },
        {
            headerName: "Cut",
            filter: true,
            field: "gemstone_cut",

        },
        {
            headerName: "Clarity",
            filter: true,
            field: "gemstone_clarity",

        },
        {
            headerName: "Gemstone Image",
            field: 'gemstone_image',
            cellRendererFramework: (params) => {
                console.log('params', params)
                return (
                    <img src={params?.data?.gemstone_image} alt='logo' style={{ height: '100%', width: '100%', objectFit: 'contain' }} />
                )
            }
        },
        {
            headerName: "View",
            field: "view",
            cellRendererFramework: (params) => (
                <center>
                    <FontAwesomeIcon icon={faEye} style={{ color: '#BE783B' }} onClick={() => { navigate('/gemstonedata', { state: params.data }) }}> </FontAwesomeIcon>{" "}
                </center>
            ),
        },
        // {
        //     headerName: "Delete",
        //     cellRendererFramework: (params) => (
        //         <center>
        //             <FontAwesomeIcon icon={faTrash} style={{ color: '#f00' }} onClick={() => openModal(params?.data?.id)}> </FontAwesomeIcon>
        //         </center>
        //     ),
        // },
        {
            headerName: "Enable/Disable",
            field: "is_active",
            cellRendererFramework: (params) => (
                <center>
                    <Button
                        variant={params.data.is_active ? "success" : "danger"}
                        onClick={() => toggleGemstoneStatus(params.data.id, params.data.is_active)}
                    >
                        {params.data.is_active ? "Enable" : "Disable"}
                    </Button>
                </center>
            ),
        },
    ])

    const gemStoneAvailable = async () => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
    
        const url = `${baseURL}/admin/readGemstone?limit=10&page_no=1&gold_vendor_id=${vendorsDataaa?.id}`;
        console.log('Request URL:', url);
    
        try {
            const getGemstone = await axios.get(url, requestOptions);
            console.log('getGemstone Response:', getGemstone);
            if (getGemstone?.status === 200) {
                setRowData(getGemstone?.data?.data);
            } else {
                console.log('Error: Unexpected status code', getGemstone.status);
            }
        } catch (error) {
            if (error.response) {
                // The request was made, and the server responded with a status code that falls out of the range of 2xx
                console.log('Error Response:', error.response);
                if (error.response.status === 404) {
                    console.log('Error: Data not found (404)');
                } else {
                    console.log('Error: Unexpected response code', error.response.status);
                }
            } else if (error.request) {
                // The request was made, but no response was received
                console.log('Error: No response received', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error: Request setup', error.message);
            }
        }
    }
    
    useEffect(() => {
        gemStoneAvailable()
    },[])

    const permanentDeleteGemstone = async () => {
        const requestOptions = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        const permanentGemstoneDel = await axios.delete(`${baseURL}/admin/deleteGemstone?gemstone_id=${deleteId}`, requestOptions)
        console.log('permanentGemstoneDel', permanentGemstoneDel)
        if (permanentGemstoneDel?.status === 200) {
            gemStoneAvailable()
        } else {
            console.log('error ==>', permanentGemstoneDel)
        }
    }

    const inActivateGemstone = async () => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        const Gemstoneinactive = await axios.delete(`${baseURL}/admin/softDeleteGemstone?gemstone_id=${deleteId}`, requestOptions)
        console.log('Gemstoneinactive', Gemstoneinactive)
        if (Gemstoneinactive?.status === 200) {
            gemStoneAvailable()
        } else {
            console.log('error ==>', Gemstoneinactive)
        }
    }

    const toggleGemstoneStatus = async (gemstoneId, isActive) => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        const response = await axios.patch(`${baseURL}/admin/updateGemstone/status`, {
            gemstone_id: gemstoneId,
            is_active: !isActive
        }, requestOptions);
        if (response?.status === 200) {
            gemStoneAvailable();
        } else {
            console.log('error ==>', response);
        }
    };

    return (
        <div>
            <div class="sidebar">
                <SideBar type='Details' />
            </div>
            <div class="content">
                <div className="container">
                    <FirstNavbar />
                    <h3 className='headertext'>Gemstone List:</h3>
            <div
                className="ag-theme-alpine"
                style={{ height: "70vh", width: "100%" }}
            >

                <AgGridReact
                    rowHeight={rowHeight}
                    // columnDefs={columns}
                    columnDefs={colDefs}
                    defaultColDef={DefaultColumnSetting}
                    pagination={true}
                    paginationPageSize={10}
                    // onGridReady={onGridReady}
                    rowData={rowData}
                // onRowClicked={(e) => 
                //     navigate('/vendorData',{id:e.data._id})}
                />
            </div>
            </div>
            </div>
            <Modal
                show={showModal}
                onHide={closeModal}>
                {/* <Modal.Header closeButton>
                </Modal.Header> */}
                <Modal.Body closeButton>
                    <h3>Are you sure, you want to delete this Gemstone ...?</h3>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button variant="warning" onClick={inActivateGemstone}>
                        inActivate
                    </Button>
                    <Button variant="danger" onClick={permanentDeleteGemstone}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default GemstoneList