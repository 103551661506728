import React, { useState } from 'react';
import SideBar from '../../../dashboard/SideBar';
import FirstNavbar from '../../../dashboard/FirstNavbar';
import { Card, Form, Row, Col, Button, Modal } from 'react-bootstrap'; // Import Modal component
import axios from 'axios';
import baseURL from '../../authServices/Url';
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function AddBrand() {
    const navigate = useNavigate();
    const [name, setName] = useState('')
    let token = localStorage.getItem('accessToken');
    const vendorsDataaa = JSON.parse(localStorage.getItem('vendorDetails'));

    const handleSubmit = async() => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',
                },
              };
              const userCredentials = {
                'brand_name': name,
                'gold_vendor_id': vendorsDataaa?.id,
              }
              const addBrandEndorsement = await axios.post(`${baseURL}/admin/brandEdorsment`,userCredentials, requestOptions)
              console.log('addBrandEndorsement', addBrandEndorsement)
              if(addBrandEndorsement?.status === 200){
                toast.success(`Sub Brand succesfully created`);
                navigate('/subbrand')

              }else{
                console.log('error =>', addBrandEndorsement)
              }
        } catch (err) {
            console.log('error ==>', err)
        }
    }

    return (
        <div>
            <div class="sidebar">
                <SideBar type='Details' />
            </div>
            <div class="content">
                <div className="container">
                    <FirstNavbar />
                    <Card className='p-2'>
                        <div>
                            <h3 className='headertext'>Add Brand:</h3>
                            <div>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Sub Brand Name</Form.Label>
                                            <Form.Control
                                                maxLength={50}
                                                type="text"
                                                placeholder="Enter name"
                                                size="md"
                                                name='name'
                                                onChange={(e) => setName(e.target.value)}
                                                autoComplete='off'
                                                className='mb-3'
                                                value={name}
                                            />
                                            {name === '' &&
                                                <span className="text-danger">Please enter the sub brand name</span>}
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                            <Button variant="warning" onClick={() => handleSubmit()}>
                                Submit
                            </Button>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default AddBrand