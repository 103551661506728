import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap'; // Import Modal component
import SideBar from '../../dashboard/SideBar'
import FirstNavbar from '../../dashboard/FirstNavbar'
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import baseURL from '../authServices/Url';


function CustomerList() {
    let token = localStorage.getItem('accessToken');
    const [deleteId, setDeleteId] = useState('')
    const [showModal, setShowModal] = useState(false);

    const openModal = (id) => {
        setShowModal(true);
        setDeleteId(id)
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const navigate = useNavigate();
    const [rowData, setRowData] = useState([]);

    const rowHeight = 100;
    const DefaultColumnSetting = {
        sortable: true,
        flex: 1,
        resizable: true,
        minWidth: 120,
    };

    const [colDefs, setColDefs] = useState([
        {
            headerName: "#",
            valueGetter: "node.rowIndex + 1",
            lockPosition: true,
        },
        {
            headerName: "customer Name",
            field: "user_first_name",
            filter: true,
        },
        {
            headerName: "Gender ",
            filter: true,
            field: "user_gender",

        },
        {
            headerName: "Location ",
            filter: true,
            field: "user_location",
        },
        {
            headerName: "Logo",
            field: 'gold_customer_logo',
            cellRendererFramework: (params) => {
                console.log('user_profile_image :', params?.data?.user_profile_image)
                return (
                    <img src={params?.data?.user_profile_image} alt='logo' style={{ height: '100%', width: '100%', objectFit: 'contain' }} />
                )
            }
        },
        {
            headerName: "View",
            field: "view",
            cellRendererFramework: (params) => (
                <center>
                    <FontAwesomeIcon icon={faEye} style={{ color: '#BE783B' }} onClick={() => { 
                     localStorage.setItem('userId', JSON.stringify(params.data));
                    
                        navigate('/customerData', { state: params.data }) }}> </FontAwesomeIcon>{" "}
                </center>
            ),
        },
      
        {
            headerName: "Delete",
            cellRendererFramework: (params) => (
                <center>
                    <FontAwesomeIcon icon={faTrash} style={{ color: '#f00' }} onClick={() => openModal(params?.data?.id)}> </FontAwesomeIcon>{" "}
                </center>
            ),
        },
    ])

    const customerAvailable = async () => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        const getcustomer = await axios.get(`${baseURL}/admin/adminCustomers`, requestOptions)
        console.log('getcustomer@@@@@@@@@@@@@@@@@@@', getcustomer)
        if (getcustomer?.status === 200) {
            setRowData(getcustomer?.data?.data)
        } else {
            console.log('error')
        }
    }

    useEffect(() => {
        customerAvailable();
    }, [])

    const permanentDeletecustomer = async () => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        const permanentcustomerDel = await axios.delete(`${baseURL}/admin/deleteGoldcustomer?gold_customer_id=${deleteId}`, requestOptions)
        console.log('permanentcustomerDel', permanentcustomerDel)
        if (permanentcustomerDel?.status === 200) {
            customerAvailable()
        } else {
            console.log('error ==>', permanentcustomerDel)
        }
    }

    const inActivatecustomer = async () => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        const customerinactive = await axios.delete(`${baseURL}/admin/softDeleteGoldcustomer?gold_customer_id=${deleteId}`, requestOptions)
        console.log('customerinactive', customerinactive)
        if (customerinactive?.status === 200) {
            customerAvailable()
        } else {
            console.log('error ==>', customerinactive)
        }
    }

    return (
        <div>
            {/* <div class="sidebar">
                <SideBar />
            </div> */}
            <div class="content">
                <div className="container">
                    {/* <FirstNavbar /> */}
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} className='py-1'>
                        <h3 className='headertext'>Customer Data</h3>
                        {/* <input class="btn btn-primary" type="button" value="Add customer" onClick={openModal} /> */}
                    </div>

                    <div
                        className="ag-theme-alpine"
                        style={{ height: "70vh", width: "100%" }}
                    >
                        <AgGridReact
                            rowHeight={rowHeight}
                            columnDefs={colDefs}
                            defaultColDef={DefaultColumnSetting}
                            pagination={true}
                            paginationPageSize={10}
                            rowData={rowData}
                        // onRowClicked={(e) => 
                        //     navigate('/customerData',{id:e.data._id})}
                        />
                    </div>
                    {/* delete modal */}
                    <Modal show={showModal} onHide={closeModal}>
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>
                            <h3>Are you sure, you want to delete this customer ....?</h3>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={closeModal}>
                                Cancel
                            </Button>
                            <Button variant="warning" onClick={inActivatecustomer}>
                                inActivate
                            </Button>
                            <Button variant="danger" onClick={permanentDeletecustomer}>
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        </div>
    )
}

export default CustomerList