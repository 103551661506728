import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Card, Row, Col, Figure, Table, Button } from 'react-bootstrap';
import SideBar from '../../dashboard/SideBar';
import FirstNavbar from '../../dashboard/FirstNavbar';
import axios from 'axios';

function CustomerWishlist() {
    const [wishlist, setWishlist] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;

    const userId = localStorage.getItem("userId")
    const newUserId = JSON.parse(userId);
    const {user_credential_id}= newUserId;


    const CustomerWishlistAvailable = async () => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
            },
        };
        const getwishlists = await axios.get(`http://localhost:8001/api/v1/admin/userWishlist?user_id=${user_credential_id}`, requestOptions);
        if (getwishlists?.status === 200) {
            setWishlist(getwishlists?.data);
        } else {
            console.log('error');
        }
    };

    useEffect(() => {
        CustomerWishlistAvailable();
    }, []);

    const handleNextPage = () => {
        setCurrentPage(prevPage => prevPage + 1);
    };

    const handlePreviousPage = () => {
        setCurrentPage(prevPage => (prevPage > 1 ? prevPage - 1 : 1));
    };

    const paginatedData = Array.isArray(wishlist)
        ? wishlist.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
        : [];

    return (
        <div>
            <div className="sidebar">
                <SideBar type='Details' />
            </div>
            <div className="content">
                <div className="container">
                    <FirstNavbar />
                    <h3 className='headertext'>Customer Data</h3>
                    <div>
                        
                        <Card className="p-2 mt-4">
                            <h3>Wishlist</h3>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Sl. No</th>
                                        <th>Product Name</th>
                                        <th>Total Price</th>
                                        <th>Image</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.isArray(paginatedData) && paginatedData.length > 0 ? (
                                        paginatedData.map((item, index) => (
                                            item.products_details.map((product, subIndex) => (
                                                <tr key={`${index}-${subIndex}`}>
                                                    <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                    <td>{product.product_name}</td>
                                                    <td>{product.total_price}</td>
                                                    <td>
                                                        <Figure>
                                                            <Figure.Image
                                                                width={100}
                                                                height={100}
                                                                alt={product.product_name}
                                                                src={product.product_image}
                                                            />
                                                        </Figure>
                                                    </td>
                                                </tr>
                                            ))
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="4">No wishlist data available</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                            <div className="d-flex justify-content-between">
                                <Button onClick={handlePreviousPage} disabled={currentPage === 1}>
                                    Previous Page
                                </Button>
                                <Button onClick={handleNextPage} disabled={paginatedData.length < itemsPerPage}>
                                    Next Page
                                </Button>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CustomerWishlist;
