import React,{useEffect,useState} from 'react'
import SideBar from '../../dashboard/SideBar'
import FirstNavbar from '../../dashboard/FirstNavbar'
import { Card, Row, Col } from 'react-bootstrap'
import Plot from 'react-plotly.js'
import axios from 'axios'
import baseURL from '../authServices/Url'

function DashboardAdmin() {
  let url="https://Aunest-admin-api.moshimoshi.cloud/user/usercount"
  let token=localStorage.getItem('accessToken');

const [customerCount,setCustomerCount] = useState(0)
const [vendorCount,setVendorCount] = useState(0)

const gemStoneAvailable = async () => {
  const requestOptions = {
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  const getGemstone = await axios.get(`${baseURL}/admin/readGemstone?limit=10&page_no=1&gold_vendor_id=43a40c02-53c2-4cca-b6c8-f6e3823a0f6c`, requestOptions)
  console.log('getGemstone@@@@@@@@@@@@@@@@@@@', getGemstone)
  if (getGemstone?.status === 200) {
  //   setProductList(getGemstone?.data?.data)
  } else {
    console.log('error')
  }
}

const goldVendorRequest = async () => {
  const requestOptions = {
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  const getGemstone = await axios.get(`${baseURL}/admin/readGoldVendor?limit=10&page_no=1`, requestOptions)
  console.log('getGemstone@@@@@@@@@@@@@@@@@@@', getGemstone)
  if (getGemstone?.status === 200) {
  //   setProductList(getGemstone?.data?.data)
  } else {
    console.log('error')
  }
}

useEffect(() => {
  gemStoneAvailable();
  goldVendorRequest();
}, [])


  // useEffect(() => {
    
  //   axios.get(url,{headers:{"x-access-token":token}}).then((response) => {
  //     console.log(response.data.data,"RESPONSE DATA")
  //     if(response.data.error==false){
  //       response.data.data.map(count=>{
  //         if(count.type=="customer"){
  //           setCustomerCount(count.count)
  //         }
  //         else if(count.type=="vendor"){
  //           setVendorCount(count.count)
  //         }
  //       })
  //     }
  //   });
  // }, []);

  return (
    <div>
      <div class="sidebar">
        <SideBar />
      </div>
      <div class="content">
        <div className="container">
          <FirstNavbar />
          <h3 className='headertext'>Dashboard</h3>
          <div>

            <Card>
              <Card.Body>
                {/* <Card.Title>Dashboard</Card.Title> */}
                <Card>
                  <Card.Body>
                    <Row>
                      <Col md={3}>
                        <Card.Title className='headertext'>Customer Data</Card.Title>
                        <Card.Text>
                          Total number of Customers
                        </Card.Text>
                        <div style={{ borderRadius: 50, height: 100, width: 100, border: '2px solid #BE783B', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                          <h1 style={{}}>
                            {customerCount}
                          </h1>
                        </div>
                      </Col>
                      {/* <Col md={9}>
                        <Plot
                          data={[
                            {
                              x: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                              y: [19, 14, 22, 14, 16, 19, 15, 14, 10, 12, 12, 16],
                              type: 'bar',
                            }

                          ]}
                          layout={{
                            width: '80%', height: 440, title: 'Chart showing no. of customers registered in the year 2020', xaxis: {
                              tickangle: -45
                            },
                          }}
                        />
                      </Col> */}
                    </Row>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Body>
                    <Card.Title className='headertext'>Vendor Data</Card.Title>
                    <Row>
                      <Col md={3}>
                        <Card.Text>
                          Total number of Vendors
                        </Card.Text>
                        <div style={{ borderRadius: 50, height: 100, width: 100, border: '2px solid #BE783B', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                          <h1 style={{}}>
                            {vendorCount}
                          </h1>
                        </div>
                      </Col>
                      {/* <Col md={9}>
                        <Plot
                          data={[
                            {
                              x: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                              y: [19, 14, 22, 14, 16, 19, 15, 14, 10, 12, 12, 16],
                              type: 'bar',
                            }

                          ]}
                          layout={{
                            width: '80%', height: 440, title: 'Chart showing no. of vendors registered in the year 2020', xaxis: {
                              tickangle: -45
                            },
                          }}
                        />
                      </Col> */}
                    </Row>
                  </Card.Body>
                </Card>
              </Card.Body>
            </Card>

          </div>
        </div>
      </div>


      {/* <div className="row my-2">
              <div className="col-md-2">
                <h3>
                  <b>Dashboard</b>
                </h3>
              </div>
              </div> */}
      {/* <h1></h1> */}
    </div>
  )
}

export default DashboardAdmin