import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {Card, Table} from 'react-bootstrap';
import SideBar from '../../dashboard/SideBar';
import FirstNavbar from "../../dashboard/FirstNavbar";

const UserCartDetails = () => {
    const [cartData, setCartData] = useState([]);
    const userId = localStorage.getItem("userId");
    const newUserId = JSON.parse(userId);
    const { user_credential_id } = newUserId;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`http://localhost:8001/api/v1/admin/userCartDetails?user_id=${user_credential_id}`);
                setCartData(response.data.data[0].cart_items.product_data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <div>
            <div className="sidebar">
                <SideBar type='Details' />
            </div>
            <div className="content">
                <div className="container">
                    <FirstNavbar />
                    <h3 className='headertext'>Customer Data</h3>
                    <div>
                    <Card className="p-2 mt-4">
                    <h3>User Cart Details</h3>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Sl. No.</th>
                        <th>Product Name</th>
                        <th>Quantity</th>
                        <th>Total Price</th>
                        <th>Product Image</th>
                    </tr>
                </thead>
                <tbody>
          {cartData.map((product, index) => (
            <tr key={product.id}>
              <td>{index + 1}</td>
              <td>{product.product_name}</td>
              <td>{product.price_breakup.product_quantity}</td>
              <td>{product.price_breakup.total_price.toFixed(2)}</td>
              <td>
                <img src={product.product_image[0]} alt={product.product_name} style={{ width: '100px' }} />
              </td>
            </tr>
          ))}
        </tbody>
            </Table>
            </Card>

                       
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserCartDetails;
