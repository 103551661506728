import React from 'react'
import { Card, Figure, Row, Col } from 'react-bootstrap'
const image = require('../../../assets/imagesCustomer/image.png');

function Profile(props) {
  const {data} = props
    console.log('image', image);
    return (
        <div>
            <Card className="p-2">
                <h3 className='headertext'>Customer Profile Details:</h3>
                <Row>
                    <Figure>
                        <Figure.Image
                            width={100}
                            height={100}
                            // alt="171x180"
                            src={data?.image}
                        />
                    </Figure>
                </Row>
                <hr />
                <h4 className='headertext'>Personal Deatils</h4>
                <Row>
                   <Col md={3}>
                    <h5>Name:</h5>
                   </Col>
                   <Col md={3}>
                     <h5>{data.name}</h5>
                   </Col>
                </Row>
                <Row>
                   <Col md={3}>
                    <h5>Phone Number:</h5>
                   </Col>
                   <Col md={3}>
                     <h5>{data.phone}</h5>
                   </Col>
                </Row>
                <Row>
                   <Col md={3}>
                    <h5>E-mail Id :</h5>
                   </Col>
                   <Col md={3}>
                     <h5>{data?.email}</h5>
                   </Col>
                </Row>
                {/* <Row>
                   <Col md={3}>
                    <h5>Gender :</h5>
                   </Col>
                   <Col md={3}>
                     <h5>male</h5>
                   </Col>
                </Row> */}
                <hr />
                {/* <h4 className='headertext'>Bank Deatils</h4> */}
                {/* <Row>
                   <Col md={3}>
                    <h5>Bank Name:</h5>
                   </Col>
                   <Col md={3}>
                     <h5>SBI</h5>
                   </Col>
                </Row> */}
                {/* <Row>
                   <Col md={3}>
                    <h5>Account Number:</h5>
                   </Col>
                   <Col md={3}>
                     <h5>8756476456</h5>
                   </Col>
                </Row> */}
                {/* <Row>
                   <Col md={3}>
                    <h5>IFSC Code</h5>
                   </Col>
                   <Col md={3}>
                     <h5>ifsc00076456</h5>
                   </Col>
                </Row> */}
                
            </Card>
        </div>
    )
}

export default Profile