import React, { useState, useEffect } from 'react';
import FirstNavbar from '../dashboard/FirstNavbar';
import SideBar from '../dashboard/SideBar';
import { Button, Modal, Card, Row, Col, Form } from 'react-bootstrap';
import { toast } from "react-toastify";
import axios from 'axios';
import baseURL from '../adminPanel/authServices/Url';
import Multiselect from 'multiselect-react-dropdown';
import { useLocation, useNavigate } from 'react-router-dom';

function AddCoupons() {
    let token = localStorage.getItem('accessToken');
    const { state } = useLocation();
    const navigate = useNavigate();
    const couponData = state
    // console.log("couponData:____________________", couponData);

    const couponId = couponData?.id

    const [coupon, setCoupon] = useState({
        name: couponData?.coupon_name || "",
        code: couponData?.coupon_code || "",
        // discountType: couponData?.coupon_discount_type || "",
        discountValue: couponData?.coupon_discount_value || 0,
        minimumPurchaseAmount: couponData?.coupon_minimum_purchase_amount || 0,
        maximumDiscountValue: couponData?.coupon_maximum_discount_value || 0,
        coupontoVendor: couponData?.coupon_to_vendor || "",
        couponQuantity: couponData?.coupon_quantity || 0,
        startDate: couponData?.coupon_start_date || "",
        closeDate: couponData?.coupon_end_date || "",
        description: couponData?.coupon_description || "",
    })
    // console.log("coupon: _____________", JSON.stringify(coupon, null, 2));
    const [couponErrors, setCouponErrors] = React.useState({
        name: '',
        code: '',
        discountType: '',
        discountValue: '',
        minimumPurchaseAmount: '',
        maximumDiscountValue: '',
        coupontoVendor: '',
        couponQuantity: '',
        startDate: '',
        closeDate: '',
        description: '',
        couponType: '',
    })
    const [discountType, setDiscountType] = useState('')
    const [couponType, setCouponType] = useState('')
    const [selectedVendor, setSelectedVendor] = useState([]);
    const [selectedVendor1, setSelectedVendor1] = useState([]);
    const [vendorData, setVendorData] = useState([]);

    useEffect(() => {
        if (couponData) {
            setCoupon({
                name: couponData.coupon_name || "",
                code: couponData.coupon_code || "",
                discountValue: couponData.coupon_discount_value || 0,
                minimumPurchaseAmount: couponData.coupon_minimum_purchase_amount || 0,
                maximumDiscountValue: couponData.coupon_maximum_discount_value || 0,
                couponQuantity: couponData.coupon_quantity || 0,
                startDate: couponData.coupon_start_date || "",
                closeDate: couponData.coupon_end_date || "",
                description: couponData.coupon_description || "",
            });
            setDiscountType(couponData.coupon_discount_type || "");
            setCouponType(couponData.coupon_to_vendor || "");
            if (couponData.coupon_to_vendor === 'PARTICULAR' && couponData.coupon_to_vendor_id) {
                const vendorIds = Array.isArray(couponData.coupon_to_vendor_id)
                    ? couponData.coupon_to_vendor_id
                    : [couponData.coupon_to_vendor_id];
                setSelectedVendor(vendorIds);

                const selectedVendorObjects = vendorData.filter(vendor => vendorIds.includes(vendor.id));
                setSelectedVendor1(selectedVendorObjects);
            }
        }
    }, [couponData, vendorData]);

    const goldVendorRequest = async () => {
        try {
            const requestOptions = {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            };
            const response = await axios.get(`${baseURL}/admin/readGoldVendor?limit=10&page_no=1`, requestOptions);
            // console.log('getVendor@@@@@@@@@@@@@@@@@@@', response);
            if (response?.status === 200 && Array.isArray(response.data?.data)) {
                setVendorData(response.data.data);
            } else {
                console.log('error: Invalid data format');
                setVendorData([]);
            }
        } catch (error) {
            console.log('error', error);
            setVendorData([]);
        }
    };

    useEffect(() => {
        goldVendorRequest();
    }, [])

    const onSelectVendor = (selectedList, selectedItem) => {
        const selectedVendorIds = selectedList.map((item) => item.id);
        setSelectedVendor(selectedVendorIds);
        setSelectedVendor1(selectedList);
    };

    const onRemoveVendor = (selectedList, removedItem) => {
        const selectedVendorIds = selectedList.map((item) => item.id);
        setSelectedVendor(selectedVendorIds);
        setSelectedVendor1(selectedList);
    };

    const handleDiscountTypeChange = (e) => {
        setDiscountType(e.target.value)
    }
    const handleCouponTypeChange = (e) => {
        setCouponType(e.target.value)
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCoupon(prevState => ({
            ...prevState,
            [name]: value
        }));
        setCouponErrors({
            ...couponErrors,
            [e.target.name]: null,
            [e.target.description]: null,
            [e.target.code]: null,
            [e.target.startDate]: null,
            [e.target.closeDate]: null,
            [e.target.discountType]: null,
            [e.target.discountValue]: null,
            [e.target.minimumPurchaseAmount]: null,
            [e.target.maximumDiscountValue]: null,
            [e.target.coupontoVendor]: null,
            [e.target.couponQuantity]: null,
        });
    };


    const handleValidation = () => {
        const { description, name, code, startDate, closeDate, discountValue, minimumPurchaseAmount, maximumDiscountValue, couponQuantity } = coupon
        const newErrors = {}

        if (!name) {
            newErrors.name = 'please enter coupon name'
        }
        if (!code) {
            newErrors.code = 'please enter coupon code'
        }
        if (!description) {
            newErrors.description = 'please enter description'
        }
        if (!startDate) {
            newErrors.startDate = 'please select start date'
        }
        if (!closeDate) {
            newErrors.closeDate = 'please select close date'
        }
        if (!discountType) {
            newErrors.discountType = 'please enter discount type'
        }
        if (!discountValue) {
            newErrors.discountValue = 'please enter discount value'
        }
        if (!minimumPurchaseAmount) {
            newErrors.minimumPurchaseAmount = 'please enter minimum purchase amount'
        }
        if (!maximumDiscountValue && discountType == "PERCENTAGE") {
            newErrors.maximumDiscountValue = 'please enter maximum discount value'
        }
        if (!selectedVendor) {
            newErrors.coupontoVendor = 'please coupon to vendor'
        }
        if (!couponType) {
            newErrors.couponType = 'please add a vendor'
        }
        if (!couponQuantity) {
            newErrors.couponQuantity = 'please enter coupon quantity'
        }

        return newErrors
    }

    const handleSubmit = async () => {
        try {
            const handleValidationObject = handleValidation()

            if (Object.keys(handleValidationObject).length > 0) {
                setCouponErrors(handleValidationObject)
            } else {
                const requestOptions = {
                    method: couponId ? 'PATCH' : 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                };
                const userCredentials = {
                    "coupon_name": coupon.name,
                    "coupon_description": coupon.description,
                    "coupon_code": coupon.code,
                    "coupon_discount_type": discountType,
                    "coupon_discount_value": Number(coupon.discountValue),
                    "coupon_minimum_purchase_amount": Number(coupon.minimumPurchaseAmount),
                    "coupon_maximum_discount_value": Number(coupon.maximumDiscountValue),
                    "coupon_to_vendor": couponType,
                    "coupon_quantity": Number(coupon.couponQuantity),
                    "coupon_start_date": coupon.startDate,
                    "coupon_end_date": coupon.closeDate
                };

                if (couponType === 'PARTICULAR') {
                    userCredentials["coupon_to_vendor_id"] = Array.isArray(selectedVendor) ? selectedVendor : [selectedVendor];
                }

                // console.log("userCredentials: ", JSON.stringify(userCredentials, null, 2));

                let response;

                if (couponId) {
                    response = await axios.patch(`${baseURL}/productCoupon?coupon_id=${couponId}`, userCredentials, requestOptions);
                } else {
                    response = await axios.post(`${baseURL}/productCoupon`, userCredentials, requestOptions);
                }

                if (response?.status === 200) {
                    if (couponId) {

                        toast.success(`Coupon successfully edited`);

                        setTimeout(() => {
                            navigate("/clipboard")
                        }, 1000);
                    } else {

                        setCoupon({
                            name: '',
                            code: '',
                            discountType: '',
                            discountValue: 0,
                            minimumPurchaseAmount: 0,
                            maximumDiscountValue: 0,
                            coupontoVendor: '',
                            couponQuantity: 0,
                            startDate: '',
                            closeDate: '',
                            description: '',
                        });
                        toast.success(`Coupon successfully created`);

                        setTimeout(() => {
                            navigate("/clipboard")
                        }, 1000);
                    }
                } else {
                    console.log(response, "error after submit");
                    toast.error(`${response.data.message}`);
                }
            }
        } catch (e) {
            console.log('error ==>', e);
            toast.error(`${e?.response?.data?.message || 'An error occurred'}`);
        }
    }
    return (
        <div>
            <div class="sidebar">
                <SideBar type='Dashboard' />
            </div>
            <div class="content">
                <div className="container">
                    <FirstNavbar />
                    <h3 className='headertext'>Coupon Data</h3>
                    <Card style={{ padding: 20 }}>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Coupon Name</Form.Label>
                                    <Form.Control
                                        maxLength={50}
                                        type="text"
                                        placeholder="Enter coupon name"
                                        size="sm"
                                        name='name'
                                        onChange={(e) => handleChange(e)}
                                        autoComplete='off'
                                        className='mb-3'
                                        value={coupon?.name}
                                    />
                                    <span className="text-danger">{couponErrors?.name}</span>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Code</Form.Label>
                                    <Form.Control
                                        maxLength={50}
                                        type="text"
                                        placeholder="Enter Coupon Code"
                                        size="sm"
                                        name='code'
                                        onChange={(e) => handleChange(e)}
                                        autoComplete='off'
                                        className='mb-3'
                                        value={coupon?.code}
                                    />
                                    <span className="text-danger">{couponErrors?.code}</span>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Discount Type</Form.Label>
                                    <div className="leftedge d-flex justify-content-space">
                                        <Form.Select
                                            aria-label="select Metal"
                                            size={"sm"}
                                            className="selectsizesmall"
                                            name="discountType"
                                            onChange={(e) => handleDiscountTypeChange(e)}
                                            value={discountType || ""}
                                        >
                                            <option >Select Discount Type</option>
                                            <option name='PERCENTAGE' value='PERCENTAGE'>Percentage</option>
                                            <option name='FIXED_AMOUNT' value='FIXED_AMOUNT'>Fixed Amount</option>
                                        </Form.Select>
                                    </div>
                                    <span className="text-danger">{couponErrors?.discountType}</span>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        {
                                            discountType == "PERCENTAGE" ? "Discount in Percentage" : "Discount Value"}</Form.Label>
                                    <Form.Control
                                        maxLength={50}
                                        type="number"
                                        placeholder="Enter Discount Value"
                                        size="sm"
                                        name='discountValue'
                                        onChange={(e) => handleChange(e)}
                                        autoComplete='off'
                                        className='mb-3'
                                        value={coupon?.discountValue}
                                    />
                                    <span className="text-danger">{couponErrors?.discountValue}</span>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            {discountType === "PERCENTAGE" && (
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Maximum Coupon Discount Value</Form.Label>
                                        <Form.Control
                                            maxLength={50}
                                            type="number"
                                            placeholder="Enter Maximum Discount Value"
                                            size="sm"
                                            name='maximumDiscountValue'
                                            onChange={(e) => handleChange(e)}
                                            autoComplete='off'
                                            className='mb-3'
                                            value={coupon?.maximumDiscountValue}
                                        />
                                        <span className="text-danger">{couponErrors?.maximumDiscountValue}</span>
                                    </Form.Group>
                                </Col>
                            )}
                            <Col md={discountType === "PERCENTAGE" ? 6 : 12}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Minimum Purchase Amount</Form.Label>
                                    <Form.Control
                                        maxLength={50}
                                        type="number"
                                        placeholder="Enter Minimum Purchase Amount"
                                        size="sm"
                                        name='minimumPurchaseAmount'
                                        onChange={(e) => handleChange(e)}
                                        autoComplete='off'
                                        className='mb-3'
                                        value={coupon?.minimumPurchaseAmount}
                                    />
                                    <span className="text-danger">{couponErrors?.minimumPurchaseAmount}</span>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Coupon to Vendor</Form.Label>
                                    <div className="leftedge d-flex justify-content-space">
                                        <Form.Select
                                            aria-label="select Metal"
                                            size={"sm"}
                                            className="selectsizesmall"
                                            name="couponType"
                                            onChange={(e) => handleCouponTypeChange(e)}
                                            value={couponType || ""}
                                        >
                                            <option >Select Coupon Applies to</option>
                                            <option name='ALL' value='ALL'>All</option>
                                            <option name='PARTICULAR' value='PARTICULAR'>Particular</option>
                                        </Form.Select>
                                    </div>
                                    <span className="text-danger">{couponErrors?.coupontoVendor}</span>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Coupon Quantity</Form.Label>
                                    <Form.Control
                                        maxLength={50}
                                        type="number"
                                        placeholder="Enter Coupon Quantity"
                                        size="sm"
                                        name='couponQuantity'
                                        onChange={(e) => handleChange(e)}
                                        autoComplete='off'
                                        className='mb-3'
                                        value={coupon?.couponQuantity}
                                    />
                                    <span className="text-danger">{couponErrors?.couponQuantity}</span>
                                </Form.Group>
                            </Col>
                        </Row>
                        {couponType === 'PARTICULAR' &&
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Coupon to Vendors</Form.Label>
                                        <div className="leftedge d-flex justify-content-space">
                                            <Multiselect
                                                options={vendorData || []}
                                                displayValue="gold_vendor_name"
                                                onSelect={onSelectVendor}
                                                onRemove={onRemoveVendor}
                                                selectedValues={selectedVendor1}
                                                placeholder="Select Vendor"
                                                value={selectedVendor}
                                            />
                                        </div>
                                        <span className="text-danger">{couponErrors?.couponType}</span>
                                    </Form.Group>
                                </Col>
                            </Row>}
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Coupon Start Date</Form.Label>
                                    <Form.Control
                                        maxLength={50}
                                        type="date"
                                        placeholder="Enter Start Date"
                                        size="sm"
                                        name='startDate'
                                        onChange={(e) => handleChange(e)}
                                        autoComplete='off'
                                        className='mb-3'
                                        value={coupon?.startDate}
                                    />
                                    <span className="text-danger">{couponErrors?.startDate}</span>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Coupon Close Date</Form.Label>
                                    <Form.Control
                                        maxLength={50}
                                        type="date"
                                        placeholder="Enter Close Date"
                                        size="sm"
                                        name='closeDate'
                                        onChange={(e) => handleChange(e)}
                                        autoComplete='off'
                                        className='mb-3'
                                        value={coupon?.closeDate}
                                    />
                                    <span className="text-danger">{couponErrors?.closeDate}</span>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        as="textarea" rows={3}
                                        placeholder="Enter description"
                                        size="md"
                                        name='description'
                                        onChange={(e) => handleChange(e)}
                                        autoComplete='off'
                                        className='mb-3'
                                        value={coupon?.description}
                                    />
                                    <span className="text-danger">{couponErrors?.description}</span>
                                </Form.Group>
                            </Col>
                        </Row>
                        <div>
                            <Button variant="warning"
                                onClick={() => handleSubmit()}
                            // onClick={() => { selectedProduct?.id ? handleSubmitEdit() : handleSubmit() }}
                            >
                                Submit
                            </Button>
                        </div>
                    </Card>
                </div>
            </div>
        </div >
    )
}

export default AddCoupons