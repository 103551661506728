import React from 'react'
import { useState } from "react";
// import { Link } from "react-router-dom";
import baseURL from '../adminPanel/authServices/Url';
// import logo from "../../../assets/images/logo.png";
import { ToastContainer, toast, Zoom } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { Spinner } from "react-bootstrap";
import logoAunest from './../../assets/images/LogoAunest.png'
import axios from 'axios';

const Login1 = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false)
  const [loginForm, setLoginForm] = useState({
    phone: '',
    password: ''
  })
  const [loginFormErrors, setLoginFormErrors] = useState({
    phone: '',
    password: ''
  })
  const handleChange = (event) => {
    console.log('event.target.name', event.target.name);
    setLoginForm({
      ...loginForm, [event.target.name]: event.target.value
    })
    setLoginFormErrors({
      ...loginFormErrors, [event.target.name]: null
    })
  }

  const handleValidation = () => {
    const { phone, password } = loginForm
    const newErrors = {}
    if (!phone) {
      newErrors.phone = 'please enter user name'
    }
    else if (!phone) {
      newErrors.phone = 'user name should be text'
    }
    else if (phone && phone.length > 50) {
      newErrors.phone = 'username should be below 50 digits'
    }

    if (!password) {
      newErrors.password = 'please enter password'
    } else if (password && password.length > 50) {
      newErrors.password = 'password should be below 50 digits'
    }
    return newErrors

  }

  const handleSubmit = async() => {
    const handleValidationObject = handleValidation()
    if (Object.keys(handleValidationObject).length > 0) {
      setLoginFormErrors(handleValidationObject)
    } else {
      setLoader(true)
      let userCredentials = { 'admin_username': loginForm.phone, 'admin_password':loginForm.password }
      const requestOptions = {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
      };
      try {
        const adminLogin = await axios.post(`${baseURL}/admin/superAdminlogin`, userCredentials, requestOptions);
        console.log('response adminLogin', adminLogin);
      
        if (adminLogin.status === 200) {
          localStorage.setItem('accessToken', adminLogin.data.adminAccessTokne); 
          toast.success('Login Successfully');
          navigate('/clipboard'); 
          setLoader(false);
          setLoginForm({
            ...loginForm,
            phone: '',
            password: ''
          });
        } else {
          
          toast.error('User credentials are invalid');
          setLoader(false);
        }
      } catch (error) {
      
        console.error('Error response adminLogin', error.response);
      
        if (error.response) {
          
          if (error.response.status === 404) {
            toast.error('Super Admin With User Name Does Not Exists');
          } else {
            toast.error(`Error: ${error.response.data.message || 'Something went wrong'}`);
          }
        } else {
        
          toast.error('Network error: Please try again later');
        }
      
        setLoader(false);
      }
      
    }
  }


return (
  <div>
    {" "}
    <div>
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar
        transition={Zoom}
        delay={500}
        limit={1}
      />
      <div class="login">
        <div className="logo-container2">
          <div className="logo-container">
            <img src={logoAunest} alt="" />
          </div>
        </div>

        <h2 class="login-header">Log in </h2>
        <p>
          <input
            type="text"
            placeholder="User Name"
            name='phone'
            autoComplete="off"
            value={loginForm.phone}
            onChange={handleChange}
          />
          <span className="text-danger" >{loginFormErrors.phone}</span>
        </p>
        <p>
          <input
            type="password"
            placeholder="Password"
            name='password'
            autoComplete="off"
            value={loginForm.password}
            onChange={handleChange}
          />
          <span className="text-danger" >{loginFormErrors.password}</span>
        </p>
        <p
          onClick={handleSubmit}
        // class="login-header"
        >
          {/* <Link to="/dashboard"> */}
          {
            loader == true ? '' :
              <input
                type="submit"
                value="Log in"
              // class="login-header"
              />
          }
          {/* </Link> */}
          {
            loader == true && <div style={{ marginLeft: '170px' }} >
              <Spinner animation="border" variant="Primary" />
            </div>
          }
        </p>
      </div>
    </div>
  </div>
);
};

export default Login1