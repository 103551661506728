import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AgGridReact } from 'ag-grid-react';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { faEye } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import baseURL from '../adminPanel/authServices/Url';

const BrandEndorsement = ({ props }) => {
    const navigate = useNavigate();
    const [rowData, setRowData] = useState([]);
    console.log("rowData: ", rowData);

    const rowHeight = 100;
    const DefaultColumnSetting = {
        sortable: true,
        flex: 1,
        resizable: true,
        minWidth: 120,
    };


    const fetchBrandEndorsements = async () => {
        try {
            const response = await axios.get(`${baseURL}/admin/brandEdorsment`);
            if (response.status === 200) {
                setRowData(response.data?.response);
            } else {
                console.log('Error fetching brand endorsements');
            }
        } catch (error) {
            console.error('Failed to fetch brand endorsements:', error);
        }
    };

    useEffect(() => {
        fetchBrandEndorsements();
    }, []);

    const colDefs = [
        {
            headerName: "#",
            valueGetter: "node.rowIndex + 1",
            lockPosition: true,
        },
        {
            headerName: "Vendor Name",
            field: "brand_name",
        },
        {
            headerName: "Vendor Logo",
            cellRendererFramework: (params) => (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', }}>
                    <img src={params?.data?.brand_logo} alt='logo' style={{ height: '100%', maxWidth: '100%', objectFit: 'contain' }} />
                </div>
            ),
        },
        {
            headerName: "Vendor Name",
            field: 'gold_vendor_name',
        },
        {
            headerName: "View",
            field: "view",
            cellRendererFramework: (params) => (
                <center>
                    <FontAwesomeIcon icon={faEye} style={{ color: '#BE783B', cursor: 'pointer' }} onClick={() => { navigate('/brandEndorsementData', { state: params.data, userType: props?.userType }) }} />
                </center>
            ),
        },
    ];

    return (
        <div>
            <div className="content">
                <div className="container">
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} className='py-1'>
                        <div
                            className="ag-theme-alpine"
                            style={{ height: "70vh", width: "100%" }}
                        >
                            <h3 className='headertext'>Brand Endorsement List</h3>
                            <AgGridReact
                                rowHeight={rowHeight}
                                columnDefs={colDefs}
                                defaultColDef={DefaultColumnSetting}
                                pagination={true}
                                paginationPageSize={10}
                                rowData={rowData}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BrandEndorsement;
