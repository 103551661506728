import React from 'react'
import FirstNavbar from '../../dashboard/FirstNavbar'
import SideBar from '../../dashboard/SideBar'

function BlockedVendorList() {
  return (
    <div>
            <div class="sidebar">
                <SideBar type='Dashboard' />
            </div>
            <div class="content">
                <div className="container">
                    <FirstNavbar />
                    <h3 className='headertext'>BlockedVendorList</h3>
                </div>
            </div>
        </div>
  )
}

export default BlockedVendorList