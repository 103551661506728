import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router-dom";
import baseURL from '../../authServices/Url';
import { Card, Form, Row, Col, Button, Modal } from 'react-bootstrap'; // Import Modal component
import SideBar from '../../../dashboard/SideBar';
import FirstNavbar from '../../../dashboard/FirstNavbar';

function Metal() {
    const navigate = useNavigate();
    const vendorsDataaa = JSON.parse(localStorage.getItem('vendorDetails'));

    let token = localStorage.getItem('accessToken');
    const [deleteId, setDeleteId] = useState('')
    const [showModal, setShowModal] = useState(false);
    const openModal = (id) => {
        console.log('@@@@@@@@@@@@@@@@@')
        setShowModal(true);
        setDeleteId(id)
    };

    const closeModal = () => {
        setShowModal(false);
    }
    const [rowData, setRowData] = useState([]);
    const rowHeight = 100;
    const DefaultColumnSetting = {
        sortable: true,
        //   filter: true,
        //   floatingFilter: true,
        flex: 1,
        resizable: true,
        minWidth: 120,
    };

    const [colDefs, setColDefs] = useState([
        {
            headerName: "#",
            valueGetter: "node.rowIndex + 1",
            // filter: true,
            lockPosition: true,
        },
        {
            headerName: "metal Name",
            field: "metal_name",
            filter: true,
        },
        {
            headerName: "Purity",
            // filter: true,
            field: "metal_purity",

        },
        {
            headerName: "Purity Unit",
            // filter: true,
            field: "metal_purity_unit",

        },
        {
            headerName: "Price/gram",
            filter: true,
            field: "metal_price_per_weight_unit",

        },
        {
            headerName: "View",
            field: "view",
            cellRendererFramework: (params) => (
                <center>
                    <FontAwesomeIcon icon={faEye} style={{ color: '#BE783B' }} onClick={() => { navigate('/metaldata', { state: params.data }) }}> </FontAwesomeIcon>{" "}
                </center>
            ),
        },
        // {
        //     headerName: "Delete",
        //     cellRendererFramework: (params) => (
        //         <center>
        //             <FontAwesomeIcon icon={faTrash} style={{ color: '#f00' }} onClick={() => openModal(params?.data?.id)}> </FontAwesomeIcon>
        //         </center>
        //     ),
        // },
        {
            headerName: "Enable/Disable",
            field: "is_active",
            cellRendererFramework: (params) => (
                <center>
                    <Button
                        variant={params.data.is_active ? "success" : "danger"}
                        onClick={() => toggleMetalStatus(params.data.id, params.data.is_active)}
                    >
                        {params.data.is_active ? "Enable" : "Disable"}
                    </Button>
                </center>
            ),
        },
    ])

    const metalAvailable = async () => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        const getmetal = await axios.get(`${baseURL}/admin/readMetals?gold_vendor_id=${vendorsDataaa?.id}`, requestOptions)
        console.log('getmetal@@@@@@@@@@@@@@@@@@@', getmetal)
        if (getmetal?.status === 200) {
            setRowData(getmetal?.data?.data)
        } else {
            console.log('error')
        }
    }
    
    useEffect(() => {
        metalAvailable()
    }, [])

    const permanentDeletemetal = async () => {
        const requestOptions = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        const permanentmetalDel = await axios.delete(`${baseURL}/admin/deleteMetal?metal_id=${deleteId}`, requestOptions)
        console.log('permanentmetalDel', permanentmetalDel)
        if (permanentmetalDel?.status === 200) {
            metalAvailable()
            closeModal()
        } else {
            console.log('error ==>', permanentmetalDel)
        }
    }

    const inActivatemetal = async () => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        const metalinactive = await axios.delete(`${baseURL}/admin/softDeletemetal?metal_id=${deleteId}`, requestOptions)
        console.log('metalinactive', metalinactive)
        if (metalinactive?.status === 200) {
            metalAvailable()
        } else {
            console.log('error ==>', metalinactive)
        }
    }

    const toggleMetalStatus = async (metalId, isActive) => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        const response = await axios.patch(`${baseURL}/admin/updateMetal`, {
            metal_id: metalId,
            is_active: !isActive
        }, requestOptions);
        if (response?.status === 200) {
            metalAvailable();
        } else {
            console.log('error ==>', response);
        }
    };

    return (
        <div>
            <div class="sidebar">
                <SideBar type='Details' />
            </div>
            <div class="content">
                <div className="container">
                    <FirstNavbar />
                    <h3 className='headertext'>Metal List:</h3>
                    <div
                        className="ag-theme-alpine"
                        style={{ height: "70vh", width: "100%" }}
                    >

                        <AgGridReact
                            rowHeight={rowHeight}
                            // columnDefs={columns}
                            columnDefs={colDefs}
                            defaultColDef={DefaultColumnSetting}
                            pagination={true}
                            paginationPageSize={10}
                            // onGridReady={onGridReady}
                            rowData={rowData}
                        // onRowClicked={(e) => 
                        //     navigate('/vendorData',{id:e.data._id})}
                        />
                    </div>
                </div>
            </div>
            <Modal
                show={showModal}
                onHide={closeModal}>
                {/* <Modal.Header closeButton>
              </Modal.Header> */}
                <Modal.Body closeButton>
                    <h3>Are you sure, you want to delete this metal ...?</h3>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button variant="warning" onClick={inActivatemetal}>
                        inActivate
                    </Button>
                    <Button variant="danger" onClick={permanentDeletemetal}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Metal