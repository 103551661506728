import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { Card, Row, Col, Image } from "react-bootstrap";
import SideBar from "../dashboard/SideBar";
import FirstNavbar from "../dashboard/FirstNavbar";
import axios from 'axios';
import baseURL from '../adminPanel/authServices/Url';

function BrandEndorsementData() {
    const { state } = useLocation();
    const [brandData, setBrandData] = useState(null);
    console.log("brandData: ", brandData);

    const brandId = state?.id;
    const goldVendorId = state?.gold_vendor_id;

    useEffect(() => {
        const fetchBrandData = async () => {
            try {
                const token = localStorage.getItem('accessToken');
                const response = await axios.get(
                    `${baseURL}/admin/brandEdorsmentByIdbrand?gold_vendor_id=${goldVendorId}&brandId=${brandId}`,
                    {
                        headers: { Authorization: `Bearer ${token}` }
                    }
                );
                setBrandData(response.data.data[0]);
            } catch (err) {
                console.error("Failed to fetch brand data", err);
            }
        };

        if (state && brandId && goldVendorId) {
            fetchBrandData();
        }
    }, [state, brandId, goldVendorId]);

    if (!brandData) return <div style={{ textAlign: "center" }}>Loading...</div>;

    return (
        <div>
            <div className="sidebar">
                <SideBar type='Details' />
            </div>
            <div className="content">
                <div className="container">
                    <FirstNavbar />
                    <h3 className='headertext'>Brand Endorsement Data</h3>
                    <div>
                        <Card className="p-2">
                            <Row>
                                <Col md={6}>
                                    <h3 className="headertext text1">Brand Details:</h3>
                                </Col>
                            </Row>
                            {/* <Row>
                                <Col md={1}></Col>
                                <Col md={4}>
                                    <h5>Brand ID</h5>
                                </Col>
                                <Col md={1}>:</Col>
                                <Col md={6}>
                                    <h5>{brandData.id}</h5>
                                </Col>
                            </Row> */}
                            <Row>
                                <Col md={1}></Col>
                                <Col md={4}>
                                    <h5>Brand Name</h5>
                                </Col>
                                <Col md={1}>:</Col>
                                <Col md={6}>
                                    <h5>{brandData.brand_name}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={1}></Col>
                                <Col md={4}>
                                    <h5>Brand Approved</h5>
                                </Col>
                                <Col md={1}>:</Col>
                                <Col md={6}>
                                    <h5>{brandData.brand_is_approved ? 'Yes' : 'No'}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={1}></Col>
                                <Col md={4}>
                                    <h5>Brand Logo</h5>
                                </Col>
                                <Col md={1}>:</Col>
                                <Col md={6}>
                                    <Image src={brandData.brand_logo} alt="Brand Logo" fluid width={100} height={100} style={{ objectFit: "cover" }} />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={1}></Col>
                                <Col md={4}>
                                    <h5>Gold Vendor Name</h5>
                                </Col>
                                <Col md={1}>:</Col>
                                <Col md={6}>
                                    <h5>{brandData.gold_vendor_name}</h5>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BrandEndorsementData;