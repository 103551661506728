import React, { useState, useEffect } from 'react'
import { useLocation } from "react-router-dom";
import {
    Card,
    Row,
    Col,
    Figure,
    Table,
    Button,
    Modal,
    Form,
} from "react-bootstrap";
import SideBar from "../../../dashboard/SideBar";
import FirstNavbar from "../../../dashboard/FirstNavbar";
import axios from 'axios';
import baseURL from '../../authServices/Url';
import { toast } from "react-toastify";

function OrderData() {
    const { state } = useLocation();
    const orderData = state;

    return (
        <div>
            <div class="sidebar">
                <SideBar type='Details'/>
            </div>
            <div class="content">
                <div className="container">
                    <FirstNavbar />
                    <h3 className='headertext'>Order Data</h3>
                    <div>
                        <Card className="p-2">
                            <Row>
                                <Col md={6}>
                                    <h3 className="headertext text1">Order Deatils:</h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={1}></Col>
                                <Col md={4}>
                                    <h5>Product Name</h5>
                                </Col>
                                <Col md={1}>:</Col>
                                <Col md={6}>
                                    {orderData?.product_order_details?.product_data.map((product, i) => {
                                        return (
                                            <h5 key={i}>{product?.product_name}</h5>
                                        )
                                    })}
                                </Col>

                            </Row>
                            <Row>
                                <Col md={1}></Col>
                                <Col md={4}>
                                    <h5>Delivery Charges</h5>
                                </Col>
                                <Col md={1}>:</Col>
                                <Col md={6}>
                                    <h5>{orderData?.product_order_details?.delivery_charges}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={1}></Col>
                                <Col md={4}>
                                    <h5>Convenience Charges</h5>
                                </Col>
                                <Col md={1}>:</Col>
                                <Col md={6}>
                                    <h5>{orderData?.product_order_details?.convenience_charges}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={1}></Col>
                                <Col md={4}>
                                    <h5>Total Cart Amount Charges</h5>
                                </Col>
                                <Col md={1}>:</Col>
                                <Col md={6}>
                                    <h5>{orderData?.finalprice}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={1}></Col>
                                <Col md={4}>
                                    <h5>Payment Method</h5>
                                </Col>
                                <Col md={1}>:</Col>
                                <Col md={6}>
                                    <h5>{orderData?.product_order_payment_method}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={1}></Col>
                                <Col md={4}>
                                    <h5>Payment Status</h5>
                                </Col>
                                <Col md={1}>:</Col>
                                <Col md={6}>
                                    <h5>{orderData?.product_order_payment_status}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={1}></Col>
                                <Col md={4}>
                                    <h5>Delivery Status</h5>
                                </Col>
                                <Col md={1}>:</Col>
                                <Col md={6}>
                                    <h5>{orderData?.product_order_status}</h5>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={1}></Col>
                                <Col md={4}>
                                    <h5>Terms and Conditions</h5>
                                </Col>
                                <Col md={1}>:</Col>
                                <Col md={6}>
                                    {orderData?.Order_terms_and_conditions?.map((term, i) => {
                                        return (
                                            <h5>{term}</h5>
                                        )
                                    })}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={1}></Col>
                                <Col md={4}>
                                    <h5>description</h5>
                                </Col>
                                <Col md={1}>:</Col>
                                <Col md={6}>

                                    <h5>{orderData?.Order_description}</h5>
                                </Col>
                            </Row>

                        </Card>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderData