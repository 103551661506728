import React, { useEffect, useState } from "react";
import SideBar from "../../../dashboard/SideBar";
import FirstNavbar from "../../../dashboard/FirstNavbar";
import {
    Card,
    Row,
    Col,
    Figure,
    Table,
    Button,
    Modal,
    Form,
} from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import baseURL from "../../authServices/Url";
import { useLocation } from "react-router-dom";


function BankDetails() {
    const vendorsDataaa = JSON.parse(localStorage.getItem('vendorDetails'));

    const [bankData, setBankData] = useState(vendorsDataaa)
    let token = localStorage.getItem('accessToken');

    const [verified, setVerified] = useState(false)
    const [active, setactive] = useState(false)

    const [bank, setbank] = useState({
        name: '',
        accountNumber: '',
        ifsc: '',
        branchName: '',
        verified: '',
        active: true,
    })
    const [bankErrors, setbankErrors] = React.useState({
        name: '',
        accountNumber: '',
        ifsc: '',
        branchName: '',
        verified: '',
        active: '',

    })

    const [showModal, setShowModal] = useState(false);
  const openModal = (id) => {
      console.log('@@@@@@@@@@@@@@@@@')
      setShowModal(true);
  };

  const closeModal = () => {
      setShowModal(false);
  }
    const [bankModal, setbankModal] = useState(false)

    const handleCloseModal = () => setbankModal(false)
    const handleShowModal = () => {
        // console.log('bank', bank);
        setbankModal(true)
        setbank({
            name: bankData?.bank_name,
            accountNumber: bankData?.bank_account_number,
            ifsc: bankData?.bank_ifsc_code,
            branchName: bankData?.bank_branch_name,
            verified: bankData?.bank_is_verified,
            active: bankData?.is_active,
        })
        setVerified(bankData?.bank_is_verified)
        setactive(bankData?.is_active)
    }

    const bankAvailable = async () => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        const getbank = await axios.get(`${baseURL}/admin/goldVendorBankAccount?gold_vendor_id=${vendorsDataaa?.id}`, requestOptions)
        console.log('getBank@@@@@@@@@@@@@@@@@@@', getbank)
        if (getbank?.status === 200) {
            setBankData(getbank?.data?.data[0])
        } else {
            console.log('error')
        }
    }
    useEffect(() => {
        bankAvailable()
    }, [])

    const handleMetalChange = (e) => {
        setactive(e.target.value)
      }

    const handleChange = (e) => {
        setbank({
            ...bank,
            [e?.target?.name]: e?.target?.value,
            [e.target.accountNumber]: e.target.value,
            [e.target.ifsc]: e.target.value,
            [e.target.branchName]: e.target.value,
            [e.target.verified]: e.target.value,
            [e.target.active]: e.target.value.split('\n'),
        });

        setbankErrors({
            ...bankErrors,
            [e.target.name]: null,
            [e.target.accountNumber]: null,
            [e.target.ifsc]: null,
            [e.target.branchName]: null,
            [e.target.verified]: null,
            [e.target.active]: null,
        });
    };

    const handleValidation = () => {
        const { name, accountNumber, ifsc, branchName, verified, active } = bank
        const newErrors = {}

        if (!name) {
            newErrors.name = 'please enter bank name'
        }
        if (!accountNumber) {
            newErrors.accountNumber = 'please upload account number'
        }
        if (!ifsc) {
            newErrors.ifsc = 'please enter ifsc code'
        }
        if (!verified) {
            newErrors.verified = 'please select verified or not'
        }
        if (!branchName) {
            newErrors.branchName = 'please enter branch name'
        }
        if (!active) {
            newErrors.active = 'please select active or not'
        }
        return newErrors
    }

    const handleSubmitEdit = async () => {
        const handleValidationObject = handleValidation()
        if (Object.keys(handleValidationObject).length > 0) {
            setbankErrors(handleValidationObject)
        } else {

            const requestOptions = {
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };
            const userCredentials = JSON.stringify({
                "bank_name": bank?.name,
                "bank_account_id": bankData?.id,
                "bank_branch_name": bank?.branchName,
                "bank_ifsc_code": bank?.ifsc,
                "bank_account_number": bank?.accountNumber,
                "bank_is_verified": bank?.verified,
                "is_active": bank?.active,
                // "gold_vendor_id": bankData?.gold_vendor_id,

            })
            const editbank = await axios.patch(`${baseURL}/admin/goldVendorBankAccount`, userCredentials, requestOptions)
            console.log('editbank editbank', editbank);
            if (editbank?.status === 200) {
                toast.success(`bank edited succesfully `);
                bankAvailable()
                setbank({
                    ...bank,
                    name: '',
                    accountNumber: '',
                    ifsc: '',
                    branchName: '',
                    verified: '',
                    active: '',

                })
                handleCloseModal()

            } else {
                console.log(editbank, "error after submit")
                bankAvailable()
                toast.show({
                    type: 'error',
                    text1: `${editbank.message}`,
                })
                handleCloseModal()
            }


        }
    }

    const permanentDeletemetal = async () => {
        const requestOptions = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        const permanentmetalDel = await axios.delete(`${baseURL}/admin/goldVendorBankAccount?bank_account_id=${bankData?.id}`, requestOptions)
        console.log('permanentmetalDel', permanentmetalDel)
        if (permanentmetalDel?.status === 200) {
            bankAvailable()
            closeModal()
            toast.success(`bank deleted succesfully `);

        } else {
            console.log('error ==>', permanentmetalDel)
        }
    }

    return (
        <div>
            <div class="sidebar">
                <SideBar type='Details' />
            </div>
            <div class="content">
                <div className="container">
                    <FirstNavbar />
            <div>
                <Card className="p-2">
                    <Row>
                        <Col md={6}>
                            <h3 className="headertext text1">Vendor Bank Deatils:</h3>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={1}></Col>
                        <Col md={3}>
                            <h5>Name</h5>
                        </Col>
                        <Col md={1}>:</Col>
                        <Col md={3}>
                            <h5>{bankData?.bank_name}</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={1}></Col>
                        <Col md={3}>
                            <h5>Account Number</h5>
                        </Col>
                        <Col md={1}>:</Col>
                        <Col md={3}>
                            <h5>{bankData?.bank_account_number}</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={1}></Col>
                        <Col md={3}>
                            <h5>IFSC Code</h5>
                        </Col>
                        <Col md={1}>:</Col>
                        <Col md={3}>
                            <h5>{bankData?.bank_ifsc_code}</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={1}></Col>
                        <Col md={3}><h5>Verified</h5></Col>
                        <Col md={1}>:</Col>
                        <Col md={3}><h5>{ bankData ? String(bankData?.bank_is_verified): ''}</h5></Col>
                    </Row>
                    <Row>
                        <Col md={1}></Col>
                        <Col md={3}><h5>Is Active</h5></Col>
                        <Col md={1}>:</Col>
                        <Col md={3}><h5>{bankData ? String(bankData?.is_active) : ''}</h5></Col>
                    </Row>
                    <Row>
                        <Col md={1}></Col>
                        <Col md={3}><h5>Branch Name</h5></Col>
                        <Col md={1}>:</Col>
                        <Col md={3}><h5>{bankData?.bank_branch_name}</h5></Col>
                    </Row>


                    <hr />
                    <Row>
                        <Col md={3}>
                            <Button variant="warning"
                                onClick={handleShowModal}
                            >
                                Edit Bank Details
                            </Button>
                        </Col>
                        <Col md={3}>
                            <Button variant="warning"
                                onClick={openModal}
                            >
                                Delete Bank Details
                            </Button>
                        </Col>

                    </Row>

                </Card>
            </div>
            </div>
            </div>
            <Modal
                show={bankModal}
                onHide={handleCloseModal}
                backdrop="static"
                keyboard={false}
                centered
                size="lg"
            >
                <h4 className='headertext text-center'>Edit Product banks Details:</h4>
                <Modal.Body>

                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>bank Name</Form.Label>
                                <Form.Control
                                    maxLength={50}
                                    type="text"
                                    placeholder="Enter bank Name"
                                    size="sm"
                                    name='name'
                                    onChange={handleChange}
                                    autoComplete='off'
                                    className='mb-3'
                                    // pattern="/^[a-zA-Z]*$/"
                                    required
                                    value={bank?.name}
                                />
                                <span className="text-danger">{bankErrors?.name}</span>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Account Number</Form.Label>
                                <Form.Control
                                    maxLength={50}
                                    type="text"
                                    placeholder="Enter bank account number"
                                    size="sm"
                                    name='accountNumber'
                                    onChange={handleChange}
                                    autoComplete='off'
                                    className='mb-3'
                                    value={bank?.accountNumber}
                                />
                                <span className="text-danger">{bankErrors?.accountNumber}</span>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>IFSC</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="ifsc"
                                    placeholder="Enter bank account number"
                                    size="sm"
                                    onChange={handleChange}
                                    autoComplete='off'
                                    className='mb-3'
                                    value={bank?.ifsc}
                                />
                                <span className="text-danger">{bankErrors?.ifsc}</span>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Branch Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="branchName"
                                    placeholder="Enter bank branch name"
                                    size="sm"
                                    onChange={handleChange}
                                    autoComplete='off'
                                    className='mb-3'
                                    value={bank?.branchName}
                                />
                                <span className="text-danger">{bankErrors?.branchName}</span>
                            </Form.Group>


                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Verified</Form.Label>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    label="Product Availability"
                                    checked={verified}
                                    onChange={(e) => setVerified(e?.target?.checked)}
                                    value={verified}
                                />
                                <span className="text-danger">{bankErrors?.verified}</span>
                            </Form.Group>
                        </Col>
                        <Col>
                        <Form.Group className="mb-3">
                                <Form.Label>Active</Form.Label>
                            <Form.Select
                                aria-label="select Metal"
                                size={"sm"}
                                className="selectsizesmall"
                                name="handleGenderChange"
                                onChange={(e) => handleMetalChange(e)}
                                value={active}
                            >

                                <option >Select </option>
                                <option name='true' value='true'>Active</option>
                                <option name='false' value='false'>In Active</option>

                            </Form.Select>
                            <span className="text-danger">{bankErrors?.verified}</span>

                            </Form.Group>
                            
                        </Col>
                    </Row>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseModal} variant="secondary">
                        Cancel
                    </Button>
                    <Button variant="warning" onClick={() => handleSubmitEdit()}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
              show={showModal}
              onHide={closeModal}>
              {/* <Modal.Header closeButton>
              </Modal.Header> */}
              <Modal.Body closeButton>
                  <h3>Are you sure, you want to delete this metal ...?</h3>
              </Modal.Body>
              <Modal.Footer>
                  <Button variant="secondary" onClick={closeModal}>
                      Cancel
                  </Button>
                  {/* <Button variant="warning" onClick={inActivatemetal}>
                      inActivate
                  </Button> */}
                  <Button variant="danger" onClick={permanentDeletemetal}>
                      Delete
                  </Button>
              </Modal.Footer>
          </Modal>
        </div>
    )
}

export default BankDetails