import React, { useState, useEffect } from 'react';
import { Button, Modal, Card, Row, Col, Form } from 'react-bootstrap';
import { AgGridReact } from "ag-grid-react";
import axios from 'axios';

import baseURL from '../adminPanel/authServices/Url';

function FaqList() {
    let token = localStorage.getItem('accessToken');
     
    const [rowData, setRowData] = useState([]);
    const rowHeight = 100;
    const DefaultColumnSetting = {
        sortable: true,
        flex: 1,
        resizable: true,
        minWidth: 120,
    };

    const [colDefs, setColDefs] = useState([
        {
            headerName: "#",
            valueGetter: "node.rowIndex + 1",
            lockPosition: true,
        },
        {
            headerName: "Question",
            field: "question",
            filter: true,
        },
        {
            headerName: "Answer",
            filter: true,
            field: "answer",
        }
    ]);

    const FaqAvailable = async () => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        try {
            const getFaq = await axios.get(`${baseURL}/admin/faqs`, requestOptions);
            console.log('getcoupon@@@@@@@@@@@@@@@@@@@', getFaq);
            if (getFaq?.status === 200) {
                setRowData(getFaq.data.data);
            } else {
                console.log('error');
            }
        } catch (error) {
            console.log('error', error);
        }
    };
   
    useEffect(() => {
        FaqAvailable();
    }, []);

    return (
        <div>
            <div className="content">
                <div className="container">
                    <Card className='p-2'>
                        <Row>
                            <Col>
                                <h3 className='headertext'>Bulk Order:</h3>
                            </Col>
                        </Row>
                        <div
                            className="ag-theme-alpine"
                            style={{ height: "70vh", width: "100%" }}
                        >
                            <AgGridReact
                                rowHeight={rowHeight}
                                columnDefs={colDefs}
                                defaultColDef={DefaultColumnSetting}
                                pagination={true}
                                paginationPageSize={10}
                                rowData={rowData}
                            />
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default FaqList;
