import React, { useEffect, useState, useContext } from 'react'
import { BsSpeedometer, BsFillPeopleFill } from 'react-icons/bs'
import { BiStore } from 'react-icons/bi'
import { FaTags } from "react-icons/fa6";
import { Link } from "react-router-dom";
import './index.css'
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useLocation } from "react-router-dom";
import logoAunest from '../../assets/images/LogoAunest.png'
import { UserTypeContext } from '../../App';

function SideBar({ Navigation, type = 'Dashboard' }) {
  const location = useLocation();
  const { userType, setUserType } = useContext(UserTypeContext)
  console.log('props : ', type)

  const [liveRate, setLiveRate] = useState("");
  console.log('userType', userType)

  const Customer = [{
    path: '/clipboard',
    name: 'Customer List',
    icon: <BsSpeedometer className="icons" />
  },
  {
    path: '/blockedCustomer',
    name: 'Blocked Customer List',
    icon: <BsFillPeopleFill className="icons" />

  },
  {
    path: '/approval',
    name: 'Banner Approval',
    icon: <BsFillPeopleFill className="icons" />

  },
  {
    path: '/productapproval',
    name: 'Product Approval',
    icon: <BsFillPeopleFill className="icons" />

  },
  ]
  const Vendor = [
    {
      path: '/clipboard',
      name: 'Vendor List',
      icon: <BsSpeedometer className="icons" />
    },
    {
      path: '/BlockedVendor',
      name: 'Blocked Vendor List',
      icon: <BsFillPeopleFill className="icons" />

    },
    {
      path: '/AddVendor',
      name: 'Add Vendor',
      icon: <BiStore className="icons" />

    },
  ]
  const Coupon = [
    {
      path: '/clipboard',
      name: 'Coupon List',
      icon: <BsSpeedometer className="icons" />
    },
    {
      path: '/addcoupon',
      name: 'Add Coupon',
      icon: <BsFillPeopleFill className="icons" />

    },
  ]

  const BrandEndorsement = [
    { path: '/clipboard', name: 'Brand Endorsement List', icon: <BsSpeedometer className="icons" /> },
    { path: '/addBrand', name: 'Add Brand', icon: <BsFillPeopleFill className="icons" /> },
  ];

  const Request = [
    {
      path: '/clipboard',
      name: 'Bulk Order List',
      icon: <BsSpeedometer className="icons" />
    },
  ]
  const Faq = [
    {
      path: '/clipboard',
      name: 'List FAQ',
      icon: <BsSpeedometer className="icons" />
    },
    {
      path: '/addFaq',
      name: 'Add FAQ',
      icon: <BsSpeedometer className="icons" />
    }
  ]
  const CustomerScreens = [{
    path: '/customerData',
    name: 'Profile',
    icon: <BsSpeedometer className="icons" />
  },
  {
    path: '/customerWishlist',
    name: 'Wishlist Items',
    icon: <BsFillPeopleFill className="icons" />

  },
  {
    path: '/customerCart',
    name: 'Cart Items',
    icon: <BiStore className="icons" />

  },
  {
    path: '/customerOrders',
    name: 'Orders',
    icon: <FaTags className="icons" />

  },
  ]
  const VendorScreens = [
    {
      path: '/vendorprofile',
      name: 'Vendor Profile',
      icon: <BsSpeedometer className="icons" />
    },
    {
      path: '/subbrand',
      name: 'Brand Endorsement',
      icon: <BsSpeedometer className="icons" />
    },
    {
      path: '/category',
      name: 'Product Category',
      icon: <BsSpeedometer className="icons" />
    },
    {
      path: '/store',
      name: 'Store',
      icon: <BsFillPeopleFill className="icons" />

    },
    {
      path: '/metal',
      name: 'Metal',
      icon: <BiStore className="icons" />

    },
    {
      path: '/gemstone',
      name: 'Gemstone',
      icon: <FaTags className="icons" />

    },
    {
      path: '/offer',
      name: 'Offer',
      icon: <BsFillPeopleFill className="icons" />

    },
    {
      path: '/jewellery',
      name: 'Jewels',
      icon: <BiStore className="icons" />

    },
    {
      path: '/bankdetails',
      name: 'Bank Details',
      icon: <FaTags className="icons" />

    },
    {
      path: '/order',
      name: 'Order',
      icon: <FaTags className="icons" />

    },
  ]
  const CouponScreens = [
    {
      path: '/couponActive',
      name: 'Active',
      icon: <BsSpeedometer className="icons" />
    },
    {
      path: '/couponUnactive',
      name: 'Unactive',
      icon: <BsFillPeopleFill className="icons" />

    },
  ]

  return (
    <div>
      <div className="d-inline-flex px-2 pt-2 gap-2">
        <img src={logoAunest} width="150px" height="60px" alt="logo" style={{ backgroundColor: '#00183f', padding: 10, margin: 10 }} />
      </div>
      <div>
        {type === 'Dashboard' ? (
          <>
            {userType === 'Customer' ? (
              <>
                {Customer.map((nav, i) => (
                  <p key={i}>
                    <Link to={nav?.path} className="p-1">
                      {nav?.icon} {nav?.name}
                    </Link>
                  </p>
                ))}
              </>
            ) : userType === 'Vendor' ? (
              <>
                {Vendor.map((nav, i) => (
                  <p key={i}>
                    <Link to={nav?.path} className="p-1">
                      {nav?.icon} {nav?.name}
                    </Link>
                  </p>
                ))}
              </>
            ) : userType === 'Request' ? (
              <>
                {Request.map((nav, i) => (
                  <p key={i}>
                    <Link to={nav?.path} className="p-1">
                      {nav?.icon} {nav?.name}
                    </Link>
                  </p>
                ))}
              </>
            ) : userType === 'Faq' ? (
              <>
                {Faq.map((nav, i) => (
                  <p key={i}>
                    <Link to={nav?.path} className="p-1">
                      {nav?.icon} {nav?.name}
                    </Link>
                  </p>
                ))}
              </>
            ) : userType === 'BrandEndorsement' ? (
              <>
                {BrandEndorsement.map((nav, i) => (
                  <p key={i}>
                    <Link to={nav?.path} className="p-1">
                      {nav?.icon} {nav?.name}
                    </Link>
                  </p>
                ))}
              </>
            ) : (
              <>
                {Coupon.map((nav, i) => (
                  <p key={i}>
                    <Link to={nav?.path} className="p-1">
                      {nav?.icon} {nav?.name}
                    </Link>
                  </p>
                ))}
              </>
            )}
          </>
        ) : (
          <>
            {userType === 'Customer' ? (
              <>
                {CustomerScreens.map((nav, i) => (
                  <p key={i}>
                    <Link to={nav?.path} className="p-1">
                      {nav?.icon} {nav?.name}
                    </Link>
                  </p>
                ))}
              </>
            ) : userType === 'Vendor' ? (
              <>
                {VendorScreens.map((nav, i) => (
                  <p key={i}>
                    <Link to={nav?.path} className="p-1">
                      {nav?.icon} {nav?.name}
                    </Link>
                  </p>
                ))}
              </>
            ) : userType === 'Request' ? (
              <>
                {Request.map((nav, i) => (
                  <p key={i}>
                    <Link to={nav?.path} className="p-1">
                      {nav?.icon} {nav?.name}
                    </Link>
                  </p>
                ))}
              </>
            ) : userType === 'Faq' ? (
              <>
                {Faq.map((nav, i) => (
                  <p key={i}>
                    <Link to={nav?.path} className="p-1">
                      {nav?.icon} {nav?.name}
                    </Link>
                  </p>
                ))}
              </>
            ) : userType === 'BrandEndorsement' ? (
              <>
                {BrandEndorsement.map((nav, i) => (
                  <p key={i}>
                    <Link to={nav?.path} className="p-1">
                      {nav?.icon} {nav?.name}
                    </Link>
                  </p>
                ))}
              </>
            ) : (
              <>
                {CouponScreens.map((nav, i) => (
                  <p key={i}>
                    <Link to={nav?.path} className="p-1">
                      {nav?.icon} {nav?.name}
                    </Link>
                  </p>
                ))}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default SideBar;
