import React, { useState, useEffect } from 'react'
import { useLocation } from "react-router-dom";
import {
    Card,
    Row,
    Col,
    Figure,
    Table,
    Button,
    Modal,
    Form,
} from "react-bootstrap";
import SideBar from "../../../dashboard/SideBar";
import FirstNavbar from "../../../dashboard/FirstNavbar";
import axios from 'axios';
import baseURL from '../../authServices/Url';
import { toast } from "react-toastify";
import Multiselect from 'multiselect-react-dropdown';


function ProductData() {
    const { state } = useLocation();
    const [productData1, setproductData1] = useState(state)
    console.log('productData1', productData1, state)
    let token = localStorage.getItem('accessToken');

    const [video, setVideo] = useState("");

    const [metal, setMetal] = useState({})
    const [availability, setAvailability] = useState(false)
    const [isOpen, setIsOpen] = useState(false);
    const [deleteId, setDeleteId] = useState('')
    const [offerData, setOfferData] = useState([])
    const [physicalGoldForm, setPhysicalGoldForm] = useState({
        productName: '' ,
        productCode: '',
        productHeight: '',
        stoneWeight: '',
        productDescription: '',
        grossWeight: '',
        netWeight: '',
        productImage: '',
        image360: '',
        productWidth: '',
        productDimensionUnit: '',
        wasteagePercentage: '',
        gender: '',
        productDiscount: '',
        // productAvailability:'',
        productMakingCharges: '',
        productStock: '',
        productGemstone: '',
        productOffers: [],
        productCertifications: [],
        productCategory: '',
        productMetal: '',
    })

    const [physicalGoldFormErrors, setPhysicalGoldFormErrors] = React.useState({
        productName: '',
        productCode: '',
        productHeight: '',
        stoneWeight: '',
        productDescription: '',
        grossWeight: '',
        netWeight: '',
        productImage: '',
        image360: '',
        productWidth: '',
        productDimensionUnit: '',
        wasteagePercentage: '',
        gender: '',
        productDiscount: '',
        productAvailability: '',
        productMakingCharges: '',
        productStock: '',
        productGemstone: '',
        productOffers: '',
        productCertifications: '',
        productCategory: '',
        productMetal: ''
    })
    const [options, setOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [weights, setWeights] = useState({});
    const [weightsArray, setWeightsArray] = useState([]);
    const [showJewel, setShowJewel] = useState(false)
    const [viewJewel, setViewJewel] = useState({})
    const [productcertificate, setProductcertificate] = useState({})
    const [productCat, setProductCat] = useState({})
    const [productCategory, setProductCategory] = useState(productData1?.product_category_id)
    const vendorToken1 = localStorage.getItem('accessVendorToken');
    const vendorIdGold = localStorage.getItem('goldVendorId');
    // console.log('vendorToken1 vendorToken1', vendorToken1);
    // console.log('vendorIdGold', vendorIdGold);
    const onSelect = (selectedList, selectedItem) => {
        setSelectedOptions(selectedList);
    };

    const onRemove = (selectedList, removedItem) => {
        setSelectedOptions(selectedList);
        // Remove the weight entry when an item is removed
        const updatedWeights = { ...weights };
        delete updatedWeights[removedItem?.gemstone_id];
        setWeights(updatedWeights);
    };

    const handleAdd = (e, gemstoneId) => {
        if (weightsArray?.length && !weightsArray?.some((obj) => obj?.gemstone_id === gemstoneId)) {
            console.log('4544545454554541');
            const newWeightObj = {
                "gemstone_id": gemstoneId,
                "gemstone_weight": parseInt(e?.target?.value),
            };
            setWeightsArray([...weightsArray, newWeightObj]);

        } else {
            console.log('454454545455454');
            const newArray = weightsArray?.length > 0 ? weightsArray?.filter((obj) => obj?.gemstone_id !== gemstoneId) : [];
            const newWeightObj = {
                "gemstone_id": gemstoneId,
                "gemstone_weight": parseInt(e?.target?.value),
            };
            setWeightsArray([...newArray, newWeightObj]);
        }
    };

    // certification dropdown
    const [selectedOptionsCert, setSelectedOptionsCert] = useState([] || productData1?.product_certifications);
    const [selectedOptionsCert1, setSelectedOptionsCert1] = useState([]);

    const onSelectCertificate = (selectedList, selectedItem) => {
        const selectedCertIds = selectedList?.map((item) => item?.id);
        setSelectedOptionsCert(selectedCertIds);
        setSelectedOptionsCert1(selectedList)
    };

    const onRemoveCertificate = (selectedList, removedItem) => {
        const updatedSelectedCertIds = selectedList?.map((item) => item?.id);
        setSelectedOptionsCert(updatedSelectedCertIds);
        setSelectedOptionsCert1(selectedList)
    };

    // Offer dropdown
    const [selectedOffers, setSelectedOffers] = useState([] || productData1?.product_offers);
    const [selectedOffers1, setSelectedOffers1] = useState([]);

    const onSelectOffer = (selectedList, selectedItem) => {
        const selectedCertIds = selectedList?.map((item) => item?.id);
        setSelectedOffers(selectedCertIds);
        setSelectedOffers1(selectedList)
    };

    const onRemoveOffer = (selectedList, removedItem) => {
        const updatedSelectedCertIds = selectedList?.map((item) => item?.id);
        setSelectedOffers(updatedSelectedCertIds);
        setSelectedOffers1(selectedList)
    };

    const handleChange = (e) => {
        setPhysicalGoldForm({
            ...physicalGoldForm,
            [e?.target?.name]: e?.target?.value,
            [e?.target?.productName]: e?.target?.value,
            [e?.target?.productCode]: e?.target?.value,
            [e?.target?.productHeight]: e?.target?.value,
            [e?.target?.stoneWeight]: e?.target?.value,
            [e?.target?.productDescription]: e?.target?.value,
            [e?.target?.grossWeight]: e?.target?.value,
            [e?.target?.netWeight]: e?.target?.value,
            [e?.target?.productImage]: e?.target?.value,
            [e?.target?.image360]: e?.target?.value,
            [e?.target?.productWidth]: e?.target?.value,
            [e?.target?.productDimensionUnit]: e?.target?.value,
            [e?.target?.wasteagePercentage]: e?.target?.value,
            [e?.target?.gender]: e?.target?.value,
            [e?.target?.productDiscount]: e?.target?.value,
            [e?.target?.productAvailability]: e?.target?.value,
            [e?.target?.productMakingCharges]: e?.target?.value,
            [e?.target?.productStock]: e?.target?.value,
            [e?.target?.productGemstone]: e?.target?.value,
            [e?.target?.productOffers]: e?.target?.value,
            [e?.target?.productCertifications]: e?.target?.value,


        });

        setPhysicalGoldFormErrors({
            ...physicalGoldFormErrors,
            [e?.target?.productName]: null,
            [e?.target?.productHeight]: null,
            [e?.target?.productCode]: null,
            [e?.target?.stoneWeight]: null,
            [e?.target?.productDescription]: null,
            [e?.target?.grossWeight]: null,
            [e?.target?.netWeight]: null,
            [e?.target?.productImage]: null,
            [e?.target?.image360]: null,
            [e?.target?.productWidth]: null,
            [e?.target?.productDimensionUnit]: null,
            [e?.target?.wasteagePercentage]: null,
            [e?.target?.gender]: null,
            [e?.target?.productDiscount]: null,
            [e?.target?.productAvailability]: null,
            [e?.target?.productMakingCharges]: null,
            [e?.target?.productStock]: null,
            [e?.target?.productGemstone]: null,
            [e?.target?.productOffers]: null,
            [e?.target?.productCertifications]: null,
        });
    };

    const handleMetalChange = (e) => {
        setPhysicalGoldForm({ ...physicalGoldForm, productMetal: e?.target?.value })
    }

    const handleCategoryChange = (e) => {
        setPhysicalGoldForm({ ...physicalGoldForm, productCategory: e.target.value })
    }
    const handleGenderChange = (e) => {
        setPhysicalGoldForm({ ...physicalGoldForm, gender: e.target.value })
    }

    const handleValidation = () => {
        const { productName, productCode, productHeight, stoneWeight, productDescription, grossWeight, netWeight, productImage, image360,
            productWidth, productDimensionUnit, wasteagePercentage, gender, productDiscount, productAvailability, productMakingCharges, productStock,
            productGemstone, productMetal, productOffers, productCertifications, productCategory } = physicalGoldForm

        const newErrors = {}

        if (!productName) {
            newErrors.productName = 'please enter product Name'
        }
        if (!productMetal) {
            newErrors.productMetal = 'please select Metal'
        }
        if (!productCode) {
            newErrors.productCode = 'please enter product Code'
        }
        if (availability === false) {
            newErrors.availability = 'please select availability'
        }
        if (!productHeight) {
            newErrors.productHeight = 'please enter product Height'
        }
        if (!stoneWeight) {
            newErrors.stoneWeight = 'please enter Stone Weight'
        }
        if (!productDescription) {
            newErrors.productDescription = 'please enter Product Description'
        }
        if (!grossWeight) {
            newErrors.grossWeight = 'please enter Gross Weight'
        }
        if (!netWeight) {
            newErrors.netWeight = 'please enter net Weight of product'
        }
        if (!img) {
            newErrors.productImage = 'please upload Product Image'
        }
        if (!video) {
            newErrors.image360 = 'please upload 360 degree view of jewel'
        }
        if (!productWidth) {
            newErrors.productWidth = 'please enter Product Width'
        }
        if (!productDimensionUnit) {
            newErrors.productDimensionUnit = 'please enter Product Dimensions'
        }
        if (!wasteagePercentage) {
            newErrors.wasteagePercentage = 'please enter wastage percentage'
        }
        if (!gender) {
            newErrors.gender = 'please enter gender'
        }
        if (!productDiscount) {
            newErrors.productDiscount = 'please enter Product Discount'
        }
        if (!productAvailability) {
            newErrors.productAvailability = 'please enter availability'
        }
        if (!productMakingCharges) {
            newErrors.productMakingCharges = 'please enter Product Making Charges'
        }
        if (!productStock) {
            newErrors.productStock = 'please enter Product Stock'
        }
        if (!weightsArray) {
            newErrors.productGemstone = 'please select Product Gemstone'
        }
        if (!productOffers) {
            newErrors.productOffers = 'please upload Produc tOffers'
        }
        if (!productcertificate) {
            newErrors.productCertifications = 'please select Product Certifications'
        }
        if (!productCategory) {
            newErrors.productCategory = 'please select Product Category'
        }
        return newErrors
    }

    const handleImageChange = (e) => {
        const image = e.target.files;
        console.log('@@@@@@@@@@@@@@@', image?.length);
        setImg([...image]);
    };

   
    const [img, setImg] = useState('');
    const [productModal, setproductModal] = useState(false)

    const handleCloseModal1 = () => setproductModal(false)

    const handleShowModal1 = () => {
        console.log('productData1', productData1)
        setproductModal(true)
        setAvailability(productData1?.product_availability)
        setSelectedOptionsCert1(productData1?.product_certifications)
        setSelectedOptions(productData1?.gemstones?.map(option => option?.gemstone))
        setSelectedOffers1(productData1?.product_offers)
        // setImg(productData1?.product_image)
        // setVideo(productData1?.product_360_view)
        setWeightsArray(productData1?.gemstones?.map(option => {
            return {
                gemstone_id: option?.gemstone_id,
                gemstone_weight: option?.gemstone_weight,
            }
        }))
        setPhysicalGoldForm({
            productName: productData1?.product_name,
            productCode: productData1?.product_code,
            productHeight: productData1?.product_height,
            stoneWeight: productData1?.stone_weight,
            productDescription: productData1?.product_description,
            grossWeight: productData1?.gross_weight,
            netWeight: productData1?.net_weight,
            productImage: productData1?.product_image,
            image360: productData1?.product_360_view,
            productWidth: productData1?.product_width,
            productDimensionUnit: productData1?.product_dimension_unit,
            wasteagePercentage: productData1?.wasteage_percentage,
            gender: productData1?.gender,
            productDiscount: productData1?.product_discount,
            productMakingCharges: productData1?.product_making_charges,
            productStock: productData1?.product_stock,
            productOffers: productData1?.product_offers,

            productCategory: productData1?.product_category?.id,
            productMetal: productData1?.metal?.id,
        })

    }

    const ProductAvailable = async () => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        const getProduct = await axios.get(`${baseURL}/admin/readProduct?product_id=${productData1?.id}`, requestOptions)
        console.log('getProduct@@@@@@@@@@@@@@@@@@@', getProduct)
        if (getProduct?.status === 200) {
            setproductData1(getProduct?.data?.data[0])
        } else {
            console.log('error', getProduct)
        }
    }

    const getProductCertificates = async () => {
        const productCertif = await axios.get(`${baseURL}/productCertification`)
        console.log('productCertif@@@@@', productCertif)
        if (productCertif.status === 200) {
            setProductcertificate(productCertif?.data?.data)
        } else {
            console.log('error');
        }
    }

    const gemStoneAvailable = async () => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        const getGemstone = await axios.get(`${baseURL}/admin/readGemstone?limit=10&page_no=1&gold_vendor_id=${productData1?.gold_vendor_id}`, requestOptions)
        console.log('getGemstone@@@@@@@@@@@@@@@@@@@', getGemstone)
        if (getGemstone?.status === 200) {
            setOptions(getGemstone?.data?.data)
        } else {
            console.log('error')
        }
    }

    const categoryAvailable = async () => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        const getcategory = await axios.get(`${baseURL}/admin/readProductCategory?gold_vendor_id=${productData1?.gold_vendor_id}`, requestOptions)
        // console.log('getcategory@@@@@@@@@@@@@@@@@@@', getcategory, getcategory?.data?.data[0])
        if (getcategory?.status === 200) {
            setProductCat(getcategory?.data?.data)
            console.log('getcategory@@@@@@@@@@@@@@@@@@@', getcategory)

        } else {
            console.log('error')
        }
    }

    const metalAvailable = async () => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        const getmetal = await axios.get(`${baseURL}/admin/readMetals?gold_vendor_id=${productData1?.gold_vendor_id}`, requestOptions)
        console.log('getmetal@@@@@@@@@@@@@@@@@@@', getmetal)
        if (getmetal?.status === 200) {
            setMetal(getmetal?.data?.data)
        } else {
            console.log('error')
        }
    }

    const offersAvailable = async () => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        const getoffer = await axios.get(`${baseURL}/admin/readProductOffer?gold_vendor_id=${productData1?.gold_vendor_id}`, requestOptions)
        console.log('getoffer@@@@@@@@@@@@@@@@@@@', getoffer)
        if (getoffer?.status === 200) {
            setOfferData(getoffer?.data?.data)
        } else {
            console.log('error')
        }
    }
    useEffect(() => {
        ProductAvailable()
        getProductCertificates()
        gemStoneAvailable()
        categoryAvailable()
        metalAvailable()
        offersAvailable()
    }, [])

    const handleSubmitEdit = async (props) => {
        // const handleValidationObject = handleValidation()
        // if (Object.keys(handleValidationObject).length > 3) {
        //   setPhysicalGoldFormErrors(handleValidationObject)
        //   console.log('errrrrr')
        // } else {

        const requestOptions = {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        };
        const userCredentials = {
            'product_name': physicalGoldForm?.productName,
            'stone_weight': parseInt(physicalGoldForm?.stoneWeight),
            'product_description': physicalGoldForm?.productDescription,
            'gross_weight': parseInt(physicalGoldForm?.grossWeight),
            'net_weight': parseInt(physicalGoldForm?.netWeight),
            'product_height': parseInt(physicalGoldForm?.productHeight),
            // 'product_image':physicalGoldForm?.productImage,
            // 'product_360_view':physicalGoldForm?.image360,
            'product_image': [...props?.images, ...productData1?.product_image],
            'product_360_view': props?.video ? props?.video : productData1?.product_360_view,
            'product_code': physicalGoldForm?.productCode,
            'product_width': parseInt(physicalGoldForm?.productWidth),
            'product_dimension_unit': physicalGoldForm?.productDimensionUnit,
            'wasteage_percentage': parseInt(physicalGoldForm?.wasteagePercentage),
            'gender': physicalGoldForm?.gender,
            'product_making_charges': parseInt(physicalGoldForm?.productMakingCharges),
            'product_discount': parseInt(physicalGoldForm?.productDiscount),
            'product_stock': parseInt(physicalGoldForm?.productStock),
            'product_availability': availability,
            'metal_id': physicalGoldForm?.productMetal?.id,
            'gemstones': weightsArray,
            'product_offers': selectedOffers ,
            'product_certifications': selectedOptionsCert,
            "product_category_id": physicalGoldForm?.productCategory,
            'gold_vendor_id': productData1?.gold_vendor_id,
            'product_id': productData1?.id,
        }

        const editProduct = await axios.patch(`${baseURL}/admin/updateProduct`, userCredentials, requestOptions)
        console.log('editProduct editProduct', editProduct);

        if (editProduct?.status === 200) {
            toast.success(`Product succesfully edited`);
            ProductAvailable()
            setPhysicalGoldForm({
                ...physicalGoldForm,
                productName: '',
                productCode: '',
                productHeight: '',
                stoneWeight: '',
                productDescription: '',
                grossWeight: '',
                netWeight: '',
                productImage: '',
                image360: '',
                productWidth: '',
                productDimensionUnit: '',
                wasteagePercentage: '',
                gender: '',
                productMakingCharges: '',
                productDiscount: '',
                productStock: '',
                productAvailability: '',
                productOffers: '',
                productCertifications: '',
            })
            handleCloseModal1()
            setSelectedOptions()
            setSelectedOptionsCert()
            setSelectedOptionsCert1()
            setSelectedOffers1()
            setSelectedOffers()
            setWeightsArray()
            setWeights()
            setOptions()


        } else {
            console.log('error', editProduct)
        }
        // }
    }

    const productImagesUpload = async (id) => {

        try {
            const handleValidationObject = handleValidation()
            if (Object.keys(handleValidationObject).length > 3) {
                setPhysicalGoldFormErrors(handleValidationObject)
                console.log('errrrrr')
            } else {

                var formdata = new FormData();
                for (let i = 0; i < img?.length; i++) {
                    formdata?.append("images", img[i]);
                }
                if (video !== undefined) {
                    formdata.append("images", video)
                }
                // formdata.append("image", img);
                console.log('image userCredentials#############', formdata, img, video)
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },

                };

                const imgUpload = await axios.post(`${baseURL}/admin/mediaMingle/upload/multiple`, formdata, requestOptions)
                console.log('imgUpload imgUpload imgUpload', imgUpload)
                const extensions = imgUpload?.data?.data?.url?.map(x => x?.split('.').pop())
                console.log('@############@@@@@@@@', imgUpload?.data?.data?.url?.slice(0, extensions.indexOf('mp4')), imgUpload?.data?.data?.url[extensions.indexOf('mp4')]);
                if (imgUpload?.status === 200) {
                    console.log('selectedProduct?.id', imgUpload?.data?.data?.url)
                    handleSubmitEdit({ id, images: imgUpload?.data?.data?.url?.slice(0, extensions.indexOf('mp4')), video: imgUpload?.data?.data?.url[extensions.indexOf('mp4')] })
                    // id ? handleSubmitEdit({ id, images: imgUpload?.data?.data?.url?.slice(0, extensions.indexOf('mp4')), video: imgUpload?.data?.data?.url[extensions.indexOf('mp4')] }) : handleSubmit({ images: imgUpload?.data?.data?.url?.slice(0, extensions.indexOf('mp4')), video: imgUpload?.data?.data?.url[extensions.indexOf('mp4')] })
                }
            }
        } catch (err) {
            console.log('err0r ==>', err)
        }
    }


    const productImageUpload = async () => {

        try {

            var formdata = new FormData();
            formdata.append("image", img);
            console.log('image userCredentials', img)
            const requestOptions = {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },

            };

            const imgUpload = await axios.post(`${baseURL}/admin/mediaMingle/upload`, formdata, requestOptions)
            console.log('imgUpload imgUpload imgUpload', imgUpload)
            if (imgUpload?.status === 200) {
                console.log('selectedProduct?.id', imgUpload?.data?.data?.url)
                // selectedProduct?.id ? handleSubmitEdit(imgUpload?.data?.data?.url) : handleSubmit(imgUpload?.data?.data?.url)
                handleSubmitEdit(imgUpload?.data?.data?.url)
            }
        } catch (err) {
            console.log('err0r ==>', err)
        }
    }

    console.log('options', options)
    return (
        <div>
            <div class="sidebar">
                <SideBar type='Details'/>
            </div>
            <div class="content">
                <div className="container">
                    <FirstNavbar />
                    <h3 className='headertext'>product Data</h3>
                    <div>
                        <Card className="p-2">
                            <Row>
                                <Col md={6}>
                                    <h3 className="headertext text1">product Deatils:</h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={1}></Col>
                                <Col md={2}>
                                    <video src={productData1?.product_360_view} width="150" height="150" controls>
                                    </video>

                                </Col>
                            </Row>
                            <Row>
                                <Col md={1}></Col>
                                <Col md={11}>
                                    <div style={{ display: 'flex', height: '150px', width: '300px', justifyContent: 'space-evenly', alignItems: 'center', }}>

                                        {productData1?.product_image?.map((jewel, i) => {
                                            return (<Figure key={i}>

                                                <Figure.Image
                                                    width={150}
                                                    height={150}
                                                    alt="171x180"
                                                    src={jewel}
                                                />

                                            </Figure>
                                            )
                                        })}
                                    </div>

                                </Col>
                            </Row>
                            <Row>
                                <Col md={1}></Col>
                                <Col md={3}>
                                    <h5>Name</h5>
                                </Col>
                                <Col md={1}>:</Col>
                                <Col md={3}>
                                    <h5>{productData1?.product_name}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={1}></Col>
                                <Col md={3}>
                                    <h5>Product Code</h5>
                                </Col>
                                <Col md={1}>:</Col>
                                <Col md={3}>
                                    <h5>{productData1?.product_code}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={1}></Col>
                                <Col md={3}>
                                    <h5>Gender</h5>
                                </Col>
                                <Col md={1}>:</Col>
                                <Col md={3}>
                                    <h5>{productData1?.gender}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={1}></Col>
                                <Col md={3}><h5>Product Height</h5></Col>
                                <Col md={1}>:</Col>
                                <Col md={3}><h5>{productData1?.product_height} {productData1?.product_dimension_unit}</h5></Col>
                            </Row>
                            <Row>
                                <Col md={1}></Col>
                                <Col md={3}>
                                    <h5>Product Width</h5>
                                </Col>
                                <Col md={1}>:</Col>
                                <Col md={6}>
                                    <h5>{productData1?.product_width} {productData1?.product_dimension_unit}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={1}></Col>
                                <Col md={3}>
                                    <h5>Product Availability</h5>
                                </Col>
                                <Col md={1}>:</Col>
                                <Col md={6}>
                                    <h5>{String(productData1?.product_availability)}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={1}></Col>
                                <Col md={3}>
                                    <h5>Product Stock</h5>
                                </Col>
                                <Col md={1}>:</Col>
                                <Col md={6}>
                                    <h5>{productData1?.product_stock}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={1}></Col>
                                <Col md={3}>
                                    <h5>Product Category</h5>
                                </Col>
                                <Col md={1}>:</Col>
                                <Col md={6}>
                                    <h5>{productData1?.product_category?.category_name}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={1}></Col>
                                <Col md={3}>
                                    <h5>Product metal</h5>
                                </Col>
                                <Col md={1}>:</Col>
                                <Col md={6}>
                                    <h5>{productData1?.product_stock}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={1}></Col>
                                <Col md={3}>
                                    <h5>Gemstones</h5>
                                </Col>
                                <Col md={1}>:</Col>
                                <Col md={6}>
                                    <h5>{productData1?.product_stock}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={1}></Col>
                                <Col md={3}>
                                    <h5>Stone Weight</h5>
                                </Col>
                                <Col md={1}>:</Col>
                                <Col md={6}>
                                    <h5>{productData1?.stone_weight}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={1}></Col>
                                <Col md={3}>
                                    <h5>Net Weight</h5>
                                </Col>
                                <Col md={1}>:</Col>
                                <Col md={6}>
                                    <h5>{productData1?.net_weight}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={1}></Col>
                                <Col md={3}>
                                    <h5>Gross Weight</h5>
                                </Col>
                                <Col md={1}>:</Col>
                                <Col md={6}>
                                    <h5>{productData1?.gross_weight}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={1}></Col>
                                <Col md={3}>
                                    <h5>Product Discount</h5>
                                </Col>
                                <Col md={1}>:</Col>
                                <Col md={6}>
                                    <h5>{productData1?.product_discount}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={1}></Col>
                                <Col md={3}>
                                    <h5>Product Making Charges</h5>
                                </Col>
                                <Col md={1}>:</Col>
                                <Col md={6}>
                                    <h5>{productData1?.product_making_charges}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={1}></Col>
                                <Col md={3}>
                                    <h5>Wasteage Percentage</h5>
                                </Col>
                                <Col md={1}>:</Col>
                                <Col md={6}>
                                    <h5>{productData1?.wasteage_percentage} %</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={1}></Col>
                                <Col md={3}>
                                    <h5>Product Offers</h5>
                                </Col>
                                <Col md={1}>:</Col>
                                <Col md={6}>
                                {productData1?.product_offers?.map((offer,i) =>{return(
                                 <h5 key={i}>{offer?.offer_name}</h5>
                                    )})}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={1}></Col>
                                <Col md={3}>
                                    <h5>Product Certificate</h5>
                                </Col>
                                <Col md={1}>:</Col>
                                <Col md={6}>
                                    <h5>{productData1?.product_stock}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={1}></Col>
                                <Col md={3}>
                                    <h5>description</h5>
                                </Col>
                                <Col md={1}>:</Col>
                                <Col md={3}>

                                    <h5>{productData1?.product_description}</h5>
                                </Col>
                            </Row>

                            <hr />
                            <Row>
                                <Col md={3}>
                                    <Button variant="warning" onClick={handleShowModal1}>
                                        Edit product
                                    </Button>
                                </Col>

                            </Row>

                        </Card>
                    </div>
                </div>
            </div>

            <Modal
                show={productModal}
                onHide={handleCloseModal1}
                backdrop="static"
                keyboard={false}
                centered
                size="lg"
            >
                <h4 className='headertext text-center'>Add/Edit product Details:</h4>
                <Modal.Body>

                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Product Name</Form.Label>
                                <Form.Control
                                    maxLength={50}
                                    type="text"
                                    placeholder="Enter Name"
                                    size="sm"
                                    name='productName'
                                    onChange={handleChange}
                                    autoComplete='off'
                                    className='mb-3'
                                    required
                                    value={physicalGoldForm?.productName}
                                />
                                <span className="text-danger">{physicalGoldFormErrors?.productName}</span>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>product Code</Form.Label>
                                <Form.Control
                                    maxLength={50}
                                    type="text"
                                    placeholder="Enter product Code"
                                    size="sm"
                                    name='productCode'
                                    onChange={handleChange}
                                    autoComplete='off'
                                    className='mb-3'
                                    value={physicalGoldForm?.productCode}
                                />
                                <span className="text-danger">{physicalGoldFormErrors?.productCode}</span>
                            </Form.Group>


                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Product Height</Form.Label>
                                <Form.Control
                                    maxLength={50}
                                    type="number"
                                    placeholder="Enter Product Height"
                                    size="sm"
                                    name='productHeight'
                                    onChange={handleChange}
                                    autoComplete='off'
                                    className='mb-3'
                                    value={physicalGoldForm?.productHeight}
                                />
                                <span className="text-danger">{physicalGoldFormErrors?.productHeight}</span>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Product Width</Form.Label>
                                <Form.Control
                                    maxLength={50}
                                    type="number"
                                    placeholder="Enter Product Width"
                                    size="sm"
                                    name='productWidth'
                                    onChange={handleChange}
                                    autoComplete='off'
                                    className='mb-3'
                                    value={physicalGoldForm?.productWidth}
                                />
                                <span className="text-danger">{physicalGoldFormErrors?.productWidth}</span>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Product Dimension Unit</Form.Label>
                                <Form.Control
                                    maxLength={50}
                                    type="text"
                                    placeholder="Enter Product Dimension Unit "
                                    size="sm"
                                    name='productDimensionUnit'
                                    onChange={handleChange}
                                    autoComplete='off'
                                    className='mb-3'
                                    value={physicalGoldForm?.productDimensionUnit}
                                />
                                <span className="text-danger">{physicalGoldFormErrors?.productDimensionUnit}</span>
                            </Form.Group>
                        </Col>

                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Select Gender</Form.Label>
                                <div className="leftedge d-flex justify-content-space">
                                    <Form.Select
                                        aria-label="select Gender"
                                        size={"sm"}
                                        className="selectsizesmall"
                                        name="handleGenderChange"
                                        onChange={handleGenderChange}
                                        value={physicalGoldForm?.gender}
                                    >

                                        <option >Select Gender</option>
                                        <option name='FEMALE' value='FEMALE'>Female</option>
                                        <option name='MALE' value='MALE'>Male</option>

                                    </Form.Select>
                                </div>
                                <span className="text-danger">{physicalGoldFormErrors?.gender}</span>
                            </Form.Group>

                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Select Product Offers</Form.Label>
                                <div className="leftedge d-flex justify-content-space">
                                    <Multiselect
                                        options={offerData}
                                        displayValue="offer_name"
                                        onSelect={onSelectOffer}
                                        onRemove={onRemoveOffer}
                                        selectedValues={selectedOffers1}
                                        placeholder="Select Offers"
                                        value={selectedOptionsCert}
                                    />
                                </div>
                                <span className="text-danger">{physicalGoldFormErrors?.productOffers}</span>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Select Metal</Form.Label>
                                <div className="leftedge d-flex justify-content-space">
                                    <Form.Select
                                        aria-label="select Metal"
                                        size={"sm"}
                                        className="selectsizesmall"
                                        name="metal"
                                        onChange={handleMetalChange}
                                        value={physicalGoldForm?.productMetal}
                                    >
                                        <option >Select Metal </option>
                                        {metal.length && metal?.map((metal, i) => {
                                            // console.log('metalllllllllll',metal)
                                            return (
                                                <option name='metal' value={metal?.id}>{metal?.metal_name}</option>
                                            )
                                        })}
                                    </Form.Select>
                                </div>
                                <span className="text-danger">{physicalGoldFormErrors?.productMetal}</span>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Select gemstones</Form.Label>
                                <Multiselect
                                    options={options}
                                    displayValue="gemstone_name"
                                    onSelect={onSelect}
                                    onRemove={onRemove}
                                    selectedValues={selectedOptions}
                                    placeholder="Select options"
                                // value={}
                                />
                                <span className="text-danger">{!weightsArray && 'please select gemstone'}</span>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            {selectedOptions?.map((gem, i) => {
                                return (
                                    <div key={i} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div md={2}>{gem?.gemstone_name}</div>
                                        <input
                                            md={2}
                                            type="number"
                                            placeholder="Enter weight"
                                            value={weightsArray?.find(x => x?.gemstone_id === gem?.id)?.gemstone_weight || ''}
                                            onChange={(e) => handleAdd(e, gem?.id)}
                                        />
                                        {/* <Button md={2} onClick={() => handleAdd(gem?.id)} variant="warning">add</Button> */}
                                    </div>
                                )
                            })}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Gross Weight</Form.Label>
                                <Form.Control
                                    maxLength={50}
                                    type="number"
                                    placeholder="Enter Gross Weight"
                                    size="sm"
                                    name='grossWeight'
                                    onChange={handleChange}
                                    autoComplete='off'
                                    className='mb-3'
                                    value={physicalGoldForm?.grossWeight}
                                />
                                <span className="text-danger">{physicalGoldFormErrors?.grossWeight}</span>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Stone Weight</Form.Label>
                                <Form.Control
                                    maxLength={50}
                                    type="number"
                                    placeholder="Enter Ston Weight"
                                    size="sm"
                                    name='stoneWeight'
                                    onChange={handleChange}
                                    autoComplete='off'
                                    className='mb-3'
                                    value={physicalGoldForm?.stoneWeight}
                                />
                                <span className="text-danger">{physicalGoldFormErrors?.stoneWeight}</span>
                            </Form.Group>
                        </Col>

                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Net Weight</Form.Label>
                                <Form.Control
                                    maxLength={50}
                                    type="number"
                                    placeholder="Enter Net Weight"
                                    size="sm"
                                    name='netWeight'
                                    onChange={handleChange}
                                    autoComplete='off'
                                    className='mb-3'
                                    value={physicalGoldForm?.netWeight}
                                />
                                <span className="text-danger">{physicalGoldFormErrors?.netWeight}</span>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Wastage %</Form.Label>
                                <Form.Control
                                    maxLength={50}
                                    type="number"
                                    placeholder="Enter Wastage Percentage"
                                    size="sm"
                                    name='wasteagePercentage'
                                    onChange={handleChange}
                                    autoComplete='off'
                                    className='mb-3'
                                    value={physicalGoldForm?.wasteagePercentage}
                                />
                                <span className="text-danger">{physicalGoldFormErrors?.wasteagePercentage}</span>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Select Product Category</Form.Label>
                                <div className="leftedge d-flex justify-content-space">
                                    <Form.Select
                                        aria-label="select Product Category"
                                        size={"sm"}
                                        className="selectsizesmall"
                                        name="productCategory"
                                        onChange={handleCategoryChange}
                                        value={physicalGoldForm?.productCategory}
                                    >

                                        <option >Select Product Category</option>
                                        {productCat?.length && productCat?.map((prod, i) => {
                                            return (
                                                <option key={i} name='productCategory' value={prod?.id}>{prod?.category_name}</option>
                                            )
                                        })}
                                    </Form.Select>
                                </div>
                                <span className="text-danger">{physicalGoldFormErrors?.productCategory}</span>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Select Product Certifications</Form.Label>
                                <Multiselect
                                    options={productcertificate}
                                    displayValue="product_certification_name"
                                    onSelect={onSelectCertificate}
                                    onRemove={onRemoveCertificate}
                                    selectedValues={selectedOptionsCert1}
                                    placeholder="Select certificates"
                                    value={selectedOptionsCert}
                                />
                                <span className="text-danger">{selectedOptionsCert1 && physicalGoldFormErrors?.productCertifications}</span>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Making Charges /grm</Form.Label>
                                <Form.Control
                                    maxLength={50}
                                    type="number"
                                    placeholder="Enter Product Making Charges"
                                    size="sm"
                                    name='productMakingCharges'
                                    onChange={handleChange}
                                    autoComplete='off'
                                    className='mb-3'
                                    value={physicalGoldForm?.productMakingCharges}
                                />
                                <span className="text-danger">{physicalGoldFormErrors?.productMakingCharges}</span>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Product Discount</Form.Label>
                                <Form.Control
                                    maxLength={50}
                                    type="number"
                                    placeholder="Enter Product Discount"
                                    size="sm"
                                    name='productDiscount'
                                    onChange={handleChange}
                                    autoComplete='off'
                                    className='mb-3'
                                    value={physicalGoldForm?.productDiscount}
                                />
                                <span className="text-danger">{physicalGoldFormErrors?.productDiscount}</span>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Product Stock</Form.Label>
                                <Form.Control
                                    maxLength={50}
                                    type="number"
                                    placeholder="Enter Product Stock"
                                    size="sm"
                                    name='productStock'
                                    onChange={handleChange}
                                    autoComplete='off'
                                    className='mb-3'
                                    value={physicalGoldForm?.productStock}
                                />
                                <span className="text-danger">{physicalGoldFormErrors?.productStock}</span>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label></Form.Label>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    label="Product Availability"
                                    checked={availability}
                                    onChange={(e) => setAvailability(e?.target?.checked)}
                                    value={availability}
                                />
                                <span className="text-danger">{physicalGoldFormErrors?.availability}</span>
                            </Form.Group>
                        </Col>

                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Add Product Image</Form.Label>
                                <Form.Control
                                    type="file"
                                    multiple
                                    placeholder="Product Image"
                                    className="mb-3"
                                    name="productImage"
                                    onChange={(e) => handleImageChange(e)}
                                    autoComplete="off"
                                />
                                <span className="text-danger">{physicalGoldFormErrors?.productImage}</span>
                            </Form.Group>
                        </Col>

                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Add product_360_view</Form.Label>
                                <Form.Control
                                    type="file"
                                    placeholder="Product View"
                                    className="mb-3"
                                    name="image360"
                                    onChange={(e) => setVideo(e?.target?.files[0])}
                                    autoComplete="off"
                                />
                                <span className="text-danger">{physicalGoldFormErrors?.image360}</span>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Product Description</Form.Label>
                                <Form.Control
                                    as="textarea" rows={3}
                                    placeholder="enter Product Description"
                                    className="mb-3"
                                    name="productDescription"
                                    onChange={handleChange}
                                    autoComplete="off"
                                    value={physicalGoldForm?.productDescription}
                                />
                                <span className="text-danger">{physicalGoldFormErrors?.productDescription}</span>
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseModal1} variant="secondary">
                        Cancel
                    </Button>
                    <Button variant="warning" onClick={() => productImagesUpload()}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ProductData