import React, { useContext } from 'react'
import SideBar from '../dashboard/SideBar'
import FirstNavbar from '../dashboard/FirstNavbar'
import VendorList from './vendor/VendorList'
import CustomerList from './customer/CustomerList'
import CouponsList from '../Coupons/CouponsList'
import CustomerRequestList from '../BulkOrder/BulkOrderList'
import FaqList from '../FAQ/faqList'
import { UserTypeContext } from '../../App'
import BrandEndorsement from '../BrandEndorsement/BrandEndorsement'

function Master() {
    const { userType, setUserType } = useContext(UserTypeContext)

    console.log('userType', userType)
    return (
        <div>
            <div className="sidebar">
                <SideBar type='Dashboard' />
            </div>
            <div className="content">
                <FirstNavbar />
            </div>
            <div>
                {userType === 'Customer' ? (
                    <CustomerList userType={userType} />
                ) : userType === 'Vendor' ? (
                    <VendorList userType={userType} />
                ) : userType === 'Request' ? (
                    <CustomerRequestList userType={userType} />
                ) : userType === 'Faq' ? (
                    <FaqList userType={userType} />
                ) : userType === 'BrandEndorsement' ? (
                    <BrandEndorsement userType={userType} />
                ) : (
                    <CouponsList />
                )}
            </div>
        </div>
    )
}

export default Master