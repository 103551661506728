import React, { useEffect, useState } from "react";
import SideBar from "../../dashboard/SideBar";
import FirstNavbar from "../../dashboard/FirstNavbar";
import {
  Card,
  Row,
  Col,
  Figure,
  Table,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import baseURL from "../authServices/Url";
import { useLocation } from "react-router-dom";

function VendorProfile(props) {
  const { state } = useLocation();
    const vendorsDataaa = JSON.parse(localStorage.getItem('vendorDetails'));
  const [profileModal, setProfileModal] = useState(false);
  let token = localStorage.getItem('accessToken');
  const [vendorData, setVendorData] = useState(vendorsDataaa)
  const [img, setImg] = useState([]);
  const [logoImg, setLogoImg] = useState('')

  const [userDetails, setUserDetails] = useState({
    name: "" || vendorData?.gold_vendor_name,
    email: "" || vendorData?.gold_vendor_name,
    phone: "" || vendorData?.gold_vendor_name,
    gstNumber: "" || vendorData?.gold_vendor_gst_number,
    addressStreet: '' || vendorData?.gold_vendor_address,
    addressPincode: '' || vendorData?.gold_vendor_address_pincode,
    addressCity: '' || vendorData?.gold_vendor_address_city,
    addressState: '' || vendorData?.gold_vendor_address_state,
    constitutionBusiness: '' || vendorData?.gold_vendor_constitution_of_business,

  });
  const [userDetailsError, setUserDetailsError] = useState({
    name: "",
    email: "",
    phone: "",
    gstNumber: "",
    addressStreet: '',
    addressPincode: '',
    addressCity: '',
    addressState: '',
    constitutionBusiness: '',

  });

  const [vendorProprietor, setVendorProprietor] = useState({
    proprietorName: "" || vendorData?.gold_vendor_proprietor_name,
    proprietorDescription: "" || vendorData?.gold_vendor_description,
    proprietorLogo: "" || vendorData?.gold_vendor_logo,
    proprietorBanners: '' || vendorData?.gold_vendor_banners,
  });
  const [vendorProprietorError, setVendorProprietorError] = useState({
    proprietorName: '',
    proprietorDescription: '',
    proprietorLogo: '',
    proprietorBanners: '',
  });

  const handleCloseModal = () => setProfileModal(false);
  const handleShowModal = () => setProfileModal(true);

  const goldVendorRequest = async () => {
    const requestOptions = {
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    const getVendor = await axios.get(`${baseURL}/admin/readGoldVendor?limit=10&page_no=1&gold_vendor_id=${vendorsDataaa?.id}`, requestOptions)
    if (getVendor?.status === 200) {
      setVendorData(getVendor?.data?.data[0])
    } else {
      console.log('error')
    }
  }

  useEffect(() => {
    goldVendorRequest();
  }, [])

  const handleChange = (e) => {
    setUserDetails({
      ...userDetails,
      [e.target.name]: e.target.value,
      [e.target.gstNumber]: e.target.value,
      [e.target.email]: e.target.value,
      [e.target.phone]: e.target.value,
      [e.target.addressStreet]: e.target.value,
      [e.target.addressPincode]: e.target.value,
      [e.target.addressCity]: e.target.value,
      [e.target.addressState]: e.target.value,
      [e.target.constitutionBusiness]: e.target.value,

    });

    setUserDetailsError({
      ...userDetailsError,
      [e.target.name]: null,
      [e.target.email]: null,
      [e.target.phone]: null,
      [e.target.gstNumber]: null,
      [e.target.addressStreet]: null,
      [e.target.addressPincode]: null,
      [e.target.addressCity]: null,
      [e.target.addressState]: null,
      [e.target.constitutionBusiness]: null,
    });
  };

  const handleChangeProprietor = (e) => {
    setVendorProprietor({
      ...vendorProprietor,
      [e.target.name]: e.target.value,
    });

    setVendorProprietorError({
      ...vendorProprietorError,
      [e.target.proprietorName]: null,
      [e.target.proprietorDescription]: null,
      [e.target.proprietorLogo]: null,
      [e.target.proprietorBanners]: null,
    });
  };

  const handleImageChange = (e) => {
    const image = e.target.files;
    setImg([...image]);
  };


  const venderProprietorValidation = () => {
    const newErrors = {};
    const { name, gstNumber, addressStreet, addressPincode, addressCity, addressState, constitutionBusiness, proprietorName, proprietorDescription, logoImg, img } = vendorProprietor;
    const nameRegex = /^[a-zA-Z\s]{1,}[\.]{0,1}[a-zA-Z\s]{0,}$/;
    if (name === "" || nameRegex.test(nameRegex)) {
      newErrors.name = "please enter name";
    }
    if (gstNumber === '') {
      newErrors.gstNumber = "please enter GST Number ";
    }
    if (addressStreet === '') {
      newErrors.addressStreet = "please enter Address Street";
    }
    if (addressPincode === '') {
      newErrors.addressPincode = "please enter Address Pincode";
    }
    if (addressCity === '') {
      newErrors.addressCity = "please enter Address City";
    }
    if (addressState === '') {
      newErrors.addressState = "please enter Address City";
    }
    if (constitutionBusiness === '') {
      newErrors.constitutionBusiness = "please enter Constitution Business";
    }
    if (proprietorName === '') {
      newErrors.proprietorName = "please enter name";
    }
    if (proprietorDescription === '') {
      newErrors.proprietorDescription = "please enter Description";
    }
    if (logoImg === '') {
      newErrors.proprietorLogo = "please upload proprietor Logo";
    }
    if (img?.length === 0) {
      newErrors.proprietorBanners = "please upload proprietor Banners ";
    }

    return newErrors;
  };


  const logoImageUpload = async () => {
    var formdata = new FormData();
    formdata.append("image", logoImg);
    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return await axios.post(`${baseURL}/admin/mediaMingle/upload`, formdata, requestOptions)
    
  }

  const bannerImagesUpload = async (id) => {
    var formdata = new FormData();
    for (let i = 0; i < img?.length; i++) {
      formdata?.append("images", img[i]);
    }
    formdata.append("image", img);
    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return await axios.post(`${baseURL}/admin/mediaMingle/upload/multiple`, formdata, requestOptions)
    }

  const addVendorProprietor = async () => {
    const venderValidationObject = venderProprietorValidation();
    if (Object.keys(venderValidationObject).length > 0) {
      setVendorProprietorError(venderValidationObject);
    } else {
      const [firstDataResponse, secondDataResponse] = await Promise.all([
        logoImageUpload(),
        bannerImagesUpload(),
      ]);
      if (firstDataResponse?.status === 200 && secondDataResponse?.status === 200) {
        const requestOptions = {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        };

        const userCredentials = JSON.stringify({
          "gold_vendor_proprietor_name": vendorProprietor?.proprietorName,
          "gold_vendor_description": vendorProprietor?.proprietorDescription,
          "gold_vendor_logo": firstDataResponse?.data?.data?.url,
          "gold_vendor_banners": secondDataResponse?.data?.data?.url,
          "gold_vendor_name": userDetails?.name,
          "gold_vendor_gst_number": userDetails?.gstNumber,
          "gold_vendor_address": userDetails?.addressStreet,
          "gold_vendor_address_pincode": userDetails?.addressPincode,
          "gold_vendor_address_city": userDetails?.addressCity,
          "gold_vendor_address_state": userDetails?.addressState,
          "gold_vendor_constitution_of_business": userDetails?.constitutionBusiness,
          "gold_vendor_id": vendorsDataaa?.id,
        })
        const data = await axios.patch(`${baseURL}/admin/updateGoldVendor`, userCredentials, requestOptions)


        if (data.status === 200) {
          goldVendorRequest()
          toast.success(" Profile Details Updated", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            theme: "light",
          });
          handleCloseModal();

          // getUserDetails();
        } else {
          console.log("else is working");
        }
      }
    }
  };

  return (
    <>
      <div class="sidebar">
        <SideBar type='Details' />
      </div>
      <div class="content">
        <div className="container">
          <FirstNavbar />

          <h3 className='headertext'>Vendor Data</h3>

          <div>
            <Card className="p-2">
              <Row>
                <Col md={6}>
                  <h3 className="headertext text1">Vendor Profile Deatils:</h3>
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={2}>
                  <Figure>
                    <Figure.Image
                      width={100}
                      height={100}
                      alt="171x180"
                      src={vendorData?.gold_vendor_logo}
                    />
                  </Figure>
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={3}>
                  <h5>{vendorData?.gold_vendor_proprietor_name}</h5>
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={3}>
                  <h5>{vendorData?.gold_vendor_name}</h5>
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={3}>
                  <h5>{vendorData?.gold_vendor_gst_number}</h5>
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={3}><h5>{vendorData?.gold_vendor_constitution_of_business}</h5></Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={6}>
                  <h5>{vendorData?.gold_vendor_address} {vendorData?.gold_vendor_address_city} {vendorData?.gold_vendor_address_state} {vendorData?.gold_vendor_address_pincode}</h5>
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                {vendorData?.gold_vendor_banners
                  ?.map(bannerString => JSON.parse(bannerString))
                  .filter(bannerObject => bannerObject.approve)
                  .map((bannerObject, i) => {
                    return (
                      <Col key={i} md={2}>
                        <Figure>
                          <Figure.Image
                            width={100}
                            height={100}
                            alt="Approved banner"
                            src={bannerObject.gold_vendor_banners}
                            style={{ objectFit: 'contain' }}
                          />
                        </Figure>
                      </Col>
                    )
                  })
                }
              </Row>

              <Row>
                <Col md={1}></Col>
                <Col md={3}>

                  <h5>{vendorData?.gold_vendor_description}</h5>
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={10}>
                  {vendorData?.gold_vendor_banners?.map((banner) => {
                    <div style={{ overflowX: 'scroll', justifyContent: 'space-evenly', alignItems: 'center', display: 'flex' }}>
                      <Figure>
                        <Figure.Image
                          width={100}
                          height={100}
                          alt="171x180"
                          src={banner}
                        />
                      </Figure>
                    </div>
                  })}
                </Col>
                <Col md={1}></Col>
              </Row>
              <hr />
              <Row>
                <Col md={3}>
                  <Button variant="warning" onClick={handleShowModal}>
                    Edit Profile
                  </Button>
                </Col>

              </Row>

            </Card>
          </div>
        </div>
      </div>
      <Modal
        show={profileModal}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <h4 className="headertext text-center">Edit Vendor Details:</h4>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter Your Name"
                  size="sm"
                  name="name"
                  value={userDetails?.name}
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                  pattern="/^[a-zA-Z]*$/"
                  required
                />
                <span className="text-danger">
                  {userDetailsError?.name}
                </span>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Gst Number</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter Your gst Number"
                  size="sm"
                  name="gstNumber"
                  value={userDetails?.gstNumber}
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                  required
                />
                <span className="text-danger">
                  {userDetailsError?.gstNumber}
                </span>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter house no,street, area"
                  size="sm"
                  name="addressStreet"
                  value={userDetails?.addressStreet}
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                />
                <span className="text-danger">
                  {userDetailsError?.addressStreet}
                </span>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>City</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter your city"
                  size="sm"
                  name="addressCity"
                  value={userDetails?.addressCity}
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                />
                <span className="text-danger">
                  {userDetailsError?.addressCity}
                </span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>state</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter your State"
                  size="sm"
                  name="addressState"
                  value={userDetails?.addressState}
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                />
                <span className="text-danger">
                  {userDetailsError?.addressState}
                </span>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>pincode</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter your Pincode"
                  size="sm"
                  name="addressPincode"
                  value={userDetails?.addressPincode}
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                />
                <span className="text-danger">
                  {userDetailsError?.addressPincode}
                </span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Constitution Business</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter your constitution Business"
                  size="sm"
                  name="constitutionBusiness"
                  value={userDetails?.constitutionBusiness}
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                />
                <span className="text-danger">
                  {userDetailsError?.constitutionBusiness}
                </span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Vendor Proprietor Name</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter Your Name"
                  size="sm"
                  name="proprietorName"
                  value={vendorProprietor?.proprietorName}
                  onChange={handleChangeProprietor}
                  autoComplete="off"
                  className="mb-3"
                  required
                />
                <span className="text-danger">
                  {vendorProprietorError?.proprietorName}
                </span>
              </Form.Group>
            </Col>
          </Row>
          <Row>

            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Logo</Form.Label>
                <Figure>
                  <Figure.Image
                    width={100}
                    height={100}
                    alt="171x180"
                    src={vendorData?.gold_vendor_logo}
                  />
                </Figure>
                <Form.Control
                  maxLength={50}
                  type="file"
                  placeholder="upload Logo"
                  size="sm"
                  name="proprietorLogo"
                  // value={vendorProprietor?.proprietorLogo}
                  onChange={(e) => { console.log('eeee', e.target.files[0]); setLogoImg(e.target.files[0]); console.log('setLogoImg', logoImg) }}
                  autoComplete="off"
                  className="mb-3"
                />
                <span className="text-danger">
                  {vendorProprietorError?.proprietorLogo}
                </span>
              </Form.Group>
            </Col>
            {/* <Col md={6}>
                <Button variant="warning" onClick={() => { }}>
                  Update
                </Button>
              </Col> */}
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Banner</Form.Label>
                {vendorData?.gold_vendor_banners?.map((banner, i) => {
                  return (
                    <Col key={i} md={2}>
                      <Figure>
                        <Figure.Image
                          width={100}
                          height={100}
                          alt="171x180"
                          src={banner}
                          style={{ objectFit: 'contain' }}
                        />
                      </Figure>
                    </Col>
                  )
                })}
                <Form.Control
                  maxLength={50}
                  type="file"
                  placeholder="upload Banner"
                  size="sm"
                  name="proprietorBanners"
                  onChange={(e) => handleImageChange(e)}
                  autoComplete="off"
                  className="mb-3"
                  multiple
                />
                <span className="text-danger">
                  {vendorProprietorError?.proprietorBanners}
                </span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea" rows={3}
                  placeholder="Enter Your Description"
                  size="sm"
                  name="proprietorDescription"
                  value={vendorProprietor?.proprietorDescription}
                  onChange={handleChangeProprietor}
                  autoComplete="off"
                  className="mb-3"
                  required
                />
                <span className="text-danger">
                  {vendorProprietorError?.proprietorDescription}
                </span>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseModal} variant="secondary">
            Cancel
          </Button>
          <Button variant="warning" onClick={addVendorProprietor}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

    {/* </div > */}
      </>
      );
}

export default VendorProfile;
