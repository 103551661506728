import React, { useState, useEffect } from 'react'
import { useLocation } from "react-router-dom";
import {
  Card,
  Row,
  Col, Figure
} from "react-bootstrap";
import SideBar from '../../dashboard/SideBar';
import FirstNavbar from "../../dashboard/FirstNavbar";
import axios from 'axios';

function CustomerData() {
    // const { state } = useLocation();
    // const customerData = state
    const [customerData, setcustomerData] = useState();

    const userId = localStorage.getItem("userId");
    const newUserId = JSON.parse(userId);
    const { user_credential_id } = newUserId;

    // console.log('customerData', customerData, state)
    // let token = localStorage.getItem('accessToken');
  
    const CustomerOrderAvailable = async () => {
      const requestOptions = {
          headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json',
          },
      };
      try {
          const getorders = await axios.get(`http://localhost:8001/api/v1/admin/customerById?user_id=${user_credential_id}`, requestOptions);
          if (getorders?.status === 200) {
            setcustomerData(getorders.data[0]);  // Adjusted here
          } else {
              console.log('error');
          }
      } catch (error) {
          console.error('Error fetching orders:', error);
      }
  };

  useEffect(() => {
      CustomerOrderAvailable();
  }, []);

  console.log(customerData,'bbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb')
   
    return (
      <div>
        <div class="sidebar">
          <SideBar type='Details'/>
        </div>
        <div class="content">
          <div className="container">
            <FirstNavbar />
            <h3 className='headertext'>Customer Data</h3>
            <div>
              <Card className="p-2">
                <Row>
                  <Col md={6}>
                    <h3 className="headertext text1">customer Deatils:</h3>
                  </Col>
                </Row>
                <Row>
                <Col md={1}></Col>
                <Col md={2}>
                  <Figure>
                    <Figure.Image
                      width={100}
                      height={100}
                      alt="171x180"
                      src={customerData?.user_profile_image}
                    />
                  </Figure>
                </Col>
              </Row>
                <Row>
                  <Col md={1}></Col>
                  <Col md={4}>
                    <h5>customer Name</h5>
                  </Col>
                  <Col md={1}>:</Col>
                  <Col md={6}>
                    <h5>{customerData?.user_first_name} {customerData?.user_last_name}</h5>
                  </Col>
                </Row>
                <Row>
                  <Col md={1}></Col>
                  <Col md={4}>
                    <h5>Phone Number</h5>
                  </Col>
                  <Col md={1}>:</Col>
                  <Col md={6}>
                    <h5>{customerData?.user_phone_number}</h5>
                  </Col>
                </Row>
                <Row>
                  <Col md={1}></Col>
                  <Col md={4}><h5>Email</h5></Col>
                  <Col md={1}>:</Col>
                  <Col md={6}><h5>{customerData?.user_email}</h5></Col>
                </Row>
                <Row>
                  <Col md={1}></Col>
                  <Col md={4}>
                    <h5>Gender</h5>
                  </Col>
                  <Col md={1}>:</Col>
                  <Col md={6}>
                    <h5>{customerData?.user_gender}</h5>
                  </Col>
                </Row>
                <Row>
                  <Col md={1}></Col>
                  <Col md={4}>
                    <h5>Date of Birth</h5>
                  </Col>
                  <Col md={1}>:</Col>
                  <Col md={6}>
                    <h5>{customerData?.user_dob}</h5>
                  </Col>
                </Row>
                <Row>
                  <Col md={1}></Col>
                  <Col md={4}>
                    <h5>Location</h5>
                  </Col>
                  <Col md={1}>:</Col>
                  <Col md={6}>
                    <h5>{customerData?.user_location}</h5>
                  </Col>
                </Row>
                
  
              </Card>
            </div>
          </div>
        </div>
      </div>
    )
}

export default CustomerData