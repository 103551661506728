import React, { useEffect, useState } from "react";
import SideBar from "../dashboard/SideBar";
import "../../index.css";
import FirstNavbar from "../dashboard/FirstNavbar";
import {
  Card,
  Row,
  Col,
  Figure,
  Table,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import baseURL from "../adminPanel/authServices/Url";

function AddFaq() {
  let token = localStorage.getItem("accessToken");

  const [profileModal, setProfileModal] = useState(false);

  const [formData, setformData] = useState({
    question: "",
    answer: "",
    
  });


  const handleCloseModal = () => setProfileModal(false);
  const handleShowModal = () => setProfileModal(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformData({
      ...formData,
      [name]: value,
    });
  };


  
  


  const handleSubmit = async () => {
    try {
     
      const response = await axios.post(`${baseURL}/admin/faqs`, {
        ...formData,
      });
      if (response.status === 200) {
        toast.success("FAQ created successfully!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          theme: "light",
        });
        handleCloseModal();
      }
    } catch (error) {
      console.error("Error creating vendor:", error);
      toast.error("Required All Fields Please Fill each fields.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        theme: "light",
      });
    }
  };
 

  return (
    <>
      <div class="sidebar">
        <SideBar type="Dashboard" />
      </div>
      <div class="content">
        <div className="container">
          <FirstNavbar />

          <h3 className="headertext">FAQ Details</h3>

          <div>
                  <hr />
              <Row>
                <Col md={3}>
                  <Button variant="warning" onClick={handleShowModal}>
                    Add FAQ
                  </Button>
                </Col>
              </Row>
          </div>
        </div>
      </div>
      <Modal
        show={profileModal}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <h4 className="headertext text-center">Add Vendor Details:</h4>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Question</Form.Label>
                <Form.Control
                                        as="textarea" rows={3}
                                        placeholder="Enter description"
                                        size="md"
                                        name='question'
                                        onChange={(e) => handleChange(e)}
                                        autoComplete='off'
                                        className='mb-3'
                                        value={formData.question}
                                    />
                {(formData.question=== "" && (
                  <Form.Text className="text-danger">
                    Question is required.
                  </Form.Text>
                )) ||
                  (!/[a-zA-Z]+/.test(formData.question) && (
                    <Form.Text className="text-danger">
                      Name must contain only alphabets.
                    </Form.Text>
                  ))}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Answer</Form.Label>
                <Form.Control
                                        as="textarea" rows={3}
                                        placeholder="Enter description"
                                        size="md"
                                        name='answer'
                                        onChange={(e) => handleChange(e)}
                                        autoComplete='off'
                                        className='mb-3'
                                        value={formData.answer}
                                    />
                {(formData.answer === "" && (
                  <Form.Text className="text-danger">
                    Answer is required.
                  </Form.Text>
                )) ||
                  (!/[a-zA-Z]+/.test(formData.answer    ) && (
                    <Form.Text className="text-danger">
                      Name must contain only alphabets.
                    </Form.Text>
                  ))}
              </Form.Group>
            </Col>
          </Row>         
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseModal} variant="secondary">
            Cancel
          </Button>
          <Button variant="warning" onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddFaq;
