import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./pages/auth/Login";
import "bootstrap/dist/css/bootstrap.min.css";
import "./custom.css";
import "bootstrap/dist/css/bootstrap.min.css";
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import DashboardAdmin from "./pages/adminPanel/dashboard/DashboardAdmin";
import CustomerList from "./pages/adminPanel/customer/CustomerList";
import VendorData from "./pages/adminPanel/vendor/VendorData";
// import CustomerData from "./pages/adminPanel/customer/CustomerData";
import VendorList from "./pages/adminPanel/vendor/VendorList";
import ProtectedRoute from './pages/Routers/ProtectedRoute';
import React, { useState, createContext, useContext } from 'react'

import SignInOtp from "./pages/auth/SignInOtp";
import Login1 from "./pages/auth/Login1";
import Gemstone from './pages/adminPanel/vendor/Gemstone/Gemstone'
import MetalData from "./pages/adminPanel/vendor/Metal/MetalData";
import ProductCategory from "./pages/adminPanel/vendor/ProductCategory/ProductCategory";
import VendorShops from "./pages/adminPanel/vendor/VendorShop/VendorShops";
import CouponsList from "./pages/Coupons/CouponsList";
import CouponsData from "./pages/Coupons/CouponsData";
import ProductData from "./pages/adminPanel/vendor/Product/ProductData";
import ProductOffer from './pages/adminPanel/vendor/Offer/ProductOffer'
import OrderData from "./pages/adminPanel/vendor/Order/OrderData";
import Profile from "./pages/adminPanel/customer/Profile";
import Master from "./pages/adminPanel/Master";
import CustomerData from "./pages/adminPanel/customer/CustomerData";
import CustomerWishlist from "./pages/adminPanel/customer/CustomerWishlist";
import CustomerCart from "./pages/adminPanel/customer/CustomerCart";
import CustomerOrder from './pages/adminPanel/customer/CustomerOrder';
import BlockedCustomerList from "./pages/adminPanel/customer/BlockedCustomerList";
import AddCoupons from "./pages/Coupons/AddCoupons";
import BlockedVendorList from "./pages/adminPanel/vendor/BlockedVendorList";
import AddVendor from "./pages/adminPanel/vendor/AddVendor";
import VendorShopList from "./pages/adminPanel/vendor/VendorShop/VendorShopList";
import VendorProfile from "./pages/adminPanel/vendor/VendorProfile";
import Metal from "./pages/adminPanel/vendor/Metal/Metal";
import GemstoneList from "./pages/adminPanel/vendor/Gemstone/GemstoneList";
import OfferList from "./pages/adminPanel/vendor/Offer/OfferList";
import OrderList from "./pages/adminPanel/vendor/Order/OrderList";
import ProductList from "./pages/adminPanel/vendor/Product/ProductList";
import ProductCategoryList from "./pages/adminPanel/vendor/ProductCategory/ProductCategoryList";
import BankDetails from "./pages/adminPanel/vendor/VendorBank/BankDetails";
import BrandEndorsementList from "./pages/adminPanel/vendor/SubBrand/BrandEndorsementList";
import BrandData from "./pages/adminPanel/vendor/SubBrand/BrandData";
import AddBrand from "./pages/adminPanel/vendor/SubBrand/AddBrand";
import AddFAQ from './pages/FAQ/AddFaq'
import AddBrandEndorsement from "./pages/BrandEndorsement/AddBrandEndorsement";
import BrandEndorsementData from "./pages/BrandEndorsement/BrandEndorsementData";
import Approval from "./pages/adminPanel/customer/Approval";
import ProductApproval from "./pages/adminPanel/customer/ProductApproval";

export const UserTypeContext = createContext({ userType: 'Customer', setUserType: {} })


function App() {
  const [userType, setUserType] = useState('Customer')

  return (
    <UserTypeContext.Provider value={{ userType, setUserType }}>

      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Login1 />} />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <DashboardAdmin />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Otp"
            element={
              <ProtectedRoute>
                <SignInOtp />
              </ProtectedRoute>
            }
          />
          <Route
            path="/clipboard"
            element={
              <ProtectedRoute>
                <Master />
              </ProtectedRoute>
            }
          />
          <Route
            path="/customerData"
            element={
              <ProtectedRoute>
                <CustomerData />
              </ProtectedRoute>
            }
          />
          <Route
            path="/customerWishlist"
            element={
              <ProtectedRoute>
                <CustomerWishlist />
              </ProtectedRoute>
            }
          />
          <Route
            path="/customerCart"
            element={
              <ProtectedRoute>
                <CustomerCart />
              </ProtectedRoute>
            }
          />
          <Route
            path="/customerOrders"
            element={
              <ProtectedRoute>
                <CustomerOrder />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/create"
            element={
              <ProtectedRoute>
                <Login />
              </ProtectedRoute>
            }
          />
          <Route
            path="/gemstonedata"
            element={
              <ProtectedRoute>
                <Gemstone />
              </ProtectedRoute>
            }
          />
          <Route
            path="/gemstone"
            element={
              <ProtectedRoute>
                <GemstoneList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/metaldata"
            element={
              <ProtectedRoute>
                <MetalData />
              </ProtectedRoute>
            }
          />
          <Route
            path="/metal"
            element={
              <ProtectedRoute>
                <Metal />
              </ProtectedRoute>
            }
          />
          <Route
            path="/clipboard"
            element={
              <ProtectedRoute>
                <CouponsList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/addcoupon"
            element={
              <ProtectedRoute>
                <AddCoupons />
              </ProtectedRoute>
            }
          />
          <Route
            path="/addBrand"
            element={
              <ProtectedRoute>
                <AddBrandEndorsement />
              </ProtectedRoute>
            }
          />
          <Route
            path="/blockedCustomer"
            element={
              <ProtectedRoute>
                <BlockedCustomerList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/approval"
            element={
              <ProtectedRoute>
                <Approval />
              </ProtectedRoute>
            }
          />
             <Route
            path="/productapproval"
            element={
              <ProtectedRoute>
                <ProductApproval />
              </ProtectedRoute>
            }
          />
          <Route
            path="/couponsdata"
            element={
              <ProtectedRoute>
                <CouponsData />
              </ProtectedRoute>
            }
          />
          <Route
            path="/brandEndorsementData"
            element={
              <ProtectedRoute>
                <BrandEndorsementData />
              </ProtectedRoute>
            }
          />
          <Route
            path="/productData"
            element={
              <ProtectedRoute>
                <ProductData />
              </ProtectedRoute>
            }
          />
          <Route
            path="/offerdata"
            element={
              <ProtectedRoute>
                <ProductOffer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/offer"
            element={
              <ProtectedRoute>
                <OfferList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/orderdata"
            element={
              <ProtectedRoute>
                <OrderData />
              </ProtectedRoute>
            }
          />
          <Route
            path="/order"
            element={
              <ProtectedRoute>
                <OrderList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/store"
            element={
              <ProtectedRoute>
                <VendorShopList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/jewellery"
            element={
              <ProtectedRoute>
                <ProductList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/vendorprofile"
            element={
              <ProtectedRoute>
                <VendorProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/VendorShop"
            element={
              <ProtectedRoute>
                <VendorShops />
              </ProtectedRoute>
            }
          />
          <Route
            path="/BlockedVendor"
            element={
              <ProtectedRoute>
                <BlockedVendorList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/AddVendor"
            element={
              <ProtectedRoute>
                <AddVendor />
              </ProtectedRoute>
            }
          />
          <Route
            path="/categorydata"
            element={
              <ProtectedRoute>
                <ProductCategory />
              </ProtectedRoute>
            }
          />
          <Route
            path="/category"
            element={
              <ProtectedRoute>
                <ProductCategoryList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/subbrand"
            element={
              <ProtectedRoute>
                <BrandEndorsementList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/brandData"
            element={
              <ProtectedRoute>
                <BrandData />
              </ProtectedRoute>
            }
          />
          <Route
            path="/addsubrand"
            element={
              <ProtectedRoute>
                <AddBrand />
              </ProtectedRoute>
            }
          />
          <Route
            path="/bankdetails"
            element={
              <ProtectedRoute>
                <BankDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/clipboard"
            element={
              <ProtectedRoute>
                <CustomerList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/vendorData"
            element={
              <ProtectedRoute>
                <VendorData />
              </ProtectedRoute>
            }
          />
          {/* <Route
          path="/customerData"
          element={
            <ProtectedRoute>
              <CustomerData />
            </ProtectedRoute>
          }
        /> */}
          <Route
            path="/clipboard"
            element={
              <ProtectedRoute>
                <VendorList />
              </ProtectedRoute>
            }
          />

          <Route
            path="/AddFaq"
            element={
              <ProtectedRoute>
                <AddFAQ />
              </ProtectedRoute>
            }
          />

        </Routes>
      </BrowserRouter>
    </UserTypeContext.Provider>

  )
}

export default App
