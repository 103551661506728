import React, { useState, useEffect } from 'react'
import { useLocation } from "react-router-dom";
import {
    Card,
    Row,
    Col,
    Figure,
    Table,
    Button,
    Modal,
    Form,
} from "react-bootstrap";
import SideBar from "../../../dashboard/SideBar";
import FirstNavbar from "../../../dashboard/FirstNavbar";
import axios from 'axios';
import baseURL from '../../authServices/Url';
import { toast } from "react-toastify";


function ProductCategory() {
    const { state } = useLocation();
    const [categoryData1, setcategoryData1] = useState(state)
    console.log('categoryData1', categoryData1, state)
    let token = localStorage.getItem('accessToken');
    const [img, setImg] = useState("");
    const [brandData, setBrandData] = useState([])
    const [brand, setBrand] = useState('')
    const [physicalGoldForm, setPhysicalGoldForm] = useState({
        name: '' || categoryData1?.category_name,
        image: '' || categoryData1?.category_image,
        description: '' || categoryData1?.category_description,

    })
    const [physicalGoldFormErrors, setPhysicalGoldFormErrors] = React.useState({
        name: '',
        image: '',
        description: '',

    })
    const [categoryModal, setcategoryModal] = useState(false)

    const handleCloseModal = () => setcategoryModal(false)
    const handleShowModal = () => {
        console.log('categoryData111111', categoryData1);
        setcategoryModal(true)
        setPhysicalGoldForm({
            name: categoryData1?.category_name,
            image: categoryData1?.category_image,
            description: categoryData1?.category_description,
        })

    }

    const categoryAvailable = async () => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        const getcategory = await axios.get(`${baseURL}/admin/readProductCategory?product_category_id=${categoryData1?.id}`, requestOptions)
        // console.log('getcategory@@@@@@@@@@@@@@@@@@@', getcategory, getcategory?.data?.data[0])
        if (getcategory?.status === 200) {
            setcategoryData1(getcategory?.data?.data[0])
            console.log('getcategory@@@@@@@@@@@@@@@@@@@', getcategory, categoryData1)

        } else {
            console.log('error')
        }
    }

    const brandAvailable = async () => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        const getbrand = await axios.get(`${baseURL}/admin/brandEdorsment?gold_vendor_id=${categoryData1?.gold_vendor_id}`, requestOptions)
        console.log('getbrand@@@@@@@@@@@@@@@@@@@', getbrand)
        if (getbrand?.status === 200) {
            setBrandData(getbrand?.data?.data)
        } else {
            console.log('error')
        }
    }

    useEffect(() => {
        categoryAvailable()
        brandAvailable()
    }, [])

    const handleChange = (e) => {
        setPhysicalGoldForm({
            ...physicalGoldForm,
            [e?.target?.name]: e?.target?.value,
            [e.target.image]: e.target.value,
            [e.target.description]: e.target.description,

        });

        setPhysicalGoldFormErrors({
            ...physicalGoldFormErrors,
            [e.target.name]: null,
            [e.target.description]: null,
            [e.target.image]: null,

        });
    };

    const handleValidation = () => {
        const { name, } = physicalGoldForm
        const newErrors = {}

        if (!name) {
            newErrors.name = 'please enter name'
        }
        if (!img) {
            newErrors.image = 'please upload image'
        }
        // if (!description) {
        //     newErrors.description = 'please enter description'
        // }

        return newErrors
    }


    const handleSubmitEdit = async (url) => {
        const handleValidationObject = handleValidation()
        if (Object.keys(handleValidationObject).length > 0) {
            setPhysicalGoldFormErrors(handleValidationObject)
        } else {

            const requestOptions = {
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };
            const userCredentials = JSON.stringify({
                'category_name': physicalGoldForm?.name,
                'category_image': url ? url : physicalGoldForm?.image,
                // 'category_description': physicalGoldForm?.description,
                "product_category_id": categoryData1?.id,
                "brand_id": brand,
            })

            const editcategory = await axios.patch(`${baseURL}/admin/updateProductCategory?product_category_id=${categoryData1?.id}&gold_vendor_id=${categoryData1?.gold_vendor_id}`, userCredentials, requestOptions)
            console.log('editcategory editcategory', editcategory);
            if (editcategory?.status === 200) {
                toast.success('category has successfully updated')
                categoryAvailable()
                setcategoryData1(editcategory?.data?.data)
                setPhysicalGoldForm({
                    ...physicalGoldForm,
                    name: '',
                    image: '',
                    description: '',

                })
                handleCloseModal()
            } else {
                console.log(editcategory, "error after submit")
                // categoryAvailable()
                toast.show({
                    type: 'error',
                    text1: `${editcategory.message}`,
                })
                setcategoryData1()
                handleCloseModal()
            }
        }
    }

    const categoryImageUpload = async () => {
        try {
            var formdata = new FormData();
            formdata.append("image", img);
            console.log('image userCredentials', img)
            const requestOptions = {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            const imgUpload = await axios.post(`${baseURL}/admin/mediaMingle/upload`, formdata, requestOptions)
            console.log('imgUpload imgUpload imgUpload', imgUpload)
            if (imgUpload?.status === 200) {
                console.log('selectedProduct?.id', imgUpload?.data?.data?.url)
                // selectedProduct?.id ? handleSubmitEdit(imgUpload?.data?.data?.url) : handleSubmit(imgUpload?.data?.data?.url)
                handleSubmitEdit(imgUpload?.data?.data?.url)
            }
        } catch (err) {
            console.log('err0r ==>', err)
        }
    }


    return (
        <div>
            <div class="sidebar">
                <SideBar type='Details' />
            </div>
            <div class="content">
                <div className="container">
                    <FirstNavbar />
                    <h3 className='headertext'>category Data</h3>
                    <div>
                        <Card className="p-2">
                            <Row>
                                <Col md={6}>
                                    <h3 className="headertext text1">category Deatils:</h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={1}></Col>
                                <Col md={2}>
                                    <Figure>
                                        <Figure.Image
                                            width={100}
                                            height={100}
                                            alt="171x180"
                                            src={categoryData1?.category_image}
                                        />
                                    </Figure>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={1}></Col>
                                <Col md={3}>
                                    <h5>Product Category</h5>
                                </Col>
                                <Col md={1}>:</Col>
                                <Col md={3}>
                                    <h5>{categoryData1?.category_name}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={1}></Col>
                                <Col md={3}>
                                    <h5>Sub Brand Name</h5>
                                </Col>
                                <Col md={1}>:</Col>
                                <Col md={3}>
                                    <h5>{categoryData1?.brand_endorsment?.brand_name}</h5>
                                </Col>
                            </Row>
                            {/* <Row>
                                <Col md={1}></Col>
                                <Col md={3}>
                                    <h5>description</h5>
                                </Col>
                                <Col md={1}>:</Col>
                                <Col md={3}>
                                    <h5>{categoryData1?.category_description}</h5>
                                </Col>
                            </Row> */}
                            <hr />
                            <Row>
                                <Col md={3}>
                                    <Button variant="warning" onClick={handleShowModal}>
                                        Edit category
                                    </Button>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </div>
            </div>
            <Modal
                show={categoryModal}
                onHide={handleCloseModal}
                backdrop="static"
                keyboard={false}
                centered
                size="lg"
            >
                <h4 className='headertext text-center'>Edit Product Category Details:</h4>
                <Modal.Body>

                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Product Category Name</Form.Label>
                                <Form.Control
                                    maxLength={50}
                                    type="text"
                                    placeholder="Enter Product Category Name"
                                    size="sm"
                                    name='name'
                                    onChange={handleChange}
                                    autoComplete='off'
                                    className='mb-3'
                                    // pattern="/^[a-zA-Z]*$/"
                                    required
                                    value={physicalGoldForm?.name}
                                />
                                <span className="text-danger">{physicalGoldFormErrors?.name}</span>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Product Category Image</Form.Label>
                                <Row>
                                    <Figure>
                                        <Figure.Image
                                            width={100}
                                            height={100}
                                            alt="171x180"
                                            // src={gemstoneData1?.gemstone_image}
                                            src={img?.path ? img?.path : categoryData1?.category_image}
                                        />
                                    </Figure>
                                </Row>
                                <Row>
                                    <Form.Control
                                        maxLength={50}
                                        type="file"
                                        placeholder="upload Product Category Image"
                                        size="sm"
                                        name='image'
                                        onChange={(e) => setImg(e.target.files[0])}
                                        autoComplete='off'
                                        className='mb-3'
                                    // value={physicalGoldForm?.image}
                                    />
                                    <span className="text-danger">{physicalGoldFormErrors?.image}</span>
                                </Row>
                            </Form.Group>


                        </Col>

                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Select Brand</Form.Label>
                                <div className="leftedge d-flex justify-content-space">
                                    <Form.Select
                                        aria-label="select Brand"
                                        size={"sm"}
                                        className="selectsizesmall"
                                        name="brand"
                                        onChange={(e) => setBrand(e.target.value)}
                                        value={brand}
                                    >

                                        <option >Select Brand</option>
                                        {brandData?.map((item, i) => {
                                            return (
                                                <option key={i} name='brand' value={item?.id}>{item?.brand_name}</option>
                                            )
                                        })}
                                    </Form.Select>
                                </div>
                                <span className="text-danger">{physicalGoldFormErrors?.name}</span>
                            </Form.Group>
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Description</Form.Label>
                                <Form.Control

                                    as="textarea" rows={3}
                                    placeholder="Enter description"
                                    size="md"
                                    name='description'
                                    onChange={handleChange}
                                    autoComplete='off'
                                    className='mb-3'
                                    value={physicalGoldForm?.description}
                                />
                                <span className="text-danger">{physicalGoldFormErrors?.description}</span>
                            </Form.Group>
                        </Col>
                    </Row> */}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseModal} variant="secondary">
                        Cancel
                    </Button>
                    <Button variant="warning" onClick={() => categoryImageUpload()}>
                        {/* {  selectedProduct?.id ? handleSubmitEdit() : handleSubmit() } */}
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ProductCategory