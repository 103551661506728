import React, { useState, useEffect } from 'react'
import { useLocation } from "react-router-dom";
import {
  Card,
  Row,
  Col,
} from "react-bootstrap";
import SideBar from "../dashboard/SideBar";
import FirstNavbar from "../dashboard/FirstNavbar";


function CouponsData() {
  const { state } = useLocation();
  const CouponData = state
  // const [CouponData, setCouponData] = useState(state)
  console.log('CouponData', CouponData, state)
  console.log('CouponData state',  state)
  let token = localStorage.getItem('accessToken');
 
 
  return (
    <div>
      <div class="sidebar">
        <SideBar type='Details'/>
      </div>
      <div class="content">
        <div className="container">
          <FirstNavbar />
          <h3 className='headertext'>Coupon Data</h3>
          <div>
            <Card className="p-2">
              <Row>
                <Col md={6}>
                  <h3 className="headertext text1">Coupon Details:</h3>
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={4}>
                  <h5>Coupon Name</h5>
                </Col>
                <Col md={1}>:</Col>
                <Col md={6}>
                  <h5>{CouponData?.coupon_name}</h5>
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={4}>
                  <h5>Coupon Code</h5>
                </Col>
                <Col md={1}>:</Col>
                <Col md={6}>
                  <h5>{CouponData?.coupon_code}</h5>
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={4}>
                  <h5>Discount Type</h5>
                </Col>
                <Col md={1}>:</Col>
                <Col md={6}>
                  <h5>{CouponData?.coupon_discount_type}</h5>
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={4}><h5>Discount Value</h5></Col>
                <Col md={1}>:</Col>
                <Col md={6}><h5>{CouponData?.coupon_discount_value}</h5></Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={4}>
                  <h5>Maximum Discount Value</h5>
                </Col>
                <Col md={1}>:</Col>
                <Col md={6}>
                  <h5>{CouponData?.coupon_maximum_discount_value}</h5>
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={4}>
                  <h5>Minimum Purchase Amount</h5>
                </Col>
                <Col md={1}>:</Col>
                <Col md={6}>
                  <h5>{CouponData?.coupon_minimum_purchase_amount}</h5>
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={4}>
                  <h5>Product Category Excluded</h5>
                </Col>
                <Col md={1}>:</Col>
                <Col md={6}>
                  <h5>{CouponData?.coupon_product_category_excluded === null ? '-' : CouponData?.coupon_product_category_excluded}</h5>
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={4}>
                  <h5>Product Category Included</h5>
                </Col>
                <Col md={1}>:</Col>
                <Col md={6}>
                  <h5>{CouponData?.coupon_product_category_included === null ? '-' : CouponData?.coupon_product_category_excluded}</h5>
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={4}>
                  <h5>Product Excluded</h5>
                </Col>
                <Col md={1}>:</Col>
                <Col md={6}>
                  <h5>{CouponData?.coupon_product_excluded === null ? '-' : CouponData?.coupon_product_category_excluded}</h5>
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={4}>
                  <h5>Product Category Included</h5>
                </Col>
                <Col md={1}>:</Col>
                <Col md={6}>
                  <h5>{CouponData?.coupon_product_included === null ? '-' : CouponData?.coupon_product_category_excluded}</h5>
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={4}>
                  <h5>Quantity</h5>
                </Col>
                <Col md={1}>:</Col>
                <Col md={6}>
                  <h5>{CouponData?.coupon_quantity}</h5>
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={4}>
                  <h5>Start Date</h5>
                </Col>
                <Col md={1}>:</Col>
                <Col md={6}>
                  <h5>{CouponData?.coupon_start_date}</h5>
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={4}>
                  <h5>End Date</h5>
                </Col>
                <Col md={1}>:</Col>
                <Col md={6}>
                  <h5>{CouponData?.coupon_end_date}</h5>
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={4}>
                  <h5>Allow Gift Card</h5>
                </Col>
                <Col md={1}>:</Col>
                <Col md={6}>
                  <h5>{`${CouponData?.coupon_allow_gift_card}`}</h5>
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={4}>
                  <h5>Applies To</h5>
                </Col>
                <Col md={1}>:</Col>
                <Col md={6}>
                  <h5>{CouponData?.coupon_applies_to === null ? '-' : CouponData?.coupon_product_category_excluded}</h5>
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={4}>
                  <h5>Coupon to Customer</h5>
                </Col>
                <Col md={1}>:</Col>
                <Col md={6}>
                  <h5>{CouponData?.coupon_to_customer === null ? '-' : CouponData?.coupon_to_customer}</h5>
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={4}>
                  <h5>Coupon to Vendor</h5>
                </Col>
                <Col md={1}>:</Col>
                <Col md={6}>
                  <h5>{CouponData?.coupon_to_vendor === null ? '-' : CouponData?.coupon_to_vendor}</h5>
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={4}>
                  <h5>Usage Limit</h5>
                </Col>
                <Col md={1}>:</Col>
                <Col md={6}>
                  <h5>{CouponData?.coupon_usage_limit === null ? '-' : CouponData?.coupon_usage_limit}</h5>
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={4}>
                  <h5>Usage Limit Value</h5>
                </Col>
                <Col md={1}>:</Col>
                <Col md={6}>
                  <h5>{CouponData?.coupon_usage_limit_value === null ? '-' : CouponData?.coupon_usage_limit_value}</h5>
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={4}>
                  <h5>Coupon Used</h5>
                </Col>
                <Col md={1}>:</Col>
                <Col md={6}>
                  <h5>{CouponData?.coupon_used}</h5>
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={4}>
                  <h5>description</h5>
                </Col>
                <Col md={1}>:</Col>
                <Col md={6}>

                  <h5>{CouponData?.coupon_description}</h5>
                </Col>
              </Row>

            </Card>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CouponsData