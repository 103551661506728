import React, { useEffect, useState } from "react";
import SideBar from "../../dashboard/SideBar";
import "./index.css";
import FirstNavbar from "../../dashboard/FirstNavbar";
import {
  Card,
  Row,
  Col,
  Figure,
  Table,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import baseURL from "../authServices/Url";

function VendorProfile() {
  let token = localStorage.getItem("accessToken");


  const [profileModal, setProfileModal] = useState(false);
  const [vendorData, setVendorData] = useState({
    gold_vendor_name: "",
    gold_vendor_email: "",
    gold_vendor_phone: "",
    gold_vendor_gst_number: "",
    gold_vendor_address: "",
    gold_vendor_address_pincode: "",
    gold_vendor_address_city: "",
    gold_vendor_address_state: "",
    gold_vendor_constitution_of_business: "",
    gold_vendor_proprietor_name: "",
    gold_vendor_banners: ""
  });

  const [img, setImg] = useState([]);
  const [gstImage, setGstImage] = useState('');
  const [logoImg, setLogoImg] = useState('');

  const handleCloseModal = () => setProfileModal(false);
  const handleShowModal = () => setProfileModal(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setVendorData({
      ...vendorData,
      [name]: value,
    });
  };

  const handleImageChange = (e) => {
    const image = e.target.files;
    setImg([...image]);
  };

  // const handleLogoImageChange = (e) => {
  //   const file = e.target.files[0];
  //   setLogoImg(URL.createObjectURL(file));
  // };

  // const handleGstImageChange = (e) => {
  //   const file = e.target.files[0];
  //   setGstImage(URL.createObjectURL(file));
  // };

  const logoImageUpload = async () => {
  
      const formData = new FormData();
      formData.append("image", logoImg);
  
      const requestOptions = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      return await axios.post(`${baseURL}/admin/mediaMingle/upload`, formData, requestOptions)
      
    }
  
  const gstImageUpload = async () => {
    
      var formData = new FormData();
      formData.append("image", gstImage);
  
      const requestOptions = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      return await axios.post(`${baseURL}/admin/mediaMingle/upload`, formData, requestOptions)
      
    }
  
    const bannerImagesUpload = async (id) => {
      var formdata = new FormData();
      for (let i = 0; i < img?.length; i++) {
        formdata?.append("images", img[i]);
      }
      formdata.append("image", img);
      const requestOptions = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      return await axios.post(`${baseURL}/admin/mediaMingle/upload/multiple`, formdata, requestOptions)
      }

  const handleSubmit = async () => {
    try {
      const uploadedImage = await logoImageUpload();
      const UploadBannerImage = await bannerImagesUpload();
      const gstImage = await gstImageUpload();


      const response = await axios.post(`${baseURL}/admin/AddGoldVendor`, {
        ...vendorData,
        is_active: "true",
        gold_vendor_logo: uploadedImage.data.data.url,
        gold_gst_image: gstImage.data.data.url,
        gold_vendor_phone_code: '91',
        gold_vendor_banners: UploadBannerImage.data.data.url
      });
      if (response.status === 201) {
        toast.success("Vendor created successfully!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          theme: "light",
        });
        handleCloseModal();
      }
    } catch (error) {
      console.error("Error creating vendor:", error);
      toast.error("Required All Fields Please Fill each fields.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        theme: "light",
      });
    }
  };
  // const validateGSTNumber = (gstNumber) => {
  //   // GST number pattern: 2 digits, 5 alphabets, 4 digits, 1 alphabet, 1 digit, 1 alphabet, 1 digit
  //   const gstPattern =
  //     /^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1}[0-9]{1}$/;
  //   return gstPattern.test(gstNumber);
  // };

  return (
    <>
      <div class="sidebar">
        <SideBar type="Dashboard" />
      </div>
      <div class="content">
        <div className="container">
          <FirstNavbar />

          <h3 className="headertext">Vendor Data</h3>

          <div>
            <Card className="p-2">
              <Row>
                <Col md={6}>
                  <h3 className="headertext text1">Vendor Add Deatils:</h3>
                </Col>
              </Row>

              <hr />
              <Row>
                <Col md={3}>
                  <Button variant="warning" onClick={handleShowModal}>
                    Add Vendor
                  </Button>
                </Col>
              </Row>
            </Card>
          </div>
        </div>
      </div>
      <Modal
        show={profileModal}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <h4 className="headertext text-center">Add Vendor Details:</h4>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter Your Name"
                  size="sm"
                  name="gold_vendor_name"
                  value={vendorData.gold_vendor_name}
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                  pattern="[a-zA-Z]+"
                  required
                />
                {(vendorData.gold_vendor_name === "" && (
                  <Form.Text className="text-danger">
                    Name is required.
                  </Form.Text>
                )) ||
                  (!/[a-zA-Z]+/.test(vendorData.gold_vendor_name) && (
                    <Form.Text className="text-danger">
                      Name must contain only alphabets.
                    </Form.Text>
                  ))}
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>GST Number</Form.Label>
                <Form.Control
                  maxLength={15}
                  type="text"
                  placeholder="Enter Your GST Number"
                  size="sm"
                  name="gold_vendor_gst_number"
                  value={vendorData.gold_vendor_gst_number}
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                  required
                />
                {(vendorData.gold_vendor_gst_number === "" && (
                  <Form.Text className="text-danger">
                    GST number is required.
                  </Form.Text>
                // )) ||
                //   (vendorData.gold_vendor_gst_number !== "" &&
                //     !validateGSTNumber(vendorData.gold_vendor_gst_number) && (
                //       <Form.Text className="text-danger">
                //         Please enter a valid GST number in the format
                //         22AAAAA0000A1Z5.
                //       </Form.Text>
                    ))}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter house no, street, area"
                  size="sm"
                  name="gold_vendor_address"
                  value={vendorData.gold_vendor_address}
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                  required
                />
                {vendorData.gold_vendor_address === "" && (
                  <Form.Text className="text-danger">
                    Address is required.
                  </Form.Text>
                )}
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>City</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter your city"
                  size="sm"
                  name="gold_vendor_address_city"
                  value={vendorData.gold_vendor_address_city}
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                  required
                />
                {vendorData.gold_vendor_address_city === "" && (
                  <Form.Text className="text-danger">
                    City is required.
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  maxLength={10}
                  type="text"
                  placeholder="Enter the Phone Number"
                  size="sm"
                  name="gold_vendor_phone"
                  value={vendorData.gold_vendor_phone}
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                  pattern="[6-9]\d{9}"
                  required
                />
                {vendorData.gold_vendor_phone === "" && (
                  <Form.Text className="text-danger">
                    Phone number is required.
                  </Form.Text>
                )}
                {vendorData.gold_vendor_phone !== "" &&
                  !/[6-9]\d{9}/.test(vendorData.gold_vendor_phone) && (
                    <Form.Text className="text-danger">
                      Please enter a valid phone number its start 6 to 9.
                    </Form.Text>
                  )}
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="email"
                  placeholder="Enter the Email Address"
                  size="sm"
                  name="gold_vendor_email"
                  value={vendorData.gold_vendor_email}
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                  required
                />
                {vendorData.gold_vendor_email === "" && (
                  <Form.Text className="text-danger">
                    Email address is required.
                  </Form.Text>
                )}
                {vendorData.gold_vendor_email !== "" &&
                  !/\S+@\S+\.\S+/.test(vendorData.gold_vendor_email) && (
                    <Form.Text className="text-danger">
                      Please enter a valid email address.
                    </Form.Text>
                  )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>State</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter your State"
                  size="sm"
                  name="gold_vendor_address_state"
                  value={vendorData.gold_vendor_address_state}
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                  required
                />
                {vendorData.gold_vendor_address_state === "" && (
                  <Form.Text className="text-danger">
                    State is required.
                  </Form.Text>
                )}
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Pincode</Form.Label>
                <Form.Control
                  maxLength={6}
                  type="text"
                  placeholder="Enter your Pincode"
                  size="sm"
                  name="gold_vendor_address_pincode"
                  value={vendorData.gold_vendor_address_pincode}
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                  pattern="[0-9]{6}"
                  required
                />
                {vendorData.gold_vendor_address_pincode === "" && (
                  <Form.Text className="text-danger">
                    Pincode is required.
                  </Form.Text>
                )}
                {vendorData.gold_vendor_address_pincode !== "" &&
                  !/^[0-9]{6}$/.test(
                    vendorData.gold_vendor_address_pincode
                  ) && (
                    <Form.Text className="text-danger">
                      Please enter a valid 6-digit pincode.
                    </Form.Text>
                  )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Vendor Logo</Form.Label>
                <Figure>
                  <Figure.Image
                    className="image-preview"
                    alt="Image logo"
                    src={logoImg}
                  />
                </Figure>
                <Form.Control
                  maxLength={50}
                  type="file"
                  placeholder="upload Logo"
                  size="sm"
                  name="proprietorLogo"
                  // value={vendorProprietor?.proprietorLogo}
                  onChange={(e) => { console.log('eeee', e.target.files[0]); setLogoImg(e.target.files[0]); console.log('setLogoImg', logoImg) }}
                  autoComplete="off"
                  className="mb-3"
                />
                {!logoImg && (
                  <Form.Text className="text-danger">
                    Vendor logo is required.
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>GST Image</Form.Label>
                <Figure>
                  <Figure.Image
                    className="image-preview"
                    alt="GST Image"
                    src={gstImage}
                  />
                </Figure>
                <Form.Control
                  maxLength={50}
                  type="file"
                  placeholder="upload Logo"
                  size="sm"
                  name="proprietorLogo"
                  // value={vendorProprietor?.proprietorLogo}
                  onChange={(e) => { console.log('eeee', e.target.files[0]); setGstImage(e.target.files[0]);  }}
                  autoComplete="off"
                  className="mb-3"
                />
                {!gstImage && (
                  <Form.Text className="text-danger">
                    GST image is required.
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Constitution Business</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter your Constitution of Business"
                  size="sm"
                  name="gold_vendor_constitution_of_business"
                  value={vendorData.gold_vendor_constitution_of_business}
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                  required
                />
                {vendorData.gold_vendor_constitution_of_business === "" && (
                  <Form.Text className="text-danger">
                    Constitution of Business is required.
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                {/* Coomenting Banner Upload  */}
                {/* <Form.Label>Banner</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="file"
                  placeholder="upload Banner"
                  size="sm"
                  name="proprietorBanners"
                  onChange={(e) => handleImageChange(e)}
                  autoComplete="off"
                  className="mb-3"
                  multiple
                /> */}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Vendor Proprietor Name</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter Your Name"
                  size="sm"
                  name="gold_vendor_proprietor_name"
                  value={vendorData.gold_vendor_proprietor_name}
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                  pattern="[a-zA-Z]+"
                  required
                />
                {vendorData.gold_vendor_proprietor_name === "" && (
                  <Form.Text className="text-danger">
                    Proprietor Name is required.
                  </Form.Text>
                )}
                {vendorData.gold_vendor_proprietor_name !== "" &&
                  !/[a-zA-Z]+/.test(vendorData.gold_vendor_proprietor_name) && (
                    <Form.Text className="text-danger">
                      Proprietor Name must contain only alphabets.
                    </Form.Text>
                  )}
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseModal} variant="secondary">
            Cancel
          </Button>
          <Button variant="warning" onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default VendorProfile;
