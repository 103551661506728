import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap'; // Import Modal component
import SideBar from '../../dashboard/SideBar'
import FirstNavbar from '../../dashboard/FirstNavbar'
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import baseURL from '../authServices/Url';
import { useLocation } from "react-router-dom";

function VendorList(props) {
    let token = localStorage.getItem('accessToken');
    const { state } = useLocation();
    const vendorsDataaa = state
    const [deleteId, setDeleteId] = useState('')
    const [showModal, setShowModal] = useState(false);

    const openModal = (id) => {
        setShowModal(true);
        setDeleteId(id)
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const navigate = useNavigate();
    const [rowData, setRowData] = useState([]);

    const rowHeight = 100;
    const DefaultColumnSetting = {
        sortable: true,
        flex: 1,
        resizable: true,
        minWidth: 120,
    };
    const vendorNavigation = (state,userType) => {
        console.log('state,userType',state?.state, state, userType)
        localStorage.setItem('vendorDetails', JSON.stringify(state?.state));

        navigate('/vendorprofile', { userType })
    }

    const [colDefs, setColDefs] = useState([
        {
            headerName: "#",
            valueGetter: "node.rowIndex + 1",
            lockPosition: true,
        },
        {
            headerName: "Vendor Name",
            field: "gold_vendor_proprietor_name",
            filter: true,
          
        },
        {
            headerName: "Vendor Property  Name",
            filter: true,
            field: "gold_vendor_name",

        },
        {
            headerName: "Logo",
            field: 'gold_vendor_logo',
            cellRendererFramework: (params) => {
                return (
                    <img src={params?.data?.gold_vendor_logo} alt='logo' style={{ height: '100%', width: '100%', objectFit: 'contain' }} />
                )
            }
        },
        {
            headerName: "View",
            field: "view",
            cellRendererFramework: (params) => (
                <center>
                    {/* <FontAwesomeIcon icon={faEye} style={{ color: '#BE783B' }} onClick={() => { navigate('/vendorprofile', { state: params.data, userType:props?.userType }) }}> </FontAwesomeIcon>{" "} */}
                    <FontAwesomeIcon icon={faEye} style={{ color: '#BE783B' }} onClick={() => vendorNavigation({state: params.data, userType:props?.userType})}> </FontAwesomeIcon>{" "}

                </center>
            ),
        },
      
        {
            headerName: "Delete",
            cellRendererFramework: (params) => (
                <center>
                    <FontAwesomeIcon icon={faTrash} style={{ color: '#f00' }} onClick={() => openModal(params?.data?.id)}> </FontAwesomeIcon>{" "}
                </center>
            ),
        },
    ])

    const goldVendorRequest = async () => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        const getVendor = await axios.get(`${baseURL}/admin/readGoldVendor?limit=10&page_no=1`, requestOptions)
        console.log('getVendor@@@@@@@@@@@@@@@@@@@', getVendor)
        if (getVendor?.status === 200) {
            setRowData(getVendor?.data?.data)
        } else {
            console.log('error')
        }
    }

    useEffect(() => {
        goldVendorRequest();
    }, [])

    const permanentDeleteVendor = async () => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        const permanentVendorDel = await axios.delete(`${baseURL}/admin/deleteGoldVendor?gold_vendor_id=${deleteId}`, requestOptions)
        console.log('permanentVendorDel', permanentVendorDel)
        if (permanentVendorDel?.status === 200) {
            goldVendorRequest()
            closeModal()
        } else {
            console.log('error ==>', permanentVendorDel)
        }
    }

    const inActivateVendor = async () => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        const Vendorinactive = await axios.delete(`${baseURL}/admin/softDeleteGoldVendor?gold_vendor_id=${deleteId}`, requestOptions)
        console.log('Vendorinactive', Vendorinactive)
        if (Vendorinactive?.status === 200) {
            goldVendorRequest()
        } else {
            console.log('error ==>', Vendorinactive)
        }
    }

    return (
        <div>
            {/* <div class="sidebar">
                <SideBar />
            </div> */}
            <div class="content">
                <div className="container">
                    {/* <FirstNavbar /> */}
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} className='py-1'>
                        <h3 className='headertext'>Vendor Data</h3>
                        {/* <input class="btn btn-primary" type="button" value="Add Vendor" onClick={openModal} /> */}
                    </div>

                    <div
                        className="ag-theme-alpine"
                        style={{ height: "70vh", width: "100%" }}
                    >
                        <AgGridReact
                            rowHeight={rowHeight}
                            columnDefs={colDefs}
                            defaultColDef={DefaultColumnSetting}
                            pagination={true}
                            paginationPageSize={10}
                            rowData={rowData}
                        // onRowClicked={(e) => 
                        //     navigate('/vendorData',{id:e.data._id})}
                        />
                    </div>
                    {/* delete modal */}
                    <Modal show={showModal} onHide={closeModal}>
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>
                            <h3>Are you sure, you want to delete this vendor ....?</h3>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={closeModal}>
                                Cancel
                            </Button>
                            <Button variant="warning" onClick={inActivateVendor}>
                                inActivate
                            </Button>
                            <Button variant="danger" onClick={permanentDeleteVendor}>
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        </div>
    )
}

export default VendorList