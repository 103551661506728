import React, { useState, useEffect } from 'react'
import { useLocation } from "react-router-dom";
import {
  Card,
  Row,
  Col,
  Figure,
  Table,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import SideBar from "../../../dashboard/SideBar";
import FirstNavbar from "../../../dashboard/FirstNavbar";
import axios from 'axios';
import baseURL from '../../authServices/Url';
import { toast } from "react-toastify";

function Gemstone() {
  const { state } = useLocation();
  const [gemstoneData1, setGemstoneData1] = useState(state)
  console.log('gemstoneData1', gemstoneData1, state)
  let token = localStorage.getItem('accessToken');

  const [physicalGoldForm, setPhysicalGoldForm] = useState({
    name: '' || gemstoneData1?.gemstone_name,
    symbol: '' || gemstoneData1?.gemstone_symbol,
    description: '' || gemstoneData1?.gemstone_description,
    color: '' || gemstoneData1?.gemstone_color,
    cut: '' || gemstoneData1?.gemstone_cut,
    priceUnit: '' || gemstoneData1?.gemstone_price_per_weight_unit,
    clarity:'' || gemstoneData1?.gemstone_clarity,
    image:'' || gemstoneData1?.gemstone_image,
    weightUnit:'' || gemstoneData1?.gemstone_weight_unit,
  })

  const [physicalGoldFormErrors, setPhysicalGoldFormErrors] = React.useState({
    name: '',
    symbol: '',
    description: '',
    color: '',
    cut: '',
    priceUnit: '',
    clarity: '',
    image: '',
    weightUnit: ''
  })
  const [img, setImg] = useState('');
  const [gemstoneModal, setGemstoneModal] = useState(false)

  const handleCloseModal1 = () => setGemstoneModal(false)
 
  const handleShowModal1 = () => {
    console.log('gemstoneData1', gemstoneData1)
    setGemstoneModal(true)
    setImg(gemstoneData1?.gemstone_image)
    setPhysicalGoldForm({
      name: gemstoneData1?.gemstone_name,
      symbol: gemstoneData1?.gemstone_symbol,
      description: gemstoneData1?.gemstone_description,
      color: gemstoneData1?.gemstone_color,
      cut: gemstoneData1?.gemstone_cut,
      priceUnit: gemstoneData1?.gemstone_price_per_weight_unit,
      clarity: gemstoneData1?.gemstone_clarity,
      weightUnit: gemstoneData1?.gemstone_weight_unit,
    })

  }

  const gemStoneAvailable = async () => {
    const requestOptions = {
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    };
    const getGemstone = await axios.get(`${baseURL}/admin/readGemstone?limit=10&page_no=1&gold_vendor_id=${gemstoneData1?.gold_vendor_id}&gemstone_id=${gemstoneData1?.id}`, requestOptions)
    console.log('getGemstone@@@@@@@@@@@@@@@@@@@', getGemstone)
    if (getGemstone?.status === 200) {
      setGemstoneData1(getGemstone?.data?.data[0])
    } else {
        console.log('error')
    }
}

useEffect(() => {
    gemStoneAvailable()
}, [])

  const handleChange = (e) => {
    console.log('eeeeeeee',e?.target?.value)
    setPhysicalGoldForm({
      ...physicalGoldForm,
      [e?.target?.name]: e?.target?.value,
      [e.target.symbol]: e.target.value,
      [e.target.description]: e.target.vale,
      [e.target.color]: e.target.value,
      [e.target.clarity]: e.target.value,
      [e.target.priceUnit]: e.target.value,
      [e.target.cut]: e.target.value,
      [e.target.image]: e.target.value,
      [e.target.weightUnit]: e.target.value
    });

    setPhysicalGoldFormErrors({
      ...physicalGoldFormErrors,
      [e.target.name]: null,
      [e.target.description]: null,
      [e.target.symbol]: null,
      [e.target.color]: null,
      [e.target.clarity]: null,
      [e.target.priceUnit]: null,
      [e.target.cut]: null,
      [e.target.image]: null,
      [e.target.weightUnit]: null,
    });
  };

  const handleValidation = () => {
    const { name, symbol, description, weightUnit, color, clarity, priceUnit, cut, } = physicalGoldForm
    const newErrors = {}

    if (!name) {
      newErrors.name = 'please enter name'
    }
    if (!symbol) {
      newErrors.symbol = 'please enter symbol'
    }
    if (!description) {
      newErrors.description = 'please enter description'
    }
    if (!color) {
      newErrors.color = 'please enter color'
    }
    if (!clarity) {
      newErrors.clarity = 'please enter clarity'
    }
    if (!cut) {
      newErrors.cut = 'please enter cut'
    }
    if (!img) {
      newErrors.image = 'please upload image'
    }
    if (!priceUnit) {
      newErrors.priceUnit = 'please enter Price/Unit'
    }
    if (!weightUnit) {
      newErrors.weightUnit = 'please enter Price/Unit'
    }

    return newErrors
  }

  const handleSubmitEdit = async (url) => {

    const handleValidationObject = handleValidation()
    if (Object.keys(handleValidationObject).length > 0) {
      setPhysicalGoldFormErrors(handleValidationObject)
    } else {

      const requestOptions = {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };
      const userCredentials = {
        'gemstone_name': physicalGoldForm?.name,
        'gemstone_symbol': physicalGoldForm?.symbol,
        'gemstone_description': physicalGoldForm?.description,
        'gemstone_color': physicalGoldForm?.color,
        'gemstone_cut': physicalGoldForm?.cut,
        'gemstone_clarity': physicalGoldForm?.clarity,
        'gemstone_image': url ? url : physicalGoldForm?.image ,
        'gemstone_price_per_weight_unit': physicalGoldForm?.priceUnit,
        'gemstone_id': gemstoneData1?.id,
        'gemstone_weight_unit': physicalGoldForm?.weightUnit
      };

      const editGemstone = await axios.patch(`${baseURL}/admin/updateGemstone`, userCredentials, requestOptions)
      console.log('editGemstone editGemstone', editGemstone);
      if (editGemstone?.status === 200) {
        toast.success('Gemstone has successfully updated')
        gemStoneAvailable()
        // setGemstoneData1(editGemstone?.data?.data)
        setPhysicalGoldForm({
          ...physicalGoldForm,
          name: '',
          symbol: '',
          description: '',
          color: '',
          clarity: '',
          cut: '',
          priceUnit: '',
          image: '',
        })

        handleCloseModal1()
      } else {
        console.log(editGemstone, "error after submit")
        // gemStoneAvailable()
        toast.show({
          type: 'error',
          text1: `${editGemstone.message}`,
        })
        setGemstoneData1()
        handleCloseModal1()
      }
    }
  }

  const gemstoneImageUpload = async () => {

    try {

      var formdata = new FormData();
      formdata.append("image", img);
      console.log('image userCredentials', img)
      const requestOptions = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },

      };

      const imgUpload = await axios.post(`${baseURL}/admin/mediaMingle/upload`, formdata, requestOptions)
      console.log('imgUpload imgUpload imgUpload', imgUpload)
      if (imgUpload?.status === 200) {
        console.log('selectedProduct?.id', imgUpload?.data?.data?.url)
        // selectedProduct?.id ? handleSubmitEdit(imgUpload?.data?.data?.url) : handleSubmit(imgUpload?.data?.data?.url)
        handleSubmitEdit(imgUpload?.data?.data?.url)
      }
    } catch (err) {
      console.log('err0r ==>', err)
    }
  }
 
 
  return (
    <div>
      <div class="sidebar">
        <SideBar type='Details'/>
      </div>
      <div class="content">
        <div className="container">
          <FirstNavbar />
          <h3 className='headertext'>Gemstone Data</h3>
          <div>
            <Card className="p-2">
              <Row>
                <Col md={6}>
                  <h3 className="headertext text1">Gemstone Deatils:</h3>
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={2}>
                  <Figure>
                    <Figure.Image
                      width={100}
                      height={100}
                      alt="171x180"
                      src={gemstoneData1?.gemstone_image}
                    />
                  </Figure>
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={3}>
                  <h5>Name</h5>
                </Col>
                <Col md={1}>:</Col>
                <Col md={3}>
                  <h5>{gemstoneData1?.gemstone_name}</h5>
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={3}>
                  <h5>Clarity</h5>
                </Col>
                <Col md={1}>:</Col>
                <Col md={3}>
                  <h5>{gemstoneData1?.gemstone_clarity}</h5>
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={3}>
                  <h5>Colour</h5>
                </Col>
                <Col md={1}>:</Col>
                <Col md={3}>
                  <h5>{gemstoneData1?.gemstone_color}</h5>
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={3}><h5>Cut</h5></Col>
                <Col md={1}>:</Col>
                <Col md={3}><h5>{gemstoneData1?.gemstone_cut}</h5></Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={3}>
                  <h5>Symbol</h5>
                </Col>
                <Col md={1}>:</Col>
                <Col md={6}>
                  <h5>{gemstoneData1?.gemstone_symbol}</h5>
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={3}>
                  <h5>Weight Unit</h5>
                </Col>
                <Col md={1}>:</Col>
                <Col md={6}>
                  <h5>{gemstoneData1?.gemstone_weight_unit}</h5>
                </Col>
              </Row><Row>
                <Col md={1}></Col>
                <Col md={3}>
                  <h5>gemstone price/{gemstoneData1?.gemstone_weight_unit}</h5>
                </Col>
                <Col md={1}>:</Col>
                <Col md={6}>
                  <h5>{gemstoneData1?.gemstone_price_per_weight_unit}</h5>
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={3}>
                  <h5>description</h5>
                </Col>
                <Col md={1}>:</Col>
                <Col md={3}>

                  <h5>{gemstoneData1?.gemstone_description}</h5>
                </Col>
              </Row>

              <hr />
              <Row>
                <Col md={3}>
                  <Button variant="warning" onClick={handleShowModal1}>
                    Edit Gemstone
                  </Button>
                </Col>

              </Row>

            </Card>
          </div>
        </div>
      </div>

      <Modal
        show={gemstoneModal}
        onHide={handleCloseModal1}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <h4 className='headertext text-center'>Add/Edit Gemstone Details:</h4>
        <Modal.Body>

          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter Name"
                  size="sm"
                  name='name'
                  onChange={handleChange}
                  autoComplete='off'
                  className='mb-3'
                  // pattern="/^[a-zA-Z]*$/"
                  required
                  value={physicalGoldForm?.name}
                />
                <span className="text-danger">{physicalGoldFormErrors?.name}</span>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>symbol</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter symbol"
                  size="sm"
                  name='symbol'
                  onChange={handleChange}
                  autoComplete='off'
                  className='mb-3'
                  value={physicalGoldForm?.symbol}
                />
                <span className="text-danger">{physicalGoldFormErrors?.symbol}</span>
              </Form.Group>


            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Clarity</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter Clarity"
                  size="sm"
                  name='clarity'
                  onChange={handleChange}
                  autoComplete='off'
                  className='mb-3'
                  value={physicalGoldForm?.clarity}
                />
                <span className="text-danger">{physicalGoldFormErrors?.clarity}</span>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Coulor</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter Coulor"
                  size="sm"
                  name='color'
                  onChange={handleChange}
                  autoComplete='off'
                  className='mb-3'
                  value={physicalGoldForm?.color}
                />
                <span className="text-danger">{physicalGoldFormErrors?.color}</span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>cut</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter cut"
                  size="sm"
                  name='cut'
                  onChange={handleChange}
                  autoComplete='off'
                  className='mb-3'
                  value={physicalGoldForm?.cut}
                />
                <span className="text-danger">{physicalGoldFormErrors?.cut}</span>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Price/Unit</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter Price/Unit"
                  size="sm"
                  name='priceUnit'
                  onChange={handleChange}
                  autoComplete='off'
                  className='mb-3'
                  value={physicalGoldForm?.priceUnit}
                />
                <span className="text-danger">{physicalGoldFormErrors?.priceUnit}</span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Image</Form.Label>
                <Figure>
                  <Figure.Image
                    width={100}
                    height={100}
                    alt="171x180"
                    src={img?.path ? img?.path : img}
                  />
                </Figure>
                <Form.Control
                  maxLength={50}
                  type="file"
                  placeholder="Upload Image"
                  size="sm"
                  name='image'
                  onChange={(e) => {
                    // console.log('eeeee', e.target.files[0])
                    return setImg(e.target.files[0])
                  }}
                  autoComplete='off'
                  className='mb-3'
                />
                <span className="text-danger">{physicalGoldFormErrors?.image}</span>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Select Gemstone Weight unit</Form.Label>
                <div className="leftedge d-flex justify-content-space">
                  <Form.Select
                    aria-label="select Gemstone Weight unit"
                    size={"sm"}
                    className="selectsizesmall"
                    name="weightUnit"
                    onChange={handleChange}
                    value={physicalGoldForm?.weightUnit}
                  >
                    <option >Select Gemstone Weight unit</option>
                    <option name='productCategory' value='GRAMS'>{`Grams`}</option>
                    <option name='productCategory' value='CARAT'>{`Carat`}</option>
                  </Form.Select>
                </div>
                <span className="text-danger">{physicalGoldFormErrors?.weightUnit}</span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea" rows={3}
                  placeholder="Enter description"
                  size="md"
                  name='description'
                  onChange={handleChange}
                  autoComplete='off'
                  className='mb-3'
                  value={physicalGoldForm?.description}
                />
                <span className="text-danger">{physicalGoldFormErrors?.description}</span>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseModal1} variant="secondary">
            Cancel
          </Button>
          <Button variant="warning" onClick={() => gemstoneImageUpload()}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Gemstone