import React, { useState, useEffect } from 'react';
import { Button, Modal, Card, Row, Col, Form } from 'react-bootstrap';
// import SideBar from '../dashboard/SideBar'
// import FirstNavbar from '../dashboard/FirstNavbar'
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import baseURL from '../../authServices/Url';
import SideBar from '../../../dashboard/SideBar';
import FirstNavbar from '../../../dashboard/FirstNavbar';

function BrandEndorsementList() {
    let token = localStorage.getItem('accessToken');
    const vendorsDataaa = JSON.parse(localStorage.getItem('vendorDetails'));
    const [deleteId, setDeleteId] = useState('')
    const [showModal, setShowModal] = useState(false);
    const [brandModal, setBrandModal] = useState(false)
    const [brand, setBrand] = useState({
        name: '',
    })
    const [brandErrors, setBrandErrors] = useState({
        name: '',
    })

    const navigate = useNavigate();
    const [rowData, setRowData] = useState([]);
    console.log("rowData: ", rowData);

    const handleCloseModal = () => { setBrandModal(false) }

    const handleShowModal = () => {
        setBrandModal(true)
    }

    const openModal = (id) => {
        setShowModal(true);
        setDeleteId(id)
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const rowHeight = 100;
    const DefaultColumnSetting = {
        sortable: true,
        flex: 1,
        resizable: true,
        minWidth: 120,
    };

    const [colDefs, setColDefs] = useState([
        {
            headerName: "#",
            valueGetter: "node.rowIndex + 1",
            lockPosition: true,
        },
        {
            headerName: "Brand Name",
            field: "brand_name",
            filter: true,
        },
        {
            headerName: "APPROVED STATUS",
            field: "brand_is_approved",
            filter: true,
        },
        {
            headerName: "Edit",
            field: "Edit",
            cellRendererFramework: (params) => (
                <center>
                    <FontAwesomeIcon icon={faEdit} style={{ color: '#BE783B' }} onClick={() => { navigate('/addBrand', { state: params.data }) }}> </FontAwesomeIcon>{" "}
                </center>
            ),
        },
        {
            headerName: "Enable/Disable",
            field: "is_active",
            cellRendererFramework: (params) => (
                <center>
                    <Button variant={params.data.is_active ? "success" : "danger"}
                        onClick={() => toggleBrandStatus(params.data.id, params.data.is_active)}
                    >
                        {params?.data?.is_active ? "Enable" : "Disable"}
                    </Button>
                </center>

            ),
        }
    ])

    const toggleBrandStatus = async (productId, isActive) => {

        const requestOptions = {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        };


        const response = await axios.put(`${baseURL}/admin/brandEdorsmentisActiveStatus`, {
            brand_id: productId,
            is_active: !isActive
        }, requestOptions);

        console.log("response")
        if (response?.status === 200) {
            toast.success("Brand Updated Successfully")
            brandAvailable();
        } else {
            console.log('error ===>', response);
        }
    }

    const brandAvailable = async () => {
        try {
            const requestOptions = {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            };
            const response = await axios.get(`${baseURL}/admin/brandEdorsmentById?gold_vendor_id=${vendorsDataaa?.id}`, requestOptions);
            if (response.status === 200) {
                setRowData(response.data.data);
            } else {
                console.log('Error fetching brand data');
                toast.error('Failed to fetch brand data');
            }
        } catch (error) {
            console.error('Error fetching brand data:', error);
            toast.error('An error occurred while fetching brand data');
        }
    };

    useEffect(() => {
        brandAvailable()
    }, [])

    const handleChange = (e) => {
        setBrand({
            ...brand,
            [e.target.name]: e.target.value,
        });

        setBrandErrors({
            ...brandErrors,
            [e.target.name]: null,
        });
    };

    const handleValidation = () => {
        const { name } = brand
        const newErrors = {}

        if (!name) {
            newErrors.name = 'Please enter brand name'
        }

        return newErrors
    }

    const handleSubmit = async () => {
        // try {
        //     const handleValidationObject = handleValidation()

        //     if (Object.keys(handleValidationObject).length > 0) {
        //         setBrandErrors(handleValidationObject)
        //     } else {
        //         const requestOptions = {
        //             method: 'POST',
        //             headers: {
        //                 Authorization: `Bearer ${token}`,
        //                 'Content-Type': 'application/json',
        //             },
        //         };
        //         const userCredentials = {
        //             "brand_name": brand.name,
        //             "gold_vendor_id": vendorsDataaa?.id,
        //         }
        //         const addBrand = await axios.post(`${baseURL}/admin/brandEdorsment`, userCredentials, requestOptions)
        //         if (addBrand?.status === 200) {
        //             setBrand({ name: '' })
        //             brandAvailable()
        //             handleCloseModal()
        //             toast.success(`Brand successfully created`);
        //         } else {
        //             console.log(addBrand, "error after submit")
        //             toast.error(`${addBrand.message}`);
        //             setBrand({ name: '' })
        //             handleCloseModal()
        //         }
        //     }
        // } catch (e) {
        //     console.log('error ==>', e)
        //     if (e?.response?.status === 400) {
        //         setBrand({ name: '' })
        //         handleCloseModal()
        //         toast.error(`${e?.response?.data?.message}`);
        //     }
        // }
    }

    // const permanentDeleteBrand = async () => {
    //     const requestOptions = {
    //         headers: {
    //             'Content-Type': 'application/json',
    //             Authorization: `Bearer ${token}`,
    //         },
    //     };
    //     const permanentBrandDel = await axios.delete(`${baseURL}/admin/deletebrand?brand_id=${deleteId}`, requestOptions)
    //     if (permanentBrandDel?.status === 200) {
    //         brandAvailable()
    //         closeModal()
    //         toast.success(`Brand successfully deleted`);
    //     } else {
    //         console.log('error ==>', permanentBrandDel)
    //     }
    // }

    return (
        <div>
            <div className="sidebar">
                <SideBar type='Details' />
            </div>
            <div className="content">
                <div className="container">
                    <FirstNavbar />
                    <Card className='p-2'>
                        <Row>
                            <Col>
                                <h3 className='headertext'>Manage Brand:</h3>
                            </Col>
                            <Col md={3}>
                                {/* <Button variant="warning" onClick={handleShowModal}>Add Brand</Button> */}
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col>
                                <h3 className='headertext'>Brands Available:</h3>
                            </Col>
                        </Row>
                        <div
                            className="ag-theme-alpine"
                            style={{ height: "70vh", width: "100%" }}
                        >
                            <AgGridReact
                                rowHeight={rowHeight}
                                columnDefs={colDefs}
                                defaultColDef={DefaultColumnSetting}
                                pagination={true}
                                paginationPageSize={10}
                                rowData={rowData}
                            />
                        </div>
                    </Card>

                    {/* Add Brand Modal */}
                    <Modal
                        show={brandModal}
                        onHide={handleCloseModal}
                        backdrop="static"
                        keyboard={false}
                        centered
                    >
                        <h4 className='headertext text-center'>Add Brand Details:</h4>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Brand Name</Form.Label>
                                        <Form.Control
                                            maxLength={50}
                                            type="text"
                                            placeholder="Enter brand name"
                                            size="sm"
                                            name='name'
                                            onChange={(e) => handleChange(e)}
                                            autoComplete='off'
                                            className='mb-3'
                                            value={brand?.name}
                                        />
                                        <span className="text-danger">{brandErrors?.name}</span>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={handleCloseModal} variant="secondary">
                                Cancel
                            </Button>
                            <Button variant="warning" onClick={() => handleSubmit()}>
                                Submit
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    {/* Delete Confirmation Modal */}
                    <Modal show={showModal} onHide={closeModal}>
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>
                            <h3>Are you sure you want to delete this brand?</h3>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={closeModal}>
                                Cancel
                            </Button>
                            {/* <Button variant="danger" onClick={permanentDeleteBrand}>
                                Delete
                            </Button> */}
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        </div>
    )
}

export default BrandEndorsementList