
import React, { useEffect, useState } from 'react'
import SideBar from '../../dashboard/SideBar'
import FirstNavbar from '../../dashboard/FirstNavbar'
import { Card, Form, Row, Col } from 'react-bootstrap'
import VendorProfile from './VendorProfile'
import ProductList from './Product/ProductList'
import { useLocation } from "react-router-dom";
import baseURL from '../authServices/Url'
import axios from 'axios'
import OfferList from './Offer/OfferList'
import OrderList from './Order/OrderList'
import Metal from './Metal/Metal'
import GemstoneList from './Gemstone/GemstoneList'
import ProductCategoryList from './ProductCategory/ProductCategoryList'
import VendorShopList from './VendorShop/VendorShopList'
import BankDetails from './VendorBank/BankDetails'

function VendorData() {
    const { state } = useLocation();
    const vendorsDataaa = state
    const [selection, setSelection] = useState(1)
    const [vendor, setVendor] = useState()
    let token = localStorage.getItem('accessToken');
    const onDataSelect = (e) => {
        // console.log('eeeee', e.target.value, e)
        setSelection(e.target.value)
    }
    const goldVendorRequest = async () => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        const getVendor = await axios.get(`${baseURL}/admin/readGoldVendor?limit=10&page_no=1`, requestOptions)
        console.log('getVendor@@@@@@@@@@@@@@@@@@@', getVendor)
        if (getVendor?.status === 200) {
            setVendor(getVendor?.data?.data)
        } else {
            console.log('error')
        }
    }

    useEffect(() => {
        // gemStoneAvailable();
        goldVendorRequest();
    }, [])

    return (
        <div>
            <div class="sidebar">
                <SideBar type='Details' />
            </div>
            <div class="content">
                <div className="container">
                    <FirstNavbar/>

                        <h3 className='headertext'>Vendor Data</h3>
                        <div >
                            <Card className="shadow rounded p-2 w-100">

                                <Row>
                                    <Col md={3}>
                                        <div className="">
                                            <Form.Select
                                                aria-label="Default select example"
                                                size="sm"
                                                onChange={onDataSelect}
                                            >
                                                <option value="1">VendorProfile</option>
                                                <option value="2">Store</option>
                                                <option value="3">Metal</option>
                                                <option value="4">Gemstone</option>
                                                <option value="5">Product Category</option>
                                                <option value="6">Product Offer</option>
                                                <option value="7">Jewellery/Product</option>
                                                <option value="8">Order</option>
                                                <option value="9">Bank Details</option>
                                            </Form.Select>

                                        </div>
                                    </Col>
                                </Row>
                                <hr />
                                {selection == 1 && <VendorProfile vendor={vendorsDataaa} />}
                                {selection == 2 && <VendorShopList vendor={vendorsDataaa} />}
                                {selection == 3 && <Metal vendor={vendorsDataaa} />}
                                {selection == 4 && <GemstoneList vendor={vendorsDataaa} />}
                                {selection == 5 && <ProductCategoryList vendor={vendorsDataaa} />}
                                {selection == 6 && <OfferList vendor={vendorsDataaa} />}
                                {selection == 7 && <ProductList vendor={vendorsDataaa} />}
                                {selection == 8 && <OrderList vendor={vendorsDataaa} />}
                                {selection == 9 && <BankDetails vendor={vendorsDataaa} />}
                            </Card>
                        </div>

                    </div>
               
            </div>
</div>
            )
}

            export default VendorData