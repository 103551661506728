import React, { useState, useEffect } from 'react';
import { Button, Modal, Card, Row, Col, Form } from 'react-bootstrap';
import SideBar from '../../../dashboard/SideBar'
import FirstNavbar from '../../../dashboard/FirstNavbar'
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import baseURL from '../../authServices/Url';
import { toast } from "react-toastify";
import Multiselect from 'multiselect-react-dropdown';

function OfferList(props) {
    const navigate = useNavigate();
    const vendorsDataaa = JSON.parse(localStorage.getItem('vendorDetails'));

    let token = localStorage.getItem('accessToken');
    const [deleteId, setDeleteId] = useState('')
    const [showModal, setShowModal] = useState(false);
    const [offerModal, setofferModal] = useState(false)
    const [offer, setoffer] = useState({
        name: '',
        code: '',
        discountType: '',
        discountValue: 0,
        minimumPurchaseAmount: 0,
        maximumDiscountValue: 0,
        offertoVendor: '',
        offerQuantity: 0,
        startDate: '',
        closeDate: '',
        description: '',
    })
    const [offerErrors, setofferErrors] = React.useState({
        name: '',
        code: '',
        discountType: '',
        discountValue: '',
        minimumPurchaseAmount: '',
        maximumDiscountValue: '',
        offertoVendor: '',
        offerQuantity: '',
        startDate: '',
        closeDate: '',
        description: '',
        offerType: '',
    })
    const [discountType, setDiscountType] = useState('')
    const [vendorData, setVendorData] = useState('')
    const [offerType, setofferType] = useState('')

    const handleCloseModal = () => { setofferModal(false) }

    const handleShowModal = () => {
        // console.log('prod', prod);
        setofferModal(true)
    }

    const openModal = (id) => {
        setShowModal(true);
        setDeleteId(id)
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const [rowData, setRowData] = useState([]);

    const rowHeight = 100;
    const DefaultColumnSetting = {
        sortable: true,
        flex: 1,
        resizable: true,
        minWidth: 120,
    };

    const [colDefs, setColDefs] = useState([
        {
            headerName: "#",
            valueGetter: "node.rowIndex + 1",
            lockPosition: true,
        },
        {
            headerName: "offer Name",
            field: "offer_name",
            filter: true,

        },
        {
            headerName: "offer Type",
            filter: true,
            field: "offer_type",

        },
        {
            headerName: "Start Date",
            field: 'offer_start_date',
        },
        {
            headerName: "End Date",
            field: 'offer_end_date',
        },
        {
            headerName: "View",
            field: "view",
            cellRendererFramework: (params) => (
                <center>
                    <FontAwesomeIcon icon={faEye} style={{ color: '#BE783B' }} onClick={() => { navigate('/offerdata', { state: params.data }) }}> </FontAwesomeIcon>{" "}
                </center>
            ),
        },
        // {
        //     headerName: "Delete",
        //     cellRendererFramework: (params) => (
        //         <center>
        //             <FontAwesomeIcon icon={faTrash} style={{ color: '#f00' }} onClick={() => openModal(params?.data?.id)}> </FontAwesomeIcon>{" "}
        //         </center>
        //     ),
        // },
        {
            headerName:"Enable/Disable",
            field: "is_active",
            cellRendererFramework: (params) =>(
                <center>
                    <Button variant={params.data.is_active ? "success" : "danger"}
                    onClick={() => toggleOfferListStatus(params.data.id, params.data.is_active)}
                    >
                        {params.data.is_active ? "Enable" : "Disable"}
                    </Button>
                </center>

            ),
         }
    ])

    const offersAvailable = async () => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        const getoffer = await axios.get(`${baseURL}/admin/readProductOffer?gold_vendor_id=${vendorsDataaa?.id}`, requestOptions)
        console.log('getoffer@@@@@@@@@@@@@@@@@@@', getoffer)
        if (getoffer?.status === 200) {
            setRowData(getoffer?.data?.data)
        } else {
            console.log('error')
        }
    }

    // const goldVendorRequest = async () => {
    //     const requestOptions = {
    //         headers: {
    //             'Accept': 'application/json, text/plain, */*',
    //             'Content-Type': 'application/json',
    //             Authorization: `Bearer ${token}`,
    //         },
    //     };
    //     const getVendor = await axios.get(`${baseURL}/admin/readGoldVendor?limit=10&page_no=1`, requestOptions)
    //     console.log('getVendor@@@@@@@@@@@@@@@@@@@', getVendor)
    //     if (getVendor?.status === 200) {
    //         setVendorData(getVendor?.data?.data)
    //     } else {
    //         console.log('error')
    //     }
    // }

    useEffect(() => {
        offersAvailable();
        // goldVendorRequest();
    }, [])

    const permanentDeleteoffer = async () => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        const permanentofferDel = await axios.delete(`${baseURL}/admin/deleteProductOffer?offer_id=${deleteId}`, requestOptions)
        console.log('permanentofferDel', permanentofferDel)
        if (permanentofferDel?.status === 200) {
            offersAvailable()
            closeModal()
            toast.success(`offer succesfully deleted`);
        } else {
            console.log('error ==>', permanentofferDel)
        }
    }

    const inActivateoffer = async () => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        const offerinactive = await axios.delete(`${baseURL}/admin/softDeleteGoldoffer?gold_offer_id=${deleteId}`, requestOptions)
        console.log('offerinactive', offerinactive)
        if (offerinactive?.status === 200) {
            offersAvailable()
        } else {
            console.log('error ==>', offerinactive)
        }
    }

    const [selectedVendor, setSelectedVendor] = useState([]);
    const [selectedVendor1, setSelectedVendor1] = useState([]);

    const onSelectVendor = (selectedList, selectedItem) => {
        const selectedVendorIds = selectedList?.map((item) => item?.id);
        setSelectedVendor(selectedVendorIds);
        setSelectedVendor1(selectedList)
    };

    const onRemoveVendor = (selectedList, removedItem) => {
        const updatedSelectedVendorIds = selectedList?.map((item) => item?.id);
        setSelectedVendor(updatedSelectedVendorIds);
        setSelectedVendor1(selectedList)
    };

    const handleDiscountTypeChange = (e) => {
        setDiscountType(e.target.value)
    }
    const handleofferTypeChange = (e) => {
        setofferType(e.target.value)
    }
    const handleChange = (e) => {
        setoffer({
            ...offer,
            [e?.target?.name]: e?.target?.value,
            [e.target.description]: e?.target?.value,
            [e.target.code]: e?.target?.value,
            [e.target.startDate]: e?.target?.value,
            [e.target.closeDate]: e?.target?.value,
            [e.target.discountType]: e?.target?.value,
            [e.target.discountValue]: e?.target?.value,
            [e.target.minimumPurchaseAmount]: e?.target?.value,
            [e.target.maximumDiscountValue]: e?.target?.value,
            [e.target.offertoVendor]: e?.target?.value,
            [e.target.offerQuantity]: e?.target?.value,
        });

        setofferErrors({
            ...offerErrors,
            [e.target.name]: null,
            [e.target.description]: null,
            [e.target.code]: null,
            [e.target.startDate]: null,
            [e.target.closeDate]: null,
            [e.target.discountType]: null,
            [e.target.discountValue]: null,
            [e.target.minimumPurchaseAmount]: null,
            [e.target.maximumDiscountValue]: null,
            [e.target.offertoVendor]: null,
            [e.target.offerQuantity]: null,
        });
    };

    const handleValidation = () => {
        const { description, name, code, startDate, closeDate, discountValue, minimumPurchaseAmount, maximumDiscountValue, offerQuantity } = offer

        const newErrors = {}

        if (!name) {
            newErrors.name = 'please enter offer name'
        }
        if (!code) {
            newErrors.code = 'please enter offer code'
        }
        if (!description) {
            newErrors.description = 'please enter description'
        }
        if (!startDate) {
            newErrors.startDate = 'please select start date'
        }
        if (!closeDate) {
            newErrors.closeDate = 'please select close date'
        }
        if (!discountType) {
            newErrors.discountType = 'please enter discount type'
        }
        if (!discountValue) {
            newErrors.discountValue = 'please enter discount value'
        }
        if (!minimumPurchaseAmount) {
            newErrors.minimumPurchaseAmount = 'please enter minimum purchase amount'
        }
        if (!maximumDiscountValue) {
            newErrors.maximumDiscountValue = 'please enter maximum discount value'
        }
        if (!selectedVendor) {
            newErrors.offertoVendor = 'please offer to vendor'
        }
        if (!offerType) {
            newErrors.offerType = 'please offer to vendor'
        }
        if (!offerQuantity) {
            newErrors.offerQuantity = 'please enter offer quantity'
        }

        return newErrors
    }

    const handleSubmit = async () => {
        try {
            const handleValidationObject = handleValidation()

            if (Object.keys(handleValidationObject).length > 0) {
                setofferErrors(handleValidationObject)
            } else {

                const requestOptions = {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                };
                const userCredentials = {
                    "offer_name": offer?.name,
                    "offer_description": offer?.description,
                    "offer_code": offer?.code,
                    "offer_discount_type": discountType,
                    "offer_discount_value": Number(offer?.discountValue),
                    "offer_minimum_purchase_amount": Number(offer?.minimumPurchaseAmount),
                    "offer_maximum_discount_value": Number(offer?.maximumDiscountValue),
                    "offer_to_vendor": offerType,
                    "offer_quantity": Number(offer?.offerQuantity),
                    "offer_start_date": offer?.startDate,
                    "offer_end_date": offer?.closeDate
                }
                if (offerType === 'PARTICULAR') {
                    userCredentials["offer_to_vendor_id"] = selectedVendor;
                }

                const physicalGold = await axios.post(`${baseURL}/productoffer`, userCredentials, requestOptions)
                if (physicalGold?.status === 200) {
                    setoffer({
                        ...offer,
                        name: '',
                        code: '',
                        discountType: '',
                        discountValue: 0,
                        minimumPurchaseAmount: 0,
                        maximumDiscountValue: 0,
                        offertoVendor: '',
                        offerQuantity: 0,
                        startDate: '',
                        closeDate: '',
                        description: '',
                    })
                    offersAvailable()
                    handleCloseModal()
                    toast.success(`offer succesfully created`);

                } else {
                    console.log(physicalGold, "error after submit")
                    toast.show({
                        type: 'error',
                        text1: `${physicalGold.message}`,
                    })
                    setoffer({
                        ...offer,
                        name: '',
                        code: '',
                        discountType: '',
                        discountValue: 0,
                        minimumPurchaseAmount: 0,
                        maximumDiscountValue: 0,
                        offertoVendor: '',
                        offerQuantity: 0,
                        startDate: '',
                        closeDate: '',
                        description: '',
                    })
                    handleCloseModal()
                }
            }
        } catch (e) {
            console.log('error ==>', e)
            if (e?.response?.status === 400) {
                setoffer({
                    ...offer,
                    name: '',
                    code: '',
                    discountType: '',
                    discountValue: 0,
                    minimumPurchaseAmount: 0,
                    maximumDiscountValue: 0,
                    offertoVendor: '',
                    offerQuantity: 0,
                    startDate: '',
                    closeDate: '',
                    description: '',
                })
                handleCloseModal()
                toast.error(`${e?.response?.data?.message}`);
            }
        }
    }

    const toggleOfferListStatus = async(offerId, isActive) => {
         const requestOptions = {
            headers: {
                'Accept':'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
         };
         const response = await axios.patch(`${baseURL}/admin/updateOfferListStatus`, {
            offer_id: offerId,
            is_active: !isActive
         }, requestOptions);
         if(response?.status === 200) {
             toast.success("offer Updated Successfull")
            offersAvailable();
            } else {
            console.log('error ===>', response);
         }
    }

    return (
        <div>
            <div class="sidebar">
                <SideBar type='Details' />
            </div>
            <div class="content">
                <div className="container">
                    <FirstNavbar />
                    <h3 className='headertext'>Offer List:</h3>
                    <div
                        className="ag-theme-alpine"
                        style={{ height: "70vh", width: "100%" }}
                    >

                        <AgGridReact
                            rowHeight={rowHeight}
                            // columnDefs={columns}
                            columnDefs={colDefs}
                            defaultColDef={DefaultColumnSetting}
                            pagination={true}
                            paginationPageSize={10}
                            // onGridReady={onGridReady}
                            rowData={rowData}
                        // onRowClicked={(e) => 
                        //     navigate('/vendorData',{id:e.data._id})}
                        />
                    </div>
                </div>
            </div>
            <Modal
                show={offerModal}
                onHide={handleCloseModal}
                backdrop="static"
                keyboard={false}
                centered
                size="lg"
            >
                <h4 className='headertext text-center'>Add/Edit offer Details:</h4>
                <Modal.Body>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>offer Name</Form.Label>
                                <Form.Control
                                    maxLength={50}
                                    type="text"
                                    placeholder="Enter offer name"
                                    size="sm"
                                    name='name'
                                    onChange={(e) => handleChange(e)}
                                    autoComplete='off'
                                    className='mb-3'
                                    value={offer?.name}
                                />
                                <span className="text-danger">{offerErrors?.name}</span>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Code</Form.Label>
                                <Form.Control
                                    maxLength={50}
                                    type="text"
                                    placeholder="Enter offer Code"
                                    size="sm"
                                    name='code'
                                    onChange={(e) => handleChange(e)}
                                    autoComplete='off'
                                    className='mb-3'
                                    value={offer?.code}
                                />
                                <span className="text-danger">{offerErrors?.priceUnit}</span>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Discount Type</Form.Label>
                                <div className="leftedge d-flex justify-content-space">
                                    <Form.Select
                                        aria-label="select Metal"
                                        size={"sm"}
                                        className="selectsizesmall"
                                        name="discountType"
                                        onChange={(e) => handleDiscountTypeChange(e)}
                                        value={discountType}
                                    >
                                        <option >Select Discount Type</option>
                                        <option name='PERCENTAGE' value='PERCENTAGE'>Percentage</option>
                                        <option name='FIXED_AMOUNT' value='FIXED_AMOUNT'>Fixed Amount</option>
                                    </Form.Select>
                                </div>
                                <span className="text-danger">{offerErrors?.discountType}</span>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Discount Value</Form.Label>
                                <Form.Control
                                    maxLength={50}
                                    type="number"
                                    placeholder="Enter Discount Value"
                                    size="sm"
                                    name='discountValue'
                                    onChange={(e) => handleChange(e)}
                                    autoComplete='off'
                                    className='mb-3'
                                    value={offer?.discountValue}
                                />
                                <span className="text-danger">{offerErrors?.discountValue}</span>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Maximum Discount Value</Form.Label>
                                <Form.Control
                                    maxLength={50}
                                    type="number"
                                    placeholder="Enter Maximum Discount Value"
                                    size="sm"
                                    name='maximumDiscountValue'
                                    onChange={(e) => handleChange(e)}
                                    autoComplete='off'
                                    className='mb-3'
                                    value={offer?.maximumDiscountValue}
                                />
                                <span className="text-danger">{offerErrors?.maximumDiscountValue}</span>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Minimum Purchase Amount</Form.Label>
                                <Form.Control
                                    maxLength={50}
                                    type="number"
                                    placeholder="Enter Minimum Purchase Amount"
                                    size="sm"
                                    name='minimumPurchaseAmount'
                                    onChange={(e) => handleChange(e)}
                                    autoComplete='off'
                                    className='mb-3'
                                    value={offer?.minimumPurchaseAmount}
                                />
                                <span className="text-danger">{offerErrors?.minimumPurchaseAmount}</span>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>offer to Vendor</Form.Label>
                                <div className="leftedge d-flex justify-content-space">
                                    <Form.Select
                                        aria-label="select Metal"
                                        size={"sm"}
                                        className="selectsizesmall"
                                        name="offerType"
                                        onChange={(e) => handleofferTypeChange(e)}
                                        value={offerType}
                                    >
                                        <option >Select offer Applies to</option>
                                        <option name='ALL' value='ALL'>All</option>
                                        <option name='PARTICULAR' value='PARTICULAR'>Particular</option>
                                    </Form.Select>
                                </div>
                                <span className="text-danger">{offerErrors?.offertoVendor}</span>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>offer Quantity</Form.Label>
                                <Form.Control
                                    maxLength={50}
                                    type="number"
                                    placeholder="Enter offer Quantity"
                                    size="sm"
                                    name='offerQuantity'
                                    onChange={(e) => handleChange(e)}
                                    autoComplete='off'
                                    className='mb-3'
                                    value={offer?.offerQuantity}
                                />
                                <span className="text-danger">{offerErrors?.offerQuantity}</span>
                            </Form.Group>
                        </Col>
                    </Row>
                    {offerType === 'PARTICULAR' &&
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>offer to Vendors</Form.Label>
                                    <div className="leftedge d-flex justify-content-space">
                                        <Multiselect
                                            options={vendorData}
                                            displayValue="gold_vendor_name"
                                            onSelect={onSelectVendor}
                                            onRemove={onRemoveVendor}
                                            selectedValues={selectedVendor1}
                                            placeholder="Select Vendor"
                                            value={selectedVendor}
                                        />
                                    </div>
                                    <span className="text-danger">{offerErrors?.offertoVendor}</span>
                                </Form.Group>
                            </Col>
                        </Row>}
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>offer Start Date</Form.Label>
                                <Form.Control
                                    maxLength={50}
                                    type="date"
                                    placeholder="Enter Start Date"
                                    size="sm"
                                    name='startDate'
                                    onChange={(e) => handleChange(e)}
                                    autoComplete='off'
                                    className='mb-3'
                                    value={offer?.startDate}
                                />
                                <span className="text-danger">{offerErrors?.startDate}</span>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>offer Close Date</Form.Label>
                                <Form.Control
                                    maxLength={50}
                                    type="date"
                                    placeholder="Enter Close Date"
                                    size="sm"
                                    name='closeDate'
                                    onChange={(e) => handleChange(e)}
                                    autoComplete='off'
                                    className='mb-3'
                                    value={offer?.closeDate}
                                />
                                <span className="text-danger">{offerErrors?.closeDate}</span>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    as="textarea" rows={3}
                                    placeholder="Enter description"
                                    size="md"
                                    name='description'
                                    onChange={(e) => handleChange(e)}
                                    autoComplete='off'
                                    className='mb-3'
                                    value={offer?.description}
                                />
                                <span className="text-danger">{offerErrors?.description}</span>
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseModal} variant="secondary">
                        Cancel
                    </Button>
                    <Button variant="warning"
                        onClick={() => handleSubmit()}
                    // onClick={() => { selectedProduct?.id ? handleSubmitEdit() : handleSubmit() }}
                    >
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* delete modal */}
            <Modal show={showModal} onHide={closeModal}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <h3>Are you sure, you want to delete this offer ....?</h3>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        Cancel
                    </Button>
                    {/* <Button variant="warning" onClick={inActivateoffer}>
                                inActivate
                            </Button> */}
                    <Button variant="danger" onClick={permanentDeleteoffer}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default OfferList