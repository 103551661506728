import React, { useState, useEffect } from 'react'
import { useLocation } from "react-router-dom";
import {
  Card,
  Row,
  Col,
  Figure,
  Table,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import SideBar from "../../../dashboard/SideBar";
import FirstNavbar from "../../../dashboard/FirstNavbar";
import axios from 'axios';
import baseURL from '../../authServices/Url';
import { toast } from "react-toastify";


function ProductOffer() {
  const { state } = useLocation();
  const [offerData, setofferData] = useState(state)
  let token = localStorage.getItem('accessToken');
  console.log('offerData', offerData, state)

  const [offer, setoffer] = useState({
    name: '',
    type: '',
    description: '',
    startDate: '',
    endDate: '',
    termsConditions: [],
  })
  const [offerErrors, setofferErrors] = React.useState({
    name: '',
    type: '',
    description: '',
    startDate: '',
    endDate: '',
    termsConditions: '',

  })
  const [offerModal, setofferModal] = useState(false)

  const handleCloseModal = () => setofferModal(false)
  const handleShowModal = () => {
    // console.log('offer', offer);
    setofferModal(true)
    setoffer({
      name: offerData?.offer_name,
      type: offerData?.offer_type,
      description: offerData?.offer_description,
      startDate: offerData?.offer_start_date,
      endDate: offerData?.offer_end_date,
      termsConditions: offerData?.offer_terms_and_conditions,

    })

  }
  const offersAvailable = async () => {
    const requestOptions = {
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    const getoffer = await axios.get(`${baseURL}/admin/readProductOffer?offer_id=${offerData?.id}`, requestOptions)
    console.log('getoffer@@@@@@@@@@@@@@@@@@@', getoffer)
    if (getoffer?.status === 200) {
      setofferData(getoffer?.data?.data[0])
    } else {
      console.log('error')
    }
  }

  useEffect(() => {
    offersAvailable()
  }, [])

  const handleChangeTerms = (e) => {
    console.log('handleChangeTerms handleChangeTerms', e.target.value)
    const { value } = e.target;
    setoffer({
      ...offer, termsConditions: value.split('\n')
    });
  }

  const handleChange = (e) => {
    setoffer({
      ...offer,
      [e?.target?.name]: e?.target?.value,
      [e.target.type]: e.target.value,
      [e.target.description]: e.target.value,
      [e.target.startDate]: e.target.value,
      [e.target.endDate]: e.target.value,
      [e.target.termsConditions]: e.target.value.split('\n'),
    });

    setofferErrors({
      ...offerErrors,
      [e.target.name]: null,
      [e.target.description]: null,
      [e.target.type]: null,
      [e.target.startDate]: null,
      [e.target.endDate]: null,
      [e.target.termsConditions]: null,
    });
  };

  const handleValidation = () => {
    const { name, type, description, endDate, startDate, termsConditions } = offer
    const newErrors = {}

    if (!name) {
      newErrors.name = 'please enter name'
    }
    if (!type) {
      newErrors.type = 'please upload type'
    }
    if (!description) {
      newErrors.description = 'please enter description'
    }
    if (!startDate) {
      newErrors.startDate = 'please select start Date'
    }
    if (!endDate) {
      newErrors.endDate = 'please select End Date'
    }
    if (!termsConditions) {
      newErrors.termsConditions = 'please enter Terms & Conditions'
    }

    return newErrors
  }

  const handleSubmitEdit = async () => {
    const handleValidationObject = handleValidation()
    if (Object.keys(handleValidationObject).length > 0) {
      setofferErrors(handleValidationObject)
    } else {

      const requestOptions = {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };
      const userCredentials = JSON.stringify({
        "offer_name": offer?.name,
        "offer_description": offer?.description,
        "offer_type": offer?.type,
        "offer_start_date": offer?.startDate,
        "offer_end_date": offer?.endDate,
        "offer_terms_and_conditions": offer?.termsConditions,
        "offer_id": offerData?.id,
        "gold_vendor_id": offerData?.gold_vendor_id,
      })
      const editOffer = await axios.patch(`${baseURL}/admin/updateProductOffer`, userCredentials, requestOptions)
      console.log('editOffer editOffer', editOffer);
      if (editOffer?.status === 200) {
        toast.success(`offer edited succesfully `);
        offersAvailable()
        setoffer({
          ...offer,
          name: '',
          type: '',
          description: '',
          startDate: '',
          endDate: '',
          termsConditions: '',

        })
        handleCloseModal()

      } else {
        console.log(editOffer, "error after submit")
        offersAvailable()
        toast.show({
          type: 'error',
          text1: `${editOffer.message}`,
        })
        handleCloseModal()
      }


    }
  }
  const termsConditionsString = Array.isArray(offer?.termsConditions)
    ? offer?.termsConditions.join('\n')
    : '';

  return (
    <div>
      <div class="sidebar">
        <SideBar type='Details'/>
      </div>
      <div class="content">
        <div className="container">
          <FirstNavbar />
          <h3 className='headertext'>offer Data</h3>
          <div>
            <Card className="p-2">
              <Row>
                <Col md={6}>
                  <h3 className="headertext text1">offer Deatils:</h3>
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={4}>
                  <h5>offer Name</h5>
                </Col>
                <Col md={1}>:</Col>
                <Col md={6}>
                  <h5>{offerData?.offer_name}</h5>
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={4}>
                  <h5>offer Type</h5>
                </Col>
                <Col md={1}>:</Col>
                <Col md={6}>
                  <h5>{offerData?.offer_type}</h5>
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={4}>
                  <h5>Start Date</h5>
                </Col>
                <Col md={1}>:</Col>
                <Col md={6}>
                  <h5>{offerData?.offer_start_date}</h5>
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={4}>
                  <h5>End Date</h5>
                </Col>
                <Col md={1}>:</Col>
                <Col md={6}>
                  <h5>{offerData?.offer_end_date}</h5>
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={4}>
                  <h5>Terms and Conditions</h5>
                </Col>
                <Col md={1}>:</Col>
                <Col md={6}>
                  {offerData?.offer_terms_and_conditions?.map((term, i) => {
                    return (
                      <h5>{term}</h5>
                    )
                  })}
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={4}>
                  <h5>description</h5>
                </Col>
                <Col md={1}>:</Col>
                <Col md={6}>

                  <h5>{offerData?.offer_description}</h5>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md={3}>
                  <Button variant="warning"
                    onClick={handleShowModal}
                  >
                    Edit offer
                  </Button>
                </Col>

              </Row>
            </Card>
            <Modal
              show={offerModal}
              onHide={handleCloseModal}
              backdrop="static"
              keyboard={false}
              centered
              size="lg"
            >
              <h4 className='headertext text-center'>Edit Product Offers Details:</h4>
              <Modal.Body>

                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Offer Name</Form.Label>
                      <Form.Control
                        maxLength={50}
                        type="text"
                        placeholder="Enter Offer Name"
                        size="sm"
                        name='name'
                        onChange={handleChange}
                        autoComplete='off'
                        className='mb-3'
                        // pattern="/^[a-zA-Z]*$/"
                        required
                        value={offer?.name}
                      />
                      <span className="text-danger">{offerErrors?.name}</span>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Offer type</Form.Label>
                      <Form.Control
                        maxLength={50}
                        type="text"
                        placeholder="Enter Offer type"
                        size="sm"
                        name='type'
                        onChange={handleChange}
                        autoComplete='off'
                        className='mb-3'
                        value={offer?.type}
                      />
                      <span className="text-danger">{offerErrors?.type}</span>
                    </Form.Group>


                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Start Date</Form.Label>
                      <Form.Control
                        type="date"
                        name="startDate"
                        value={offer?.startDate}
                        onChange={handleChange}
                      />
                      <span className="text-danger">{offerErrors?.name}</span>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>End Date</Form.Label>
                      <Form.Control
                        type="date"
                        name="endDate"
                        value={offer?.endDate}
                        onChange={handleChange}
                      />
                      <span className="text-danger">{offerErrors?.type}</span>
                    </Form.Group>


                  </Col>
                </Row>
                <Row>
                  <Col lg >
                    <Form.Group className="mb-3">
                      <Form.Label>Terms and conditions</Form.Label>
                      <Form.Control

                        as="textarea" rows={3}
                        placeholder="Enter every new Terms and conditions in a new line"
                        size="md"
                        name='termsConditions'
                        onChange={handleChangeTerms}
                        autoComplete='off'
                        className='mb-3'
                        value={termsConditionsString}
                        style={{ whiteSpace: 'pre-line' }}
                      // value={offer?.termsConditions.join('\n')}
                      />
                      <span className="text-danger">{offerErrors?.termsConditions}</span>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        // maxLength={50}
                        // type="text"
                        as="textarea" rows={3}
                        placeholder="Enter description"
                        size="md"
                        name='description'
                        onChange={handleChange}
                        autoComplete='off'
                        className='mb-3'
                        value={offer?.description}
                      />
                      <span className="text-danger">{offerErrors?.description}</span>
                    </Form.Group>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={handleCloseModal} variant="secondary">
                  Cancel
                </Button>
                <Button variant="warning" onClick={() => handleSubmitEdit()}>
                  Submit
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductOffer