import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router-dom";
import baseURL from '../../authServices/Url';
import { Button, Modal } from 'react-bootstrap'; // Import Modal component
import FirstNavbar from '../../../dashboard/FirstNavbar';
import SideBar from '../../../dashboard/SideBar';
import { useLocation } from "react-router-dom";

function VendorShopList(props) {
    const navigate = useNavigate();
    const { state } = useLocation();
    const vendorsDataaa = JSON.parse(localStorage.getItem('vendorDetails'));

    let token = localStorage.getItem('accessToken');
    const [deleteId, setDeleteId] = useState('');
    const [showModal, setShowModal] = useState(false);

    const openModal = (id) => {
        setShowModal(true);
        setDeleteId(id);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const [rowData, setRowData] = useState([]);
    const rowHeight = 100;
    const DefaultColumnSetting = {
        sortable: true,
        flex: 1,
        resizable: true,
        minWidth: 120,
    };

    const [colDefs, setColDefs] = useState([
        {
            headerName: "#",
            valueGetter: "node.rowIndex + 1",
            lockPosition: true,
        },
        {
            headerName: "Shop Name",
            field: "store_name",
            filter: true,
        },
        {
            headerName: "Phone Number",
            field: "store_phone_no",
        },
        {
            headerName: "Email",
            field: "store_email",
        },
        {
            headerName: "Open Time",
            field: "store_open_time",
        },
        {
            headerName: "Close Time",
            field: 'store_close_time',
        },
        {
            headerName: "Working Days",
            field: 'store_week_days',
        },
        {
            headerName: "Enable/Disable",
            field: "is_active",
            cellRendererFramework: (params) => (
                <center>
                    <Button
                        variant={params.data.is_active ? "success" : "danger"}
                        onClick={() => toggleShopStatus(params.data.id, params.data.is_active)}
                    >
                        {params.data.is_active ? "Enable" : "Disable"}
                    </Button>
                </center>
            ),
        },
        {
            headerName: "View",
            field: "view",
            cellRendererFramework: (params) => (
                <center>
                    <FontAwesomeIcon icon={faEye} style={{ color: '#BE783B' }} onClick={() => { navigate('/VendorShop', { state: params.data }) }}> </FontAwesomeIcon>{" "}
                </center>
            ),
        },
        // {
        //     headerName: "Delete",
        //     cellRendererFramework: (params) => (
        //         <center>
        //             <FontAwesomeIcon icon={faTrash} style={{ color: '#f00' }} onClick={() => openModal(params?.data?.id)}> </FontAwesomeIcon>
        //         </center>
        //     ),
        // },
    ]);

    const shopAvailable = async () => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        const getshop = await axios.get(`${baseURL}/admin/readGoldVendorStore?gold_vendor_id=${vendorsDataaa?.id}`, requestOptions);
        if (getshop?.status === 200) {
            setRowData(getshop?.data?.data);
        } else {
            console.log('error');
        }
    };

    const toggleShopStatus = async (shopId, isActive) => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        const response = await axios.patch(`${baseURL}/admin/updateGoldVendorStore`, {
            store_id: shopId,
            is_active: !isActive
        }, requestOptions);
        if (response?.status === 200) {
            shopAvailable();
        } else {
            console.log('error ==>', response);
        }
    };

    useEffect(() => {
        shopAvailable();
    }, []);

    const permanentDeleteshop = async () => {
        const requestOptions = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        const permanentshopDel = await axios.delete(`${baseURL}/admin/deleteGoldVendorStore?store_id=${deleteId}`, requestOptions);
        if (permanentshopDel?.status === 200) {
            shopAvailable();
            closeModal();
        } else {
            console.log('error ==>', permanentshopDel);
        }
    };

    const inActivateshop = async () => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        const shopinactive = await axios.delete(`${baseURL}/admin/softDeleteshop?shop_id=${deleteId}`, requestOptions);
        if (shopinactive?.status === 200) {
            shopAvailable();
        } else {
            console.log('error ==>', shopinactive);
        }
    };

    return (
        <div>
            <div className="sidebar">
                <SideBar type='Details' />
            </div>
            <div className="content">
                <div className="container">
                    <FirstNavbar />
                    <h3 className='headertext'>Shop List</h3>
                    <div
                        className="ag-theme-alpine"
                        style={{ height: "70vh", width: "100%" }}
                    >
                        <AgGridReact
                            rowHeight={rowHeight}
                            columnDefs={colDefs}
                            defaultColDef={DefaultColumnSetting}
                            pagination={true}
                            paginationPageSize={10}
                            rowData={rowData}
                        />
                    </div>
                </div>
            </div>
            <Modal
                show={showModal}
                onHide={closeModal}>
                <Modal.Body closeButton>
                    <h3>Are you sure, you want to delete this shop ...?</h3>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        Cancel
                    </Button>
                    {/* <Button variant="warning" onClick={inActivateshop}>
                        inActivate
                    </Button> */}
                    <Button variant="danger" onClick={permanentDeleteshop}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default VendorShopList;
