import React, { useState, useEffect } from 'react';
import { Button, Modal, Card, Row, Col, Form } from 'react-bootstrap';
import SideBar from '../dashboard/SideBar'
import FirstNavbar from '../dashboard/FirstNavbar'
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import baseURL from '../adminPanel/authServices/Url';
import { toast } from "react-toastify";
import Multiselect from 'multiselect-react-dropdown';


function CouponsList() {
    let token = localStorage.getItem('accessToken');
    const [deleteId, setDeleteId] = useState('')
    const [showModal, setShowModal] = useState(false);
    const [couponModal, setCouponModal] = useState(false)
    console.log("couponModal: ", couponModal);
    const [coupon, setCoupon] = useState({
        name: '',
        code: '',
        discountType: '',
        discountValue: 0,
        minimumPurchaseAmount: 0,
        maximumDiscountValue: 0,
        coupontoVendor: '',
        couponQuantity: 0,
        startDate: '',
        closeDate: '',
        description: '',
    })
    const [couponErrors, setCouponErrors] = React.useState({
        name: '',
        code: '',
        discountType: '',
        discountValue: '',
        minimumPurchaseAmount: '',
        maximumDiscountValue: '',
        coupontoVendor: '',
        couponQuantity: '',
        startDate: '',
        closeDate: '',
        description: '',
        couponType: '',
    })
    const [discountType, setDiscountType] = useState('')
    const [vendorData, setVendorData] = useState('')
    const [couponType, setCouponType] = useState('')

    const handleCloseModal = () => { setCouponModal(false) }

    const handleShowModal = () => {
        // console.log('prod', prod);
        setCouponModal(true)
    }

    const openModal = (id) => {
        setShowModal(true);
        setDeleteId(id)
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const navigate = useNavigate();
    const [rowData, setRowData] = useState([]);

    const rowHeight = 100;
    const DefaultColumnSetting = {
        sortable: true,
        flex: 1,
        resizable: true,
        minWidth: 120,
    };

    const [colDefs, setColDefs] = useState([
        {
            headerName: "#",
            valueGetter: "node.rowIndex + 1",
            lockPosition: true,
        },
        {
            headerName: "Coupon Name",
            field: "coupon_name",
            filter: true,

        },
        {
            headerName: "Coupon Code",
            filter: true,
            field: "coupon_code",

        },
        {
            headerName: "Start Date",
            field: 'coupon_start_date',
        },
        {
            headerName: "End Date",
            field: 'coupon_end_date',
        },
        {
            headerName: "View",
            field: "view",
            cellRendererFramework: (params) => (
                <center>
                    <FontAwesomeIcon icon={faEye} style={{ color: '#BE783B' }} onClick={() => { navigate('/couponsdata', { state: params.data }) }}> </FontAwesomeIcon>{" "}
                </center>
            ),
        },
        {
            headerName: "Edit",
            field: "Edit",
            cellRendererFramework: (params) => (
                <center>
                    <FontAwesomeIcon icon={faEdit} style={{ color: '#BE783B' }} onClick={() => { navigate('/addCoupon', { state: params.data }) }}> </FontAwesomeIcon>{" "}
                </center>
            ),
        },
        {
            headerName: "Delete",
            cellRendererFramework: (params) => (
                <center>
                    <FontAwesomeIcon icon={faTrash} style={{ color: '#f00' }} onClick={() => openModal(params?.data?.id)}> </FontAwesomeIcon>{" "}
                </center>
            ),
        },
    ])

    const couponsAvailable = async () => {
        console.log("token: ", token);
        const requestOptions = {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        const getcoupon = await axios.get(`${baseURL}/productCoupon`, requestOptions)
        // console.log('getcoupon@@@@@@@@@@@@@@@@@@@', getcoupon)
        if (getcoupon?.status === 200) {
            setRowData(getcoupon?.data?.data)   
        } else {
            console.log('error')
        }
    }
    const goldVendorRequest = async () => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        const getVendor = await axios.get(`${baseURL}/admin/readGoldVendor?limit=10&page_no=1`, requestOptions)
        // console.log('getVendor@@@@@@@@@@@@@@@@@@@', getVendor)
        if (getVendor?.status === 200) {
            setVendorData(getVendor?.data?.data)
        } else {
            console.log('error')
        }
    }

    useEffect(() => {
        couponsAvailable();
        goldVendorRequest();
    }, [])

    const permanentDeletecoupon = async () => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        const permanentcouponDel = await axios.delete(`${baseURL}/productCoupon?coupon_id=${deleteId}`, requestOptions)
        console.log('permanentcouponDel', permanentcouponDel)
        if (permanentcouponDel?.status === 200) {
            couponsAvailable()
            closeModal()
            toast.success(`coupon succesfully deleted`);
        } else {
            console.log('error ==>', permanentcouponDel)
        }
    }

    const inActivatecoupon = async () => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        const couponinactive = await axios.delete(`${baseURL}/admin/softDeleteGoldcoupon?gold_coupon_id=${deleteId}`, requestOptions)
        console.log('couponinactive', couponinactive)
        if (couponinactive?.status === 200) {
            couponsAvailable()
        } else {
            console.log('error ==>', couponinactive)
        }
    }

    const [selectedVendor, setSelectedVendor] = useState([]);
    const [selectedVendor1, setSelectedVendor1] = useState([]);

    const onSelectVendor = (selectedList, selectedItem) => {
        const selectedVendorIds = selectedList?.map((item) => item?.id);
        setSelectedVendor(selectedVendorIds);
        setSelectedVendor1(selectedList)
    };

    const onRemoveVendor = (selectedList, removedItem) => {
        const updatedSelectedVendorIds = selectedList?.map((item) => item?.id);
        setSelectedVendor(updatedSelectedVendorIds);
        setSelectedVendor1(selectedList)
    };

    const handleDiscountTypeChange = (e) => {
        setDiscountType(e.target.value)
    }
    const handleCouponTypeChange = (e) => {
        setCouponType(e.target.value)
    }
    const handleChange = (e) => {
        setCoupon({
            ...coupon,
            [e?.target?.name]: e?.target?.value,
            [e.target.description]: e?.target?.value,
            [e.target.code]: e?.target?.value,
            [e.target.startDate]: e?.target?.value,
            [e.target.closeDate]: e?.target?.value,
            [e.target.discountType]: e?.target?.value,
            [e.target.discountValue]: e?.target?.value,
            [e.target.minimumPurchaseAmount]: e?.target?.value,
            [e.target.maximumDiscountValue]: e?.target?.value,
            [e.target.coupontoVendor]: e?.target?.value,
            [e.target.couponQuantity]: e?.target?.value,
        });

        setCouponErrors({
            ...couponErrors,
            [e.target.name]: null,
            [e.target.description]: null,
            [e.target.code]: null,
            [e.target.startDate]: null,
            [e.target.closeDate]: null,
            [e.target.discountType]: null,
            [e.target.discountValue]: null,
            [e.target.minimumPurchaseAmount]: null,
            [e.target.maximumDiscountValue]: null,
            [e.target.coupontoVendor]: null,
            [e.target.couponQuantity]: null,
        });
    };

    const handleValidation = () => {
        const { description, name, code, startDate, closeDate, discountValue, minimumPurchaseAmount, maximumDiscountValue, couponQuantity } = coupon

        const newErrors = {}

        if (!name) {
            newErrors.name = 'please enter coupon name'
        }
        if (!code) {
            newErrors.code = 'please enter coupon code'
        }
        if (!description) {
            newErrors.description = 'please enter description'
        }
        if (!startDate) {
            newErrors.startDate = 'please select start date'
        }
        if (!closeDate) {
            newErrors.closeDate = 'please select close date'
        }
        if (!discountType) {
            newErrors.discountType = 'please enter discount type'
        }
        if (!discountValue) {
            newErrors.discountValue = 'please enter discount value'
        }
        if (!minimumPurchaseAmount) {
            newErrors.minimumPurchaseAmount = 'please enter minimum purchase amount'
        }
        if (!maximumDiscountValue) {
            newErrors.maximumDiscountValue = 'please enter maximum discount value'
        }
        if (!selectedVendor) {
            newErrors.coupontoVendor = 'please enter coupon to vendor'
        }
        if (!couponType) {
            newErrors.couponType = 'please enter coupon to vendor'
        }
        if (!couponQuantity) {
            newErrors.couponQuantity = 'please enter coupon quantity'
        }

        return newErrors
    }

    const handleSubmit = async () => {
        try {
            const handleValidationObject = handleValidation()

            if (Object.keys(handleValidationObject).length > 0) {
                setCouponErrors(handleValidationObject)
            } else {

                const requestOptions = {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                };
                const userCredentials = {
                    "coupon_name": coupon?.name,
                    "coupon_description": coupon?.description,
                    "coupon_code": coupon?.code,
                    "coupon_discount_type": discountType,
                    "coupon_discount_value": Number(coupon?.discountValue),
                    "coupon_minimum_purchase_amount": Number(coupon?.minimumPurchaseAmount),
                    "coupon_maximum_discount_value": Number(coupon?.maximumDiscountValue),
                    "coupon_to_vendor": couponType,
                    "coupon_quantity": Number(coupon?.couponQuantity),
                    "coupon_start_date": coupon?.startDate,
                    "coupon_end_date": coupon?.closeDate
                }
                console.log("userCredentials: ", userCredentials);
                if (couponType === 'PARTICULAR') {
                    userCredentials["coupon_to_vendor_id"] = selectedVendor;
                }

                const physicalGold = await axios.post(`${baseURL}/productCoupon`, userCredentials, requestOptions)
                if (physicalGold?.status === 200) {
                    setCoupon({
                        ...coupon,
                        name: '',
                        code: '',
                        discountType: '',
                        discountValue: 0,
                        minimumPurchaseAmount: 0,
                        maximumDiscountValue: 0,
                        coupontoVendor: '',
                        couponQuantity: 0,
                        startDate: '',
                        closeDate: '',
                        description: '',
                    })
                    couponsAvailable()
                    handleCloseModal()
                    toast.success(`coupon succesfully created`);

                } else {
                    console.log(physicalGold, "error after submit")
                    toast.show({
                        type: 'error',
                        text1: `${physicalGold.message}`,
                    })
                    setCoupon({
                        ...coupon,
                        name: '',
                        code: '',
                        discountType: '',
                        discountValue: 0,
                        minimumPurchaseAmount: 0,
                        maximumDiscountValue: 0,
                        coupontoVendor: '',
                        couponQuantity: 0,
                        startDate: '',
                        closeDate: '',
                        description: '',
                    })
                    handleCloseModal()
                }
            }
        } catch (e) {
            console.log('error ==>', e)
            if (e?.response?.status === 400) {
                setCoupon({
                    ...coupon,
                    name: '',
                    code: '',
                    discountType: '',
                    discountValue: 0,
                    minimumPurchaseAmount: 0,
                    maximumDiscountValue: 0,
                    coupontoVendor: '',
                    couponQuantity: 0,
                    startDate: '',
                    closeDate: '',
                    description: '',
                })
                handleCloseModal()
                toast.error(`${e?.response?.data?.message}`);
            }
        }
    }


    return (
        <div>

            <div class="content">
                <div className="container">

                    <Card className='p-2'>
                        {/* <Row>
                            <Col>
                                <h3 className='headertext'>Manage Coupon:</h3>
                            </Col>
                            <Col md={3}>
                                <Button variant="warning" onClick={handleShowModal}>Add Coupon</Button>
                            </Col>
                        </Row> 
                        <hr />*/}
                        <Row>
                            <Col>
                                <h3 className='headertext'>Coupons Available:</h3>
                            </Col>
                        </Row>
                        <div
                            className="ag-theme-alpine"
                            style={{ height: "70vh", width: "100%" }}
                        >
                            <AgGridReact
                                rowHeight={rowHeight}
                                columnDefs={colDefs}
                                defaultColDef={DefaultColumnSetting}
                                pagination={true}
                                paginationPageSize={10}
                                rowData={rowData}
                            // onRowClicked={(e) => 
                            //     navigate('/couponData',{id:e.data._id})}
                            />
                        </div>
                    </Card>
                    <Modal
                        show={couponModal}
                        onHide={handleCloseModal}
                        backdrop="static"
                        keyboard={false}
                        centered
                        size="lg"
                    >
                        <h4 className='headertext text-center'>Add/Edit Coupon Details:</h4>
                        <Modal.Body>
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Coupon Name</Form.Label>
                                        <Form.Control
                                            maxLength={50}
                                            type="text"
                                            placeholder="Enter coupon name"
                                            size="sm"
                                            name='name'
                                            onChange={(e) => handleChange(e)}
                                            autoComplete='off'
                                            className='mb-3'
                                            value={coupon?.name}
                                        />
                                        <span className="text-danger">{couponErrors?.name}</span>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Code</Form.Label>
                                        <Form.Control
                                            maxLength={50}
                                            type="text"
                                            placeholder="Enter Coupon Code"
                                            size="sm"
                                            name='code'
                                            onChange={(e) => handleChange(e)}
                                            autoComplete='off'
                                            className='mb-3'
                                            value={coupon?.code}
                                        />
                                        <span className="text-danger">{couponErrors?.priceUnit}</span>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Discount Type</Form.Label>
                                        <div className="leftedge d-flex justify-content-space">
                                            <Form.Select
                                                aria-label="select Metal"
                                                size={"sm"}
                                                className="selectsizesmall"
                                                name="discountType"
                                                onChange={(e) => handleDiscountTypeChange(e)}
                                                value={discountType}
                                            >
                                                <option >Select Discount Type</option>
                                                <option name='PERCENTAGE' value='PERCENTAGE'>Percentage</option>
                                                <option name='FIXED_AMOUNT' value='FIXED_AMOUNT'>Fixed Amount</option>
                                            </Form.Select>
                                        </div>
                                        <span className="text-danger">{couponErrors?.discountType}</span>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Discount Value</Form.Label>
                                        <Form.Control
                                            maxLength={50}
                                            type="number"
                                            placeholder="Enter Discount Value"
                                            size="sm"
                                            name='discountValue'
                                            onChange={(e) => handleChange(e)}
                                            autoComplete='off'
                                            className='mb-3'
                                            value={coupon?.discountValue}
                                        />
                                        <span className="text-danger">{couponErrors?.discountValue}</span>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Maximum Purchase Amount</Form.Label>
                                        <Form.Control
                                            maxLength={50}
                                            type="number"
                                            placeholder="Enter Maximum Discount Value"
                                            size="sm"
                                            name='maximumDiscountValue'
                                            onChange={(e) => handleChange(e)}
                                            autoComplete='off'
                                            className='mb-3'
                                            value={coupon?.maximumDiscountValue}
                                        />
                                        <span className="text-danger">{couponErrors?.maximumDiscountValue}</span>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Minimum Purchase Amount</Form.Label>
                                        <Form.Control
                                            maxLength={50}
                                            type="number"
                                            placeholder="Enter Minimum Purchase Amount"
                                            size="sm"
                                            name='minimumPurchaseAmount'
                                            onChange={(e) => handleChange(e)}
                                            autoComplete='off'
                                            className='mb-3'
                                            value={coupon?.minimumPurchaseAmount}
                                        />
                                        <span className="text-danger">{couponErrors?.minimumPurchaseAmount}</span>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Coupon to Vendor</Form.Label>
                                        <div className="leftedge d-flex justify-content-space">
                                            <Form.Select
                                                aria-label="select Metal"
                                                size={"sm"}
                                                className="selectsizesmall"
                                                name="couponType"
                                                onChange={(e) => handleCouponTypeChange(e)}
                                                value={couponType}
                                            >
                                                <option >Select Coupon Applies to</option>
                                                <option name='ALL' value='ALL'>All</option>
                                                <option name='PARTICULAR' value='PARTICULAR'>Particular</option>
                                            </Form.Select>
                                        </div>
                                        <span className="text-danger">{couponErrors?.coupontoVendor}</span>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Coupon Quantity</Form.Label>
                                        <Form.Control
                                            maxLength={50}
                                            type="number"
                                            placeholder="Enter Coupon Quantity"
                                            size="sm"
                                            name='couponQuantity'
                                            onChange={(e) => handleChange(e)}
                                            autoComplete='off'
                                            className='mb-3'
                                            value={coupon?.couponQuantity}
                                        />
                                        <span className="text-danger">{couponErrors?.couponQuantity}</span>
                                    </Form.Group>
                                </Col>
                            </Row>
                            {couponType === 'PARTICULAR' &&
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Coupon to Vendors</Form.Label>
                                            <div className="leftedge d-flex justify-content-space">
                                                <Multiselect
                                                    options={vendorData}
                                                    displayValue="gold_vendor_name"
                                                    onSelect={onSelectVendor}
                                                    onRemove={onRemoveVendor}
                                                    selectedValues={selectedVendor1}
                                                    placeholder="Select Vendor"
                                                    value={selectedVendor}
                                                />
                                            </div>
                                            <span className="text-danger">{couponErrors?.coupontoVendor}</span>
                                        </Form.Group>
                                    </Col>
                                </Row>}
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Coupon Start Date</Form.Label>
                                        <Form.Control
                                            maxLength={50}
                                            type="date"
                                            placeholder="Enter Start Date"
                                            size="sm"
                                            name='startDate'
                                            onChange={(e) => handleChange(e)}
                                            autoComplete='off'
                                            className='mb-3'
                                            value={coupon?.startDate}
                                        />
                                        <span className="text-danger">{couponErrors?.startDate}</span>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Coupon Close Date</Form.Label>
                                        <Form.Control
                                            maxLength={50}
                                            type="date"
                                            placeholder="Enter Close Date"
                                            size="sm"
                                            name='closeDate'
                                            onChange={(e) => handleChange(e)}
                                            autoComplete='off'
                                            className='mb-3'
                                            value={coupon?.closeDate}
                                        />
                                        <span className="text-danger">{couponErrors?.closeDate}</span>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control
                                            as="textarea" rows={3}
                                            placeholder="Enter description"
                                            size="md"
                                            name='description'
                                            onChange={(e) => handleChange(e)}
                                            autoComplete='off'
                                            className='mb-3'
                                            value={coupon?.description}
                                        />
                                        <span className="text-danger">{couponErrors?.description}</span>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={handleCloseModal} variant="secondary">
                                Cancel
                            </Button>
                            <Button variant="warning"
                                onClick={() => handleSubmit()}
                            // onClick={() => { selectedProduct?.id ? handleSubmitEdit() : handleSubmit() }}
                            >
                                Submit
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    {/* delete modal */}
                    <Modal show={showModal} onHide={closeModal}>
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>
                            <h3>Are you sure, you want to delete this coupon ?</h3>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={closeModal}>
                                Cancel
                            </Button>
                            {/* <Button variant="warning" onClick={inActivatecoupon}>
                                inActivate
                            </Button> */}
                            <Button variant="danger" onClick={permanentDeletecoupon}>
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        </div>
    )
}

export default CouponsList