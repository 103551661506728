import React, { useState, useEffect } from 'react'
import { useLocation } from "react-router-dom";
import {
  Card,
  Row,
  Col,
  Figure,
  Table,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import SideBar from "../../../dashboard/SideBar";
import FirstNavbar from "../../../dashboard/FirstNavbar";
import axios from 'axios';
import baseURL from '../../authServices/Url';
import { toast } from "react-toastify";


function MetalData() {
  const { state } = useLocation();
  const [metalData1, setmetalData1] = useState(state)
  console.log('metalData1', metalData1, state)
  console.log('metalData1@@@@@', metalData1?.metal_name?.split(' ')[0])
  let token = localStorage.getItem('accessToken');
  const [physicalGoldForm, setPhysicalGoldForm] = useState({
    description: '' || metalData1?.metal_description,
    priceUnit: '' || metalData1?.metal_purity_unit,
  })
  const [physicalGoldFormErrors, setPhysicalGoldFormErrors] = React.useState({
    name: '',
    purity: '',
    description: '',
    purityUnit: '',
    weightUnit: '',
    priceUnit: '',
  })
  const [metalSelected, setMetalSelected] = useState('' || metalData1?.metal_name?.split(' ')[0])
  const [purity, setPurity] = useState('' || metalData1?.metal_purity)
  const metalData = ['Gold', 'Silver', 'Platinum']

  const purityData = {
    Gold: [24, 22, 18, 14],
    Silver: [999, 925],
    Platinum: [950]
  }
  const [metalModal, setmetalModal] = useState(false)

  const handleCloseModal1 = () => setmetalModal(false)

  const handleShowModal1 = () => {
    console.log('prod', metalData1);
    setmetalModal(true)
    setMetalSelected(metalData1?.metal_name?.split(' ')[0])
    setPurity(metalData1?.metal_purity)
    setPhysicalGoldForm({
      name: metalData1?.metal_name,
      purity: metalData1?.metal_purity,
      description: metalData1?.metal_description,
      purityUnit: metalData1?.metal_purity_unit,
      weightUnit: metalData1?.metal_weight_unit,
      priceUnit: metalData1?.metal_price_per_weight_unit,
    })


  }

  const metalAvailable = async () => {
    const requestOptions = {
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    const getmetal = await axios.get(`${baseURL}/admin/readMetals?metal_id=${metalData1?.id}`, requestOptions)
    console.log('getmetal@@@@@@@@@@@@@@@@@@@', getmetal)
    if (getmetal?.status === 200) {
      setmetalData1(getmetal?.data?.data[0])
    } else {
      console.log('error')
    }
  }
  useEffect(() => {
    metalAvailable()
  }, [])

  const handleChange = (e) => {
    setPhysicalGoldForm({
      ...physicalGoldForm,
      [e?.target?.name]: e?.target?.value,
      [e.target.purity]: e.target.value,
      [e.target.description]: e.target.description,
      [e.target.purityUnit]: e.target.value,
      [e.target.weightUnit]: e.target.value,
      [e.target.priceUnit]: e.target.value,
    });

    setPhysicalGoldFormErrors({
      ...physicalGoldFormErrors,
      [e.target.name]: null,
      [e.target.description]: null,
      [e.target.purity]: null,
      [e.target.purityUnit]: null,
      [e.target.weightUnit]: null,
      [e.target.priceUnit]: null,
    });
  };

  const handleValidation = () => {
    const { description, priceUnit } = physicalGoldForm
    const newErrors = {}

    if (!metalSelected) {
      newErrors.name = 'please select metal'
    }
    if (!purity) {
      newErrors.purity = 'please select purity'
    }
    if (!description) {
      newErrors.description = 'please enter description'
    }
    // if (!purityUnit) {
    //   newErrors.purityUnit = 'please enter Purity Unit'
    // }
    // if (!weightUnit) {
    //   newErrors.weightUnit = 'please enter weight Unit'
    // }
    if (!priceUnit) {
      newErrors.priceUnit = 'please enter Price/gram'
    }

    return newErrors
  }

  const handleMetalChange = (e) => {
    setMetalSelected(e.target.value)
  }

  const handlePurityChange = (e) => {
    console.log('e.target.value', e.target.value)
    setPurity(e.target.value)
  }

  const handleSubmitEdit = async () => {
    const handleValidationObject = handleValidation()
    if (Object.keys(handleValidationObject).length > 0) {
      setPhysicalGoldFormErrors(handleValidationObject)
    } else {
      const requestOptions = {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };
      const userCredentials = {
        'metal_name': `${metalSelected} ${purity}`,
        'metal_purity': parseInt(purity),
        'metal_description': physicalGoldForm?.description,
        'metal_purity_unit': metalSelected === 'Gold' ? `CARAT` : `PPT`,
        'metal_weight_unit': 'GRAMS',
        'metal_price_per_weight_unit': physicalGoldForm?.priceUnit,
        'metal_id': metalData1?.id,
      }

      const EditPhysicalGold = await axios.patch(`${baseURL}/admin/updateMetal`, userCredentials, requestOptions)
      console.log('EditPhysicalGold EditPhysicalGold', EditPhysicalGold);
      if (EditPhysicalGold?.status === 200) {
        toast.success('Product has successfully updated')
        metalAvailable()
        setPhysicalGoldForm({
          ...physicalGoldForm,
          description: '',
          priceUnit: '',
        })
        setMetalSelected()
        setPurity()

        handleCloseModal1()
      } else {
        console.log(EditPhysicalGold, "error after submit")
        toast.show({
          type: 'error',
          text1: `${EditPhysicalGold.message}`,
        })
        setPhysicalGoldForm({
          ...physicalGoldForm,
          description: '',
          priceUnit: '',
        })
        setMetalSelected()
        setPurity()
        handleCloseModal1()
      }


    }
  }


  return (
    <div>
      <div class="sidebar">
        <SideBar type='Details'/>
      </div>
      <div class="content">
        <div className="container">
          <FirstNavbar />
          <h3 className='headertext'>metal Data</h3>
          <div>
            <Card className="p-2">
              <Row>
                <Col md={6}>
                  <h3 className="headertext text1">metal Deatils:</h3>
                </Col>
              </Row>

              <Row>
                <Col md={1}></Col>
                <Col md={3}>
                  <h5>Name</h5>
                </Col>
                <Col md={1}>:</Col>
                <Col md={3}>
                  <h5>{metalData1?.metal_name}</h5>
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={3}>
                  <h5>Purity</h5>
                </Col>
                <Col md={1}>:</Col>
                <Col md={3}>
                  <h5>{metalData1?.metal_purity} {metalData1?.metal_purity_unit}</h5>
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={3}>
                  <h5>Price</h5>
                </Col>
                <Col md={1}>:</Col>
                <Col md={3}>
                  <h5>{metalData1?.metal_price_per_weight_unit}/gram</h5>
                </Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col md={3}><h5>Description</h5></Col>
                <Col md={1}>:</Col>
                <Col md={3}><h5>{metalData1?.metal_description}</h5></Col>
              </Row>


              <hr />
              <Row>
                <Col md={3}>
                  <Button variant="warning" onClick={handleShowModal1}>
                    Edit metal
                  </Button>
                </Col>

              </Row>

            </Card>
          </div>
        </div>
      </div>

      <Modal
        show={metalModal}
        onHide={handleCloseModal1}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <h4 className='headertext text-center'>Edit metal Details:</h4>
        <Modal.Body>

          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Select Metal</Form.Label>
                <div className="leftedge d-flex justify-content-space">
                  <Form.Select
                    aria-label="select Metal"
                    size={"sm"}
                    className="selectsizesmall"
                    name="handleGenderChange"
                    onChange={(e) => handleMetalChange(e)}
                    value={metalSelected}
                  >

                    <option >Select Metal</option>
                    {metalData?.map((met, i) => {
                      return (
                        <option key={i} name='metal' value={met}>{met}</option>
                      )
                    })}
                  </Form.Select>
                </div>
                <span className="text-danger">{physicalGoldFormErrors?.name}</span>
              </Form.Group>
            </Col>
            <Col md={6}>
              {metalSelected && <Form.Group className="mb-3">
                <Form.Label>Select Purity</Form.Label>
                <div className="leftedge d-flex justify-content-space">
                  <Form.Select
                    aria-label="select Purity"
                    size={"sm"}
                    className="selectsizesmall"
                    onChange={(e) => handlePurityChange(e)}
                    value={purity}
                  >

                    <option >Select Purity</option>
                    {purityData[metalSelected]?.map((purity, i) => {
                      console.log('purrrrrity', purity);
                      return (
                        <option key={i} name='purity' value={purity}>{metalSelected === 'Gold' ? `${metalSelected} ${purity} k` : `${metalSelected} ${purity}`}</option>
                      )
                    })}

                  </Form.Select>
                </div>
                <span className="text-danger">{physicalGoldFormErrors?.purity}</span>
              </Form.Group>
              }
            </Col>

          </Row>

          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Price/gram</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter Price/gram"
                  size="sm"
                  name='priceUnit'
                  onChange={handleChange}
                  autoComplete='off'
                  className='mb-3'
                  value={physicalGoldForm?.priceUnit}
                />
                <span className="text-danger">{physicalGoldFormErrors?.priceUnit}</span>
              </Form.Group>
            </Col>

          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  // maxLength={50}
                  // type="text"
                  as="textarea" rows={3}
                  placeholder="Enter description"
                  size="md"
                  name='description'
                  onChange={handleChange}
                  autoComplete='off'
                  className='mb-3'
                  value={physicalGoldForm?.description}
                />
                <span className="text-danger">{physicalGoldFormErrors?.description}</span>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseModal1} variant="secondary">
            Cancel
          </Button>
          <Button variant="warning" onClick={() => handleSubmitEdit()}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default MetalData